
import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import { DialogConfirmConfig, DialogController } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive } from 'vue-property-decorator';
import {
    ColorConformationLevels,
    ColorConformationLevelContrast,
    ColorRelation,
} from '../../../../components/color';

interface DefaultColors {
    white: string;
    dark_canvas_background: string;
    light_canvas_background: string;
    dark_canvas_color: string;
    light_canvas_color: string;
    dark_card_background: string;
    light_card_background: string;
    dark_card_color: string;
    light_card_color: string;
}

@Component({
    components: {
        ColorConformationLevelContrast,
        ColorConformationLevels,
    },
})
export default class ShopSettingsStyle extends Vue {

    @InjectReactive('settings')
    private settings!: CustomShopSettingsClient;

    @Inject('dialog')
    private dialog!: DialogController;

    private elReactive: null | Element = null;

    mounted(): void {
        this.elReactive = this.$el;
    }

    private get defaultColors(): DefaultColors {
        if (!this.elReactive) {
            return {
                white: '#ffffff',
                dark_canvas_background: '',
                light_canvas_background: '',
                dark_canvas_color: '',
                light_canvas_color: '',
                dark_card_background: '',
                light_card_background: '',
                dark_card_color: '',
                light_card_color: '',
            };
        }

        const computedStyle: CSSStyleDeclaration = window.getComputedStyle(this.elReactive);

        return {
            white: computedStyle.getPropertyValue('--ot-color-core-white'),
            dark_canvas_background: computedStyle.getPropertyValue('--ot-color-core-dark-background-secondary'),
            light_canvas_background: computedStyle.getPropertyValue('--ot-color-core-light-background-secondary'),
            dark_canvas_color: computedStyle.getPropertyValue('--ot-color-core-dark-foreground-primary'),
            light_canvas_color: computedStyle.getPropertyValue('--ot-color-core-light-foreground-primary'),
            dark_card_background: computedStyle.getPropertyValue('--ot-color-core-dark-background-primary'),
            light_card_background: computedStyle.getPropertyValue('--ot-color-core-light-background-primary'),
            dark_card_color: computedStyle.getPropertyValue('--ot-color-core-dark-foreground-primary'),
            light_card_color: computedStyle.getPropertyValue('--ot-color-core-light-foreground-primary'),
        };
    }

    private get dynamicDisabled(): boolean {
        return [
            ((this.settings.static.style.color || {}).core || {}).brand || null,
            (this.settings.static.style.shop || {}).background || null,
            (this.settings.static.style.shop || {}).color || null,
            (this.settings.static.style.card || {}).background || null,
            (this.settings.static.style.card || {}).color || null,
        ].some((setting: null | unknown) => setting !== null);
    }

    private get themeOptions(): { [key: string]: string } {
        const options: { [key: string]: string } = {
            light: this.$t('dashboard.shop.settings.style.theme.option.light') as string,
            dark: this.$t('dashboard.shop.settings.style.theme.option.dark') as string,
        };

        if (!this.dynamicDisabled) {
            options.dynamic = this.$t('dashboard.shop.settings.style.theme.option.dynamic') as string;
        }

        return options;
    }

    private get themeIsDarkOrDynamic(): boolean {
        if ([ 'dynamic', 'dark' ].includes(this.settings.static.theme)) {
            return true;
        }

        return !this.settings.static.theme;
    }

    private get themeIsLightOrDynamic(): boolean {
        if ([ 'dynamic', 'light' ].includes(this.settings.static.theme)) {
            return true;
        }

        return !this.settings.static.theme;
    }

    private get brandRelations(): ColorRelation[] {
        const shopBackground: string | null = (
            this.settings.static.style.shop ? this.settings.static.style.shop.background : null
        ) || null;
        const cardBackground: string | null = (
            this.settings.static.style.card ? this.settings.static.style.card.background : null
        ) || null;

        const white: ColorRelation = {
            id: 'brand-vs-white',
            value: this.defaultColors.white,
            name: 'dashboard.shop.settings.style.brand_color.vs.white',
            location: 'above',
        };

        const overwrittenCanvasBackground: ColorRelation = {
            id: 'brand-vs-overwritten_canvas_background',
            value: shopBackground,
            name: 'dashboard.shop.settings.style.brand_color.vs.canvas_background',
            location: 'below',
        };
        const overwrittenCardBackground: ColorRelation = {
            id: 'brand-vs-overwritten_card_background',
            value: cardBackground,
            name: 'dashboard.shop.settings.style.brand_color.vs.card_background',
            location: 'below',
        };

        const darkCanvasBackground: ColorRelation = {
            id: 'brand-vs-dark_canvas_background',
            value: this.defaultColors.dark_canvas_background,
            name: 'dashboard.shop.settings.style.brand_color.vs.default_dark_canvas_background',
            location: 'below',
        };
        const darkCardBackground: ColorRelation = {
            id: 'brand-vs-dark_card_background',
            value: this.defaultColors.dark_card_background,
            name: 'dashboard.shop.settings.style.brand_color.vs.default_dark_card_background',
            location: 'below',
        };

        const lightCanvasBackground: ColorRelation = {
            id: 'brand-vs-light_canvas_background',
            value: this.defaultColors.light_canvas_background,
            name: 'dashboard.shop.settings.style.brand_color.vs.default_light_canvas_background',
            location: 'below',
        };
        const lightCardBackground: ColorRelation = {
            id: 'brand-vs-light_card_background',
            value: this.defaultColors.light_card_background,
            name: 'dashboard.shop.settings.style.brand_color.vs.default_light_card_background',
            location: 'below',
        };

        return [
            white,
            ...(shopBackground ? [ overwrittenCanvasBackground ] : [
                ...(this.themeIsDarkOrDynamic ? [ darkCanvasBackground ] : []),
                ...(this.themeIsLightOrDynamic ? [ lightCanvasBackground ] : []),
            ]),
            ...(cardBackground ? [ overwrittenCardBackground ] : [
                ...(this.themeIsDarkOrDynamic ? [ darkCardBackground ] : []),
                ...(this.themeIsLightOrDynamic ? [ lightCardBackground ] : []),
            ]),
        ];
    }

    private get canvasBackgroundRelations(): ColorRelation[] {
        const brandColor: string | null = ((this.settings.static.style.color || {}).core || {}).brand || null;

        const canvasColor: string | null = (this.settings.static.style.shop || {}).color || null;

        const brand: ColorRelation = {
            id: 'canvas_background-vs-brand',
            value: brandColor,
            name: 'dashboard.shop.settings.style.canvas.background.vs.brand',
            location: 'above',
        };

        const overwrittenCanvasColor: ColorRelation = {
            id: 'canvas_background-vs-canvas_color',
            value: canvasColor,
            name: 'dashboard.shop.settings.style.canvas.background.vs.canvas_color',
            location: 'above',
        };
        const darkCanvasColor: ColorRelation = {
            id: 'canvas_background-vs-default_dark_canvas_color',
            value: this.defaultColors.dark_canvas_color,
            name: 'dashboard.shop.settings.style.canvas.background.vs.default_dark_canvas_color',
            location: 'above',
        };
        const lightCanvasColor: ColorRelation = {
            id: 'canvas_background-vs-default_light_canvas_color',
            value: this.defaultColors.light_canvas_color,
            name: 'dashboard.shop.settings.style.canvas.background.vs.default_light_canvas_color',
            location: 'above',
        };

        return [
            ...(brandColor ? [ brand ] : []),

            ...(canvasColor ? [ overwrittenCanvasColor ] : [
                ...(this.themeIsDarkOrDynamic ? [ darkCanvasColor ] : []),
                ...(this.themeIsLightOrDynamic ? [ lightCanvasColor ] : []),
            ]),
        ];
    }

    private get canvasColorRelations(): ColorRelation[] {
        const canvasBackground: string | null = (this.settings.static.style.shop || {}).background || null;

        const overwrittenCanvasBackground: ColorRelation = {
            id: 'canvas_color-vs-canvas_background',
            value: canvasBackground,
            name: 'dashboard.shop.settings.style.canvas.color.vs.canvas_background',
            location: 'below',
        };
        const darkCanvasBackground: ColorRelation = {
            id: 'canvas_color-vs-default_dark_canvas_background',
            value: this.defaultColors.dark_canvas_background,
            name: 'dashboard.shop.settings.style.canvas.color.vs.default_dark_canvas_background',
            location: 'below',
        };
        const lightCanvasBackground: ColorRelation = {
            id: 'canvas_color-vs-default_light_canvas_background',
            value: this.defaultColors.light_canvas_background,
            name: 'dashboard.shop.settings.style.canvas.color.vs.default_light_canvas_background',
            location: 'below',
        };

        return canvasBackground ? [ overwrittenCanvasBackground ] : [
            ...(this.themeIsDarkOrDynamic ? [ darkCanvasBackground ] : []),
            ...(this.themeIsLightOrDynamic ? [ lightCanvasBackground ] : []),
        ];
    }

    private get cardBackgroundRelations(): ColorRelation[] {
        const brandColor: string | null = ((this.settings.static.style.color || {}).core || {}).brand || null;

        const cardColor: string | null = (this.settings.static.style.card || {}).color || null;

        const brand: ColorRelation = {
            id: 'card_background-vs-brand',
            value: brandColor,
            name: 'dashboard.shop.settings.style.card.background.vs.brand',
            location: 'above',
        };

        const overwrittenCardColor: ColorRelation = {
            id: 'card_background-vs-card_color',
            value: cardColor,
            name: 'dashboard.shop.settings.style.card.background.vs.card_color',
            location: 'above',
        };
        const darkCardColor: ColorRelation = {
            id: 'card_background-vs-default_dark_card_color',
            value: this.defaultColors.dark_card_color,
            name: 'dashboard.shop.settings.style.card.background.vs.default_dark_card_color',
            location: 'above',
        };
        const lightCardColor: ColorRelation = {
            id: 'card_background-vs-default_light_card_color',
            value: this.defaultColors.light_card_color,
            name: 'dashboard.shop.settings.style.card.background.vs.default_light_card_color',
            location: 'above',
        };

        return [
            ...(brandColor ? [ brand ] : []),

            ...(cardColor ? [ overwrittenCardColor ] : [
                ...(this.themeIsDarkOrDynamic ? [ darkCardColor ] : []),
                ...(this.themeIsLightOrDynamic ? [ lightCardColor ] : []),
            ]),
        ];
    }

    private get cardColorRelations(): ColorRelation[] {
        const cardBackground: string | null = (this.settings.static.style.card || {}).background || null;

        const overwrittenCardBackground: ColorRelation = {
            id: 'card_color-vs-card_background',
            value: cardBackground,
            name: 'dashboard.shop.settings.style.card.color.vs.card_background',
            location: 'below',
        };
        const darkCardBackground: ColorRelation = {
            id: 'card_color-vs-default_dark_card_background',
            value: this.defaultColors.dark_card_background,
            name: 'dashboard.shop.settings.style.card.color.vs.default_dark_card_background',
            location: 'below',
        };
        const lightCardBackground: ColorRelation = {
            id: 'card_color-vs-default_light_card_background',
            value: this.defaultColors.light_card_background,
            name: 'dashboard.shop.settings.style.card.color.vs.default_light_card_background',
            location: 'below',
        };

        return cardBackground ? [ overwrittenCardBackground ] : [
            ...(this.themeIsDarkOrDynamic ? [ darkCardBackground ] : []),
            ...(this.themeIsLightOrDynamic ? [ lightCardBackground ] : []),
        ];
    }

    private async resetShopSettings(): Promise<void> {
        const confirmation: DialogConfirmConfig = {
            title: this.$t('dashboard.common.confirm.sure') as string,
            description: this.$t('dashboard.common.confirm.permanent') as string,
            type: 'is-danger',
        };

        if (!await this.dialog.confirm(confirmation)) {
            return;
        }

        try {
            await this.settings.resetStaticSettings();

            this.$emit('reset');

            this.$notifications.success(this.$t('dashboard.shop.settings.reset.notification.success'));
        } catch (e) {
            this.$notifications.warning(this.$t('dashboard.shop.settings.reset.notification.fail'));

            throw e;
        }
    }

}
