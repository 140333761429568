import { RouteConfig } from 'vue-router';
import CompanyForm from './views/CompanyForm.vue';
import CompanyMembers from './views/CompanyMembers.vue';
import UserProfile from './views/UserProfile.vue';

const routes: RouteConfig[] = [
    {
        path: 'company/settings',
        name: 'company.settings',
        meta: {
            scope: 'company.settings',
        },
        component: CompanyForm,
    },
    {
        path: 'company/new',
        name: 'company.new',
        component: CompanyForm,
    },
    {
        path: 'company/new/:companyId',
        name: 'company.new.edit',
        component: CompanyForm,
    },
    {
        path: 'company/members',
        name: 'company.members',
        meta: {
            scope: 'company.members',
        },
        component: CompanyMembers,
        children: [
            {
                path: 'new',
                name: 'company.members.new',
                component: UserProfile,
                meta: {
                    modal: true,
                },
            },
            {
                path: ':userId',
                name: 'company.members.edit',
                component: UserProfile,
                meta: {
                    modal: true,
                },
            },
        ],
    },
];

export default routes;
