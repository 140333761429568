
import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from 'vue-property-decorator';

@Component
export default class ShopSettingsOrder extends Vue {

    @InjectReactive('settings')
    private settings!: CustomShopSettingsClient;

    @InjectReactive('isAdmin')
    private isAdmin!: boolean;

}
