
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

// Tickets types
import { Draggable, NewDraggablePosition } from './types';

/**
 *  Must be forwarded at initialising the drag and drop logic
 */
import TicketsItemsOptions from './ItemOptions.vue';

/**
 *  Holding statistics regarding the positions.
 */
interface Position {
    /**
     *  Original position of the cursor at the start of dragging.
     */
    original: number;
    /**
     *  The correction that should be applied, taking
     *  body scroll and margins into account.
     */
    correction: number;
    /**
     *  The new, computed updated position while dragging.
     */
    updated: number;
    /**
     *  The last emited position.
     */
    emitted: null | number;
}

/**
 *  Holding the cursor's X and Y touch events as returned
 */
interface ITouchEvent {
    pageX: number;
    pageY: number;
}

/**
 *  Holding the margins that are taken into account
 *  while performing computations while dragging.
 */
interface DragMargins {
    /**
     *  The number of pixels the cursor should have been moved
     *  before considering it as being dragged (an not a click).
     */
    emit: number;
    /**
     *  The number of pixels a draggable item should
     *  be away from the viewport border, before it
     *  automatically starts scrolling.
     */
    viewport: number;
    /**
     *
     *  The number of pixels a draggable item may
     *  be dragged outside of the border element.
     *
     *  (i) Ensuring it can be dragged far enough to let the
     *  draggable item's bottom touch the border elements'
     *  top, and his top the border elements' bottom.
     *
     */
    borderEl: number;
}

/**
 * Retrieve the right touch event object, as not every browser/os uses the same syntax.
 */
function getTouchEvent(ev: TouchEvent): ITouchEvent {
    return ev.touches[0] || ev.changedTouches[0];
}

function isTouchEvent(ev: MouseEvent | TouchEvent): ev is TouchEvent {
    return /touch/g.test(ev.type);
}

@Component
export default class TicketsDragDrop extends Vue {

    // Type the draghandle ref to prevent TS errors about the event
    // listeners (in the component that mixes in this class)
    public $refs!: {
        options: TicketsItemsOptions;
    };

    // Contains data of the draggable item and the properties
    // that are needed to make it a draggable item
    public draggable!: Draggable;

    // The DOM element that is being dragged an his drag handle
    private dragEl!: HTMLElement;

    private dragHandle!: HTMLElement;

    // DOM references of the parent and the clone
    private parentEl: HTMLElement | null = null;

    private clone: HTMLElement | null = null;

    // Capture the current height of the header to compute if the document
    // should scroll in order to keep the dragged item in sight
    private headerHeight = 0;

    // Setting to give all child component a static width to
    // preserve correct styling if necessary
    private staticWidthOnDrag = false;

    // If the item is really being dragged
    // (i) Taking the margins for movement into account
    private beingDragged = false;

    // Margins that are used for computations while dragging
    private margins: DragMargins = {
        emit: 10,
        viewport: 25,
        borderEl: 50,
    };

    // Used for repositioning the clone when the document is programmatically being scrolled
    private repositioning = false;

    private repositioningTimeout!: void | number | undefined;

    // Holding the positions
    private position: Position = {
        original: 0,
        correction: 0,
        updated: 0,
        emitted: null,
    };

    // The element outside which the item that is dragged may not come
    @Prop({ required: true })
    private borderEl!: HTMLElement;

    /**
     *  To be executed by the class that mixes in this class
     *  to initiate the drag and drop logic.
     */
    public initDragDrop(
        draggable: Draggable,
        element: HTMLElement,
        options: TicketsItemsOptions,
        staticWidthOnDrag?: boolean,
    ): void {
        this.draggable = draggable;
        this.dragEl = element;
        this.parentEl = element.parentElement;
        this.dragHandle = options.dragHandle;
        this.staticWidthOnDrag = staticWidthOnDrag || this.staticWidthOnDrag;

        if (!this.parentEl || !this.dragHandle) {
            throw Error(
                'TicketsDragDrop -> Could not initialise because of missing parent or drag handle element',
            );
        }

        this.dragHandle.addEventListener('mousedown', this.dragStart);
        this.dragHandle.addEventListener('touchstart', this.dragStart);
    }

    public beforeDestroy(): void {
        if (!this.dragHandle) {
            return;
        }

        this.dragHandle.removeEventListener('mousedown', this.dragStart);
        this.dragHandle.removeEventListener('touchstart', this.dragStart);
    }

    /**
     *  The repositioning prop is set to true just before the document is about to be scrolled
     *  programmatically because the dragged item has moved out of the viewport. Once that happens
     *  this watcher fn will make sure the repositioning class is added to the clone (which adds
     *  transition) and automatically set the value back to false after the repositioning happened
     *  (which is the exact same mls as the transition takes).
     *
     *  Important to know is that any clone update is blocked while the value is set to true,
     *  which results in smooth automatic scrolling of the page while keeping the position of
     *  the dragged item intact.
     */
    @Watch('repositioning')
    private reposition(repositioning: boolean): void {
        if (!this.clone) {
            return;
        }

        const repositioningClass = 'ticket-groups--draggable-clone--repositioning';

        if (!repositioning) {
            this.clone.classList.remove(repositioningClass);

            return;
        }

        this.clone.classList.add(repositioningClass);

        // The mls is equal to the transition delay, which is added to the
        // clone via the above class while being repositioned
        this.repositioningTimeout = window.setTimeout(() => {
            this.repositioning = false;
        }, 150);
    }

    private dragStart(ev: MouseEvent | TouchEvent): void {
        ev.preventDefault();

        if (!this.parentEl) {
            return;
        }

        this.clone = this.dragEl.cloneNode(true) as HTMLElement;

        if (!this.clone) {
            return;
        }

        const draggableRect: DOMRect = this.dragEl.getBoundingClientRect();

        // Capture the original mouse position to determine if it's about a click or drag in the move fn
        this.position.original = isTouchEvent(ev) ? getTouchEvent(ev).pageY : ev.pageY;

        // Capture the corrections that should be applied
        // because of possible body scrolling
        this.position.correction = (isTouchEvent(ev) ? getTouchEvent(ev).pageY : ev.pageY)
            - draggableRect.top;

        // Also make sure to take the top margin into account
        const marginTop = parseInt(
            window.getComputedStyle(this.dragEl).marginTop.replace('px', ''),
            10,
        );

        this.position.correction += marginTop;

        // Make sure the clone starts at exactly the same spot as the original element
        this.clone.style.top = `${draggableRect.top - marginTop}px`;
        this.clone.style.left = `${draggableRect.left}px`;
        this.clone.style.width = `${draggableRect.width}px`;
        this.clone.style.height = `${draggableRect.height}px`;

        // Make sure the clone gets the right styling by adding the right class
        this.clone.classList.add('ticket-groups--draggable-clone');

        // Give every child a fixed width if required
        // (i) Needed if we're dragging a table row for instance,
        // because his clone is appended to the document, it
        // will loose his table cell widths
        const { children } = this.dragEl;

        if (this.staticWidthOnDrag && children.length) {
            let c: number;
            const l: number = children.length;

            for (c = 0; c < l; c++) {
                const cloneChild: HTMLElement = this.clone.children[
                    c
                ] as HTMLElement;

                cloneChild.style.maxWidth = 'none';

                // Necessarry to keep possible ellipses overflows working
                cloneChild.style.display = 'inline-block';

                cloneChild.style.width = window.getComputedStyle(
                    children[c],
                ).width;
            }
        }

        // Capture the height of the header for correctly computing
        // if the document should be scrolled because the dragged
        // element is being dragged out of sight
        if (this.headerHeight === 0) {
            const header: Element = document.getElementsByClassName('dashboard__header')[0];

            if (header) {
                this.headerHeight = header.clientHeight;
            }
        }

        // Append the clone in the exact same element as the original to preserve styling
        this.parentEl.appendChild(this.clone);

        window.addEventListener('mousemove', this.dragMove);
        window.addEventListener('mouseup', this.dragEnd);

        window.addEventListener('touchmove', this.dragMove);
        window.addEventListener('touchend', this.dragEnd);

        document.addEventListener('touchmove', (e: Event) => e.preventDefault());
        document.addEventListener('selectstart', (e: Event) => e.preventDefault());
    }

    private dragMove(ev: MouseEvent | TouchEvent): void {
        ev.preventDefault();

        // Read comment above the reposition() fn for explanation
        if (!this.clone || this.repositioning) {
            return;
        }

        // His dragged state should always be true while moving, so the parent
        // - which also has acces to this reference - can act accordingly
        this.draggable.dragged = true;

        // Show the clone in the DOM
        this.clone.classList.add('show');

        // Compute his new position and make sure it stays in the border element
        const pageY: number = isTouchEvent(ev) ? getTouchEvent(ev).pageY : ev.pageY;

        this.position.updated = pageY - this.position.correction;

        // Make sure it's not being moved out of the border element
        // (i) Taking some extra margin into account to ensure a draggable
        // element's middle and bottom can also touch the border
        // elements' top for instance
        const borderRect: DOMRect = this.borderEl.getBoundingClientRect();
        const cloneRect: DOMRect = this.clone.getBoundingClientRect();

        let atTopBorder = false;
        let atBottomBorder = false;

        const top = borderRect.top - this.margins.borderEl;
        const bottom = borderRect.bottom + this.margins.borderEl;

        if (this.position.updated < top) {
            this.position.updated = top;

            atTopBorder = true;
        }

        if (this.position.updated + cloneRect.height > bottom) {
            this.position.updated = bottom - cloneRect.height;

            atBottomBorder = true;
        }

        // Make sure the document is scrolled programmatically when the item is being moved out of the viewport
        const move = 60;

        const viewportBottom: number = window.innerHeight || document.documentElement.clientHeight;

        if (
            !atTopBorder
            && this.position.updated - this.headerHeight < this.margins.viewport
        ) {
            this.repositioning = true;

            window.scroll(0, document.documentElement.scrollTop - move);

            this.position.correction -= move;
        } else if (
            !atBottomBorder
            && this.position.updated + cloneRect.height + this.margins.viewport
                > viewportBottom
        ) {
            this.repositioning = true;

            window.scroll(0, document.documentElement.scrollTop + move);

            this.position.correction += move;
        }

        // Update the position of the clone, but make sure it is not being
        // repositioned or is sufficiently dragged compared to the previous
        // emitted position before emitting his new position
        // (!) Otherwise it will just keep emitting new positions
        this.clone.style.top = `${this.position.updated}px`;

        if (this.repositioning) {
            return;
        }

        if (this.position.emitted !== null) {
            this.beingDragged = !(
                this.position.updated
                    > this.position.emitted - this.margins.emit
                && this.position.updated
                    < this.position.emitted + this.margins.emit
            );
        } else if (!this.beingDragged) {
            this.beingDragged = !(
                this.position.updated
                    > this.position.original - this.margins.emit
                && this.position.updated
                    < this.position.original + this.margins.emit
            );
        }

        // Do not emit the new position when it isn't sufficiently dragged
        // compared to the previous position
        if (!this.beingDragged) {
            return;
        }

        // Compute all new positions
        const newPosition: NewDraggablePosition = {
            top: this.position.updated,
            middle: this.position.updated + cloneRect.height / 2,
            bottom: this.position.updated + cloneRect.height,
        };

        this.$emit('dragged', this.draggable, newPosition);

        // Save the just emitted value so we can make sure the
        // draggable item has been dragged for at least {margin}
        // pixels before emitting another moved event
        this.position.emitted = this.position.updated;
    }

    private dragEnd(ev: MouseEvent | TouchEvent): void {
        ev.preventDefault();

        this.draggable.dragged = false;
        this.beingDragged = false;

        if (!this.parentEl || !this.clone) {
            return;
        }

        this.parentEl.removeChild(this.clone);
        this.clone = null;

        window.removeEventListener('mousemove', this.dragMove);
        window.removeEventListener('mouseup', this.dragEnd);

        window.removeEventListener('touchmove', this.dragMove);
        window.removeEventListener('touchend', this.dragEnd);

        document.removeEventListener('touchmove', (e: Event) => e.preventDefault());
        document.removeEventListener('selectstart', (e: Event) => e.preventDefault());

        // Notify the parent when the item has been dropped
        this.$emit('dropped', this.draggable);
    }

}
