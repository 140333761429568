
import { Color } from '@peterdekok/color-compare';
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Prop, Watch } from 'vue-property-decorator';
import { Comparison, ComparisonRegistration } from './ColorConformationLevels.vue';

export interface ColorRelation {
    id: string;
    value: string | null;
    name: string;
    location: 'above' | 'below';
}

@Component
export default class ColorConformationLevelContrast extends Vue {

    @InjectReactive('color-comparison-registration')
        register!: ComparisonRegistration;

    @Prop({ required: true, type: String })
        id!: string;

    @Prop({ required: true, type: String, default: null })
        related!: string | null;

    @Prop({ required: true, type: String })
        relatedName!: string;

    @Prop({ required: true, type: String })
        relatedLocation!: 'above' | 'below';

    comparison!: Comparison;

    created(): void {
        this.comparison = this.register(this.id, this.relatedColor);
    }

    beforeDestroy(): void {
        this.comparison.deregister();
    }

    get relatedColor(): Color | null {
        if (!this.related) {
            return null;
        }

        try {
            return new Color(this.related);
        } catch (e) {
            return null;
        }
    }

    get foregroundStyle() : string {
        const fgColor: Color | null = this.relatedLocation === 'above' ? this.relatedColor : this.comparison.target;

        return fgColor ? fgColor.formatted : 'var(--ot-color-core-foreground-primary)';
    }

    get backgroundStyle() : string {
        const bgColor: Color | null = this.relatedLocation === 'above' ? this.comparison.target : this.relatedColor;

        return bgColor ? bgColor.formatted : 'var(--ot-color-core-background-primary)';
    }

    get levelInfo(): string {
        if (!this.comparison.result) {
            return 'dashboard.components.color_conformation_level_contrast.level.info.undetermined';
        }

        switch (this.comparison.result.wcagConformance) {
            case 'aaa':
                return 'dashboard.components.color_conformation_level_contrast.level.info.strong';
            case 'aa':
                return 'dashboard.components.color_conformation_level_contrast.level.info.good';
            case 'a':
                return 'dashboard.components.color_conformation_level_contrast.level.info.poor';
            default:
                return 'dashboard.components.color_conformation_level_contrast.level.info.weak';
        }
    }

    @Watch('related')
    onRelatedChanged(): void {
        this.register(this.id, this.relatedColor);
    }

}
