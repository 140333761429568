import { VueConstructor } from 'vue';
import ConfettiWrapper from './ConfettiWrapper.vue';
import DashboardBanner from './DashboardBanner.vue';
import EmptyMessage from './EmptyMessage.vue';
import EventListItem from './list_items/EventListItem.vue';
import TicketListItem from './list_items/TicketListItem.vue';
import CompanyListItem from './list_items/CompanyListItem.vue';

export function registerComponents(app: VueConstructor): void {
    app.component('ConfettiWrapper', ConfettiWrapper);
    app.component('DashboardBanner', DashboardBanner);
    app.component('EmptyMessage', EmptyMessage);

    app.component('EventListItem', EventListItem);
    app.component('TicketListItem', TicketListItem);
    app.component('CompanyListItem', CompanyListItem);
}
