
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import { PreferredLocales, VueI18n } from '@openticket/vue-localization';
import AuthProvider from './auth';

@Component
export default class App extends Vue {

    initialized = false;

    @ProvideReactive('isAdmin')
        isAdmin = false;

    async created(): Promise<void> {
        try {
            // Retrieve localization and whitelabel data
            await Promise.all([
                this.initLocalization(),
                this.$whitelabel.init(),
            ]);

            this.initDocumentTitle();

            Vue.prototype.$auth = AuthProvider.init(
                this.$router,
                this.$whitelabel,
            );

            if (AuthProvider.authNeededForRoute(this.$route)) {
                if (!this.$auth.hasToken) {
                    void this.$router.push({
                        name: 'auth.login',
                        query: {
                            redirectUrl: this.$route.path,
                        },
                    });

                    return;
                }
                try {
                    const seed = await this.$auth.getMe();
                    Vue.prototype.$user = Vue.observable(seed.user);

                    if (!this.$route.query.as || this.$route.query.as === seed.company.guid) {
                        Vue.prototype.$company = Vue.observable(seed.company);
                    } else {
                        const company = await this.$auth.companies.get(this.$route.query.as as string);
                        Vue.prototype.$company = Vue.observable(company);
                    }

                    this.isAdmin = await this.$auth.isAdmin;
                } catch (e) {
                    console.error(e);
                    void this.$router.push({ name: 'auth.login' });
                }
            }
        } catch (e) {
            console.error(e);
            void this.$router.push({ name: 'error' });
        } finally {
            this.initialized = true;
        }
    }

    private async initLocalization(): Promise<void> {
        const preferredLocales: PreferredLocales = this.$localization.preferredLocales();

        const localeGetParamOverwrite = this.$route.query.locale as
            | string
            | null;

        await this.$localization.init({
            locale: {
                defaults: [
                    localeGetParamOverwrite,
                    preferredLocales.userOverwrite,
                    preferredLocales.browser,
                ],
            },
            urls: process.env.VUE_APP_DASHBOARD_TRANSLATIONS_URL
                ? {
                    translations:
                          process.env.VUE_APP_DASHBOARD_TRANSLATIONS_URL,
                }
                : {},
        });
    }

    private initDocumentTitle(): void {
        // Wrapping the translation method in an arrow-func, so the scope is set.
        const t = (key: string, values?: unknown[] | { [key: string]: string }): VueI18n.TranslateResult => this.$t(key, values);

        this.$documentTitle.setTranslator(t);

        this.$localization.on('locale-change', () => {
            this.$documentTitle.update(this.$route);
        });
    }

}
