
import { IShopModelProps, ShopModel } from '@openticket/lib-management';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import { ModalController } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive } from 'vue-property-decorator';

@Component
export default class NewShopModal extends Vue {

    @InjectReactive('shop')
    private shop!: ShopModel;

    private form!: ModelForm<ShopModel, IShopModelProps>;

    @Inject('modal')
    private modal!: ModalController;

    created(): void {
        this.form = new ModelForm(this.$management.shops, this.shop);
    }

    private async save(): Promise<void> {
        await this.form.submit();

        void this.$router.replace({
            name: 'shops.edit',
            params: {
                shop_id: this.shop.guid,
            },
        });

        this.$notifications.success('Shop is created');
    }

    private close(): void {
        void this.$router.push({
            name: 'shops',
        });
    }

}
