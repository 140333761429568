
import { EventModel, TicketModel } from '@openticket/lib-management';
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from 'vue-property-decorator';
import GenericTicketList from '../generic/TicketList.vue';

@Component({
    components: {
        GenericTicketList,
    },
})
export default class EventTicketList extends Vue {

    @InjectReactive('event')
        event!: EventModel;

    async edit(ticket: TicketModel): Promise<void> {
        await this.$router.push({
            name: 'events.tickets.edit',
            params: {
                ticketGuid: ticket.guid,
            },
        });
    }

}
