import { RouteConfig } from 'vue-router';
import AuthLogin from './views/Login.vue';
import AuthCallback from './views/Callback.vue';

const routes: RouteConfig[] = [
    {
        path: 'callback',
        name: 'auth.callback',
        component: AuthCallback,
        meta: {
            disableAuth: true,
        },
    },
    {
        path: 'login',
        name: 'auth.login',
        component: AuthLogin,
    },
];

export default routes;
