// Management SDK
import { CollapseModel, TicketModel } from '@openticket/lib-management';

/**
 *  Holding the properties that are needed
 *  for being a draggable item.
 */
export interface Draggable {
    dragged: boolean;
}

/**
 *  Holding the new top, middle and bottom position of
 *  a draggable item that are emitted at update.
 */
export interface NewDraggablePosition {
    /**
     *  The relative number of pixels of the draggable elements' top to the viewport.
     */
    top: number;

    /**
     *  The relative number of pixels of the draggable elements' middle to the viewport.
     */
    middle: number;

    /**
     *  The relative number of pixels of the draggable elements' bottom to the viewport.
     */
    bottom: number;
}

/**
 *  The type of local event item, which is used to
 *  act accordingly to different types.
 */
export enum LocalEventItemType {
    Collapse = 'collapse',
    Ticket = 'ticket',
    CollapseTicket = 'collapseTicket',
}

/**
 *  The level of the local event item.
 */
export enum LocalEventItemLevel {
    /**
     *  Including top level tickets and collapses.
     */
    First = 'first',
    /**
     *  Including tickets in collapses.
     */
    Second = 'second',
}

/**
 *  The index of a local item: the current one is used to paint it
 *  on the right spot within the list (sortedLocalItems) and the
 *  original is saved to check if it got updated at a drop - which
 *  is true if the current is not equal to the original anymore.
 */
export interface LocalEventItemIndex {
    current: number;
    original: number;
}

/**
 *  A local event item is created for every item, adding props
 *  that allow us to let the drag and drop work.
 */
export interface LocalEventItem<T = CollapseModel | TicketModel> extends Draggable {
    /** Guid of the event item. */
    guid: string;

    /** Index of the event item. */
    index: LocalEventItemIndex;

    /** Type of the event item. */
    type: 'collapse' | 'ticket';

    /**
     *
     *  Data of the event item.
     *
     *  (i) SDK models are converted to dashboard models
     *  for making them usable within the dashboard
     *
     */
    item: T;

    /**
     *
     *  Reference to the Vue component of the item.
     *
     *  (!) Must be set in the component's mount fn
     *
     */
    ref?: Vue;

    /**
     *
     *  Remove state of an item, regardless of the type of item.
     *
     *  (i) Used to actually remove the item from the local items. The
     *  item will be filtered out of the local items when the update.done()
     *  method is invoked and this flag is set to true.
     *
     */
    _remove?: boolean;
}

/**
 *  Holds, besides the default data of a local
 *  item, the collapse's (local event) tickets.
 */
export interface LocalEventCollapse extends LocalEventItem<CollapseModel> {
    tickets: LocalEventTicket[];
}

/**
 *  Holds, besides the default data of a local
 *  item, the ticket's collapse stats.
 */
export interface LocalEventTicket extends LocalEventItem<TicketModel> {
    /**
     *
     *  The collapse stats of a ticket.
     *
     *  (i) A ticket can be present in two collapses at the same
     *  time; his current one and his new one. This is needed
     *  because when a ticket gets dragged, he should be at his
     *  original spot and at his new spot at the same time.
     *
     *  (i) When a ticket is not in a collapse, it is being
     *  handled as a top level local even item; making use
     *  of his index object on the root.
     *
     */
    collapse: {
        /**
         *
         *  Holds the stats of the tickets' current collapse; the
         *  collapse he's currently in. Used for painting it in
         *  the right collapse (and index in that collapse) when
         *  moving a ticket around.
         *
         *  (i) When the ticket is dropped, and his index is
         *  not equal to his original index, it means it got
         *  moved into his current collase and a corresponding
         *  update event is emitted.
         *
         */
        current: {
            item: LocalEventCollapse | null;
            index: number | null;
            original_index: number | null;
        };
        /**
         *
         *  Holds the stats of the tickets's new collapse; the
         *  collapse he got dragged into.
         *
         *  (i) When the ticket is dropped, and his new stats are
         *  not empty, it means it got dragged to a new collapse
         *  and a corresponding update event is emitted.
         *
         */
        new: {
            item: LocalEventCollapse | null;
            index: number | null;
        };
        /**
         *  If the ticket got moved out of his collapse,
         *  and should be added to top level.
         */
        uncollapsed: boolean;
    };
    /**
     *  Remove state of the ticket: attached to it's checkbox
     *  and used to determine if a ticket should be removed
     *  when the "Remove tickets" button is clicked.
     */
    remove: boolean;
}

/**
 *  The base for an update that gets emitted to the parent.
 */
export interface BaseUpdate {
    /**
     *
     *  Callback method to invoke by the parent to make
     *  changes final after the update succeeded, or reverting
     *  everything to it's original state on failure.
     *
     *  (i) Forwarding any value discards the
     *  update and reverts any pending changes
     *
     */
    done: (discardUpdate?: boolean) => void;
}

/**
 *  The neighbors of an item, which are searched
 *  before emitting an update for the item.
 */
export interface LocalEventItemNeighbors {
    /**
     *  The item for which the event is emitted
     *  is located below this local event ticket.
     */
    after: LocalEventTicket | null;

    /**
     *  Item for which the event is emitted is
     *  located above this local event ticket.
     */
    before: LocalEventTicket | null;
}

/**
 *  The update that gets emitted to the parent once an
 *  item should be updated; which can either be a
 *  change in index or the request of removal.
 */
export interface Update<T = CollapseModel | TicketModel> extends BaseUpdate {
    /** The type of the local event item for which the update was emitted. */
    type: LocalEventItemType;

    /** Guid of the item for which the event is emitted. */
    guid: string;

    /** The (management model) item for which the event is emitted. */
    item: T;

    /** Holding every data that is needed to sort. */
    sort: {
        /** The level of the local item for which the update was emitted. */
        level: LocalEventItemLevel;

        /**
         *
         *  The corresponding tickets of the update.
         *
         *  (i) Holds a single ticket in case of
         *  a ticket (himself) or all the tickets
         *  of a collapse in case of a collapse.
         *
         */

        tickets: LocalEventTicket[];

        /**
         *  The current neighbors of the local event
         *  item for which the update was emitted.
         */
        neighbors: LocalEventItemNeighbors;
    };
    /** If the item for which the event is emitted should be removed. */
    remove?: boolean;
}

/**
 *  A ticket update includes, besides the default update
 *  stats, the ticket for which the event is emitted and
 *  stats regarding his collapse(s).
 */
export interface TicketUpdate extends Update<TicketModel> {
    /** Update stats of the collapse of the ticket. */
    collapse?: {
        /** It got added to this collapse. */
        added: LocalEventCollapse | null;

        /** It got removed from this collapse. */
        removed: LocalEventCollapse | null;

        /** It got moved out of a collapse to top level. */
        uncollapsed: boolean;
    };
}

/**
 *  An update that gets emitted to the parent
 *  if tickets should me removed.
 */
export interface RemoveTicketsUpdate extends BaseUpdate {
    /** Holding the tickets that should be removed. */
    tickets: LocalEventTicket[];

    /**
     *
     *  If the last ticket of the event is included
     *  in the tickets.
     *
     *  (i) The parent needs to know so it can show
     *  a message about an the fact an event can't
     *  contain empty events.
     *
     */
    includesLastTicket: boolean;
}
