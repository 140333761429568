
import Vue from 'vue';
import Component from 'vue-class-component';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import {
    EventdateModel,
    EventModel,
    ITicketModelProps,
    TicketModel,
} from '@openticket/lib-management';
import { Prop } from 'vue-property-decorator';
import TicketFormShopSelect from '../../components/TicketFormShopSelect.vue';

@Component({
    components: {
        TicketFormShopSelect,
    },
})
export default class GenericTicketForm extends Vue {

    @Prop()
        event!: EventModel;

    ticket: TicketModel | null = null;

    form: ModelForm<TicketModel, ITicketModelProps> | null = null;

    eventdates: EventdateModel[] = [];

    async created(): Promise<void> {
        const guid = this.$route.params.ticketGuid;

        if (!guid) {
            this.ticket = this.event.tickets.new();
        } else {
            this.ticket = await this.event.tickets.get(guid);
        }

        this.form = new ModelForm(
            {
                create: this.event.tickets.create,
                update: this.$management.tickets.update,
                rules: this.$management.tickets.rules,
            },
            this.ticket,
        );
    }

    async submit(): Promise<TicketModel> {
        await this.form!.submit();

        await this.ticket!.eventdates.linkMulti(this.eventdates);

        return this.ticket!;
    }

}
