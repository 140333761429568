var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"color-conformation-levels"},[_c('div',{staticClass:"color-conformation-levels__summary",on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},[_c('div',{staticClass:"color-conformation-levels__summary__line"},[(_vm.comparisonSummary)?_c('div',{staticClass:"color-conformation-levels__summary__line__contrast",style:({
                    width: `${100*_vm.comparisonSummary.minContrast/21}%`,
                    background: _vm.summaryRGB,
                })}):_vm._e()]),_c('div',{staticClass:"color-conformation-levels__summary__info ot-text-small-strong"},[_vm._v(_vm._s(_vm.$t(_vm.comparisonSummaryInfo)))]),_c('i',{staticClass:"oti is-small",class:_vm.comparisonSummaryIcon})]),_c('transition',{attrs:{"name":"accordion"},on:{"enter":_vm.startTransition,"after-enter":_vm.endTransition,"before-leave":_vm.startTransition,"after-leave":_vm.endTransition}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetails),expression:"showDetails"}],staticClass:"color-conformation-levels__details"},[_c('div',{staticClass:"color-conformation-levels__details__header"},[_c('div',{staticClass:"color-conformation-levels__details__header__description ot-text-small"},[_c('div',[_vm._v(_vm._s(_vm.$t('dashboard.components.color_conformation_levels.details.description')))]),_c('br'),_vm._l(({
                            'aaa': 'dashboard.components.color_conformation_levels.details.levels.aaa',
                            'aa': 'dashboard.components.color_conformation_levels.details.levels.aa',
                            'a': 'dashboard.components.color_conformation_levels.details.levels.a',
                            '-': 'dashboard.components.color_conformation_levels.details.levels.-',
                        }),function(description,level){return _c('div',{key:level,staticClass:"color-conformation-levels__details__header__description__explained"},[_c('div',{staticClass:"color-conformation-levels__details__header__description__explained__level"},[_vm._v(_vm._s(level))]),_c('div',[_vm._v(_vm._s(_vm.$t(description)))])])}),_c('br'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('dashboard.components.color_conformation_levels.details.more'))}})],2),_c('i',{staticClass:"oti oti-close",on:{"click":function($event){_vm.showDetails = false}}})]),_vm._t("default")],2)]),(_vm.showComparisonWarning)?_c('OtNotification',{attrs:{"type":"is-warning","icon":"oti-warning","title":_vm.$t('dashboard.components.color_conformation_levels.warning.comparison')}}):(_vm.showContrastWarning)?_c('OtNotification',{attrs:{"type":"is-warning","icon":"oti-warning","title":_vm.$t('dashboard.components.color_conformation_levels.warning.contrast')}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }