
import { EventdateModel, EventModel } from '@openticket/lib-management';
import {
    ArrayPaginatable,
    ModelForm,
    Paginatable,
    Pagination,
} from '@openticket/lib-sdk-helpers';
import { IInlineModal } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import EventdateForm from './EventdateForm.vue';

@Component({
    components: {
        EventdateForm,
    },
})
export default class EventdateList extends Vue {

    @Prop() event!: EventModel;

    @Ref('eventdate_modal')
        modal!: IInlineModal;

    eventdates: Pagination<EventdateModel> | null = null;

    editingEventdate: EventdateModel | null = null;

    pendingEventdates: EventdateModel[] = [];

    public async save(): Promise<void> {
        const promises: Promise<void>[] = [];

        this.pendingEventdates.forEach((eventdate) => {
            const form = new ModelForm(
                {
                    create: this.event.eventdates.create,
                    update: this.$management.eventdates.update,
                    rules: this.$management.eventdates.rules,
                },
                eventdate,
            );

            promises.push(form.submit());
        });

        // TODO: maybe use polyfill for Promise.when
        await Promise.all(promises);
    }

    created(): void {
        let paginatable!: Paginatable<EventdateModel>;

        if (!this.event.isNew) {
            // Use CRUD paginatable if event is created
            paginatable = this.event.eventdates;
        } else {
            // Use paginatable from pending array if not
            paginatable = new ArrayPaginatable(this.pendingEventdates);
        }

        this.eventdates = new Pagination(paginatable);

        void this.eventdates.init({
            defaults: {
                per_page: 10,
            },
        });
    }

    editEventdate(eventdate: EventdateModel): void {
        this.editingEventdate = eventdate;
        this.modal.open();
    }

    onCreatedEventdate(): void {
        void this.eventdates!.refresh();
    }

    addPendingEventdate(eventdate: EventdateModel): void {
        this.pendingEventdates.push(eventdate);
        void this.eventdates!.refresh();
    }

    columns = [
        {
            prop: 'name',
            type: 'string',
            label: 'Name',
        },
        {
            prop: 'capacity',
            type: 'string',
            label: 'Capacity',
        },
        {
            prop: 'start',
            type: 'string',
            label: 'Start',
        },
        {
            prop: 'end',
            type: 'string',
            label: 'End',
        },
    ];

}
