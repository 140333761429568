
import { Pagination, PaginationFilters } from '@openticket/lib-sdk-helpers';
import { copyToClipboard } from '@openticket/lib-utils';
import { IInlineModal } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import StaticShopPreview from '../components/StaticShopPreview.vue';

@Component({
    components: {
        StaticShopPreview,
    },
})
export default class ShopList extends Vue {

    @Ref('shop_form_modal')
        shopFormModal!: IInlineModal;

    shops = new Pagination(this.$management.shops);

    searchString: string | null = null;

    private async created(): Promise<void> {
        const searchString = this.$route.query ? this.$route.query.name : null;
        const filters: PaginationFilters = {};
        if (typeof searchString === 'string' && searchString.length) {
            filters.name = searchString;
            this.searchString = searchString;
        }

        await this.shops.init({
            defaults: {
                per_page: 6,
            },
            filters,
        });
    }

    private copyShopUrl(event: MouseEvent, url: string): void {
        event.preventDefault();
        copyToClipboard(url);
    }

    @Watch('searchString')
    private onSearch(): void {
        const query = { ...this.$route.query || {} };

        if (this.searchString && this.searchString.length) {
            void this.shops.addFilter('name', this.searchString);
            query.name = this.searchString;
        } else {
            void this.shops.removeFilter('name');
            delete query.name;
        }

        void this.$router.replace({
            path: this.$route.path,
            query,
        });
    }

}
