
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// Management SDK
import { ShopModel, IShopModelProps } from '@openticket/lib-management';

// Shop SDK
import { Event } from '@openticket/sdk-shop';

// SDK helpers
import { ModelForm } from '@openticket/lib-sdk-helpers';

// Tickets logic
import TicketsEvent from './Event.vue';

// Export so it can easily be imported via this component
export { DISCARD_UPDATE } from './Event.vue';

@Component({
    components: {
        TicketsEvent,
    },
})
export default class TicketsEvents extends Vue {

    @Prop({ required: true })
    private shop!: ShopModel;

    @Prop({ required: true })
    protected form!: ModelForm<ShopModel, IShopModelProps>;

    // The SDK model is used because it includes the items
    // to paint, which the dashboard model does not
    @Prop({ required: true })
    private events!: Event[];

}
