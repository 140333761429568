import VueRouter, { Route, RouteRecord } from 'vue-router';
import Axios from 'axios';
import { Whitelabel } from '@openticket/lib-whitelabels';
import AuthClient, { AuthConfig } from '@openticket/lib-auth';

interface LegacyParams {
    legacy: string;
    access_token: string;
    as: string;
}

export default class AuthProvider {

    public static init(router: VueRouter, whitelabel: Whitelabel): AuthClient {
        const http = Axios.create();

        const authConfig: AuthConfig = {
            http: http as AuthConfig['http'],
            clientId: whitelabel.dashboard.auth_client_id,
            clientSecret: whitelabel.dashboard.auth_client_secret,
            redirectUrl: `${whitelabel.dashboard.url}auth/callback`,
            baseUrl: whitelabel.dashboard.auth_api_url,
        };

        const legacyParams = this._getLegacyParams(router.currentRoute);

        if (legacyParams) {
            const fakeExpiryDate = new Date();
            fakeExpiryDate.setDate(fakeExpiryDate.getDate() + 180);

            authConfig.token = {
                access_token: legacyParams.access_token,
                expiry_date: Number.MAX_SAFE_INTEGER,
                refresh_token: '<temporaryAccessTokenUsed>',
                refresh_token_expiry_date: Number.MAX_SAFE_INTEGER,
            };
        }

        return new AuthClient(authConfig);
    }

    public static authNeededForRoute(route: Route): boolean {
        return !route.matched.some((record: RouteRecord) => record.meta.disableAuth);
    }

    public static _getLegacyParams(route: Route): LegacyParams | null {
        const legacy = route.query.legacy as string;
        const as = route.query.as as string;
        const access_token = route.query.access_token as string;

        if (!legacy || !as || !access_token) {
            return null;
        }

        return {
            legacy,
            as,
            access_token,
        };
    }

}
