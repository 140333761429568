
import { CompanyModel } from '@openticket/lib-auth';
import { LazyList } from '@openticket/lib-sdk-helpers';
import { OtLocaleFlag, OtLocaleSwitch } from '@openticket/vue-localization';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Ref } from 'vue-property-decorator';
import {
    IInlineModal,
    DialogController,
} from '@openticket/vue-dashboard-components';
import UserProfile from '../../pages/company/views/UserProfile.vue';
import { DashboardInteractionController } from '../Dashboard.vue';

@Component({
    components: {
        OtLocaleFlag,
        OtLocaleSwitch,
        UserProfile,
    },
})
export default class Profile extends Vue {

    @Inject('dialog')
        dialog!: DialogController;

    @Inject('dashboard')
        dashboard!: DashboardInteractionController;

    @Ref('modal')
    private modal!: IInlineModal;

    @Ref('ot-locale-switch')
        otLocaleSwitch!: OtLocaleSwitch;

    localeSwitchOpen = false;

    private companies: LazyList<CompanyModel> = new LazyList(
        this.$auth.companies,
    );

    private theme: string | null = null;

    async created(): Promise<void> {
        this.theme = 'light';
        await this.companies.init({
            defaults: {
                per_page: 3,
            },
        });
    }

    private openProfile(): void {
        this.modal.open();
    }

    onLocaleSwitchOpened(opened: boolean): void {
        this.localeSwitchOpen = opened;
    }

    private async logout(): Promise<void> {
        if (
            !(await this.dialog.confirm({
                title: 'Log out?',
                description:
                    'You will have to login again to access the dashboard',
                type: 'is-danger',
            }))
        ) {
            return;
        }

        this.$auth.logout();
        window.location.reload();
    }

    private setTheme(theme: string): void {
        const { classList } = document.body;
        if (theme === 'dark') {
            classList.remove('is-light');
            classList.add('is-dark');
        } else {
            classList.remove('is-dark');
            classList.add('is-light');
        }
    }

}
