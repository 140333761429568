
import { CompanyModel } from '@openticket/lib-auth';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class companyListItem extends Vue {

    @Prop() company!: CompanyModel;

    @Prop() currentCompany!: boolean;

}
