
import { EventModel } from '@openticket/lib-management';
import { Pagination } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class EventList extends Vue {

    events = new Pagination(this.$management.events);

    created(): void {
        void this.events.init();
    }

    viewEvent(event: EventModel): void {
        void this.$router.push({
            name: 'events.home',
            params: {
                eventGuid: event.guid,
            },
        });
    }

    columns = [
        {
            prop: 'name',
            type: 'string',
            label: 'Name',
        },
        {
            prop: 'category',
            type: 'string',
            label: 'Category',
        },
        {
            prop: 'start',
            type: 'string',
            label: 'Start date',
        },
        {
            prop: 'end',
            type: 'string',
            label: 'End date',
        },
    ];

}
