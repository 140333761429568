var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"events__event",class:{
        'events__event--last': _vm.last,
        'events__event--loading': _vm.loading,
    }},[_c('div',{staticClass:"event__heading px-s"},[_c('div',{staticClass:"event__info"},[_c('h3',{staticClass:"event__name is-primary"},[_vm._v(" "+_vm._s(_vm.event.name)+" ")]),_c('p',{staticClass:"event__date mt-3xs"},[_c('i',{staticClass:"date__icon oti oti-calendar"}),_c('span',[_vm._v(" "+_vm._s(_vm.eventDate)+" ")])])]),_c('div',{staticClass:"event__options"},[_c('button',{staticClass:"options__toggle-tickets button is-small",attrs:{"title":`Click to ${_vm.toggleTicketsLabel.toLowerCase()} all the tickets of ${_vm.event.name}`},on:{"click":_vm.toggleTickets}},[_vm._v(" "+_vm._s(_vm.toggleTicketsLabel)+" all tickets ")]),_c('button',{staticClass:"options__remove button is-small",attrs:{"title":"Click to remove the selected tickets from your shop","disabled":!_vm.selectedTickets.length},on:{"click":_vm.removeSelectedTickets}},[_vm._v(" Remove tickets "),(_vm.selectedTickets.length)?_c('em',{staticClass:"remove__counter"},[_vm._v(" "+_vm._s(_vm.selectedTickets.length)+" ")]):_vm._e()])])]),_c('div',{ref:`event_${_vm.event.guid}`,staticClass:"event__ticket-groups"},[(_vm.isMounted && _vm.sortedLocalItems.length)?_c('div',[_c('div',{staticClass:"ticket-groups__items"},_vm._l((_vm.sortedLocalItems),function(localItem,index){return _c('table',{key:localItem.guid,staticClass:"ticket-groups__item"},[(localItem.type === _vm.LocalEventItemType.Ticket)?_c('TicketsTicket',{key:localItem.guid,attrs:{"ticket":localItem,"event-name":_vm.event.name,"border-el":_vm.$refs[`event_${_vm.event.guid}`],"bottom":_vm.isBottomTicket(index)},on:{"dragged":(item, newPos) =>
                            _vm.itemDragged(
                                item,
                                newPos,
                                _vm.LocalEventItemType.Ticket
                            ),"dropped":(item) =>
                            _vm.itemUpdate(item, _vm.LocalEventItemType.Ticket)}}):_vm._e(),(localItem.type === _vm.LocalEventItemType.Collapse)?_c('TicketsCollapse',{key:localItem.guid,attrs:{"shop":_vm.shop,"form":_vm.form,"event":_vm.event,"collapse":localItem,"border-el":_vm.$refs[`event_${_vm.event.guid}`]},on:{"dragged":(item, newPos) =>
                            _vm.itemDragged(
                                item,
                                newPos,
                                _vm.LocalEventItemType.Collapse
                            ),"dropped":(item) =>
                            _vm.itemUpdate(
                                item,
                                _vm.LocalEventItemType.Collapse
                            ),"edit":function($event){return _vm.$emit('collapse-edit', localItem.item)},"remove":function($event){return _vm.itemUpdate(
                                localItem,
                                _vm.LocalEventItemType.Collapse,
                                _vm.REMOVE_ITEM
                            )},"ticket-dragged":(item, newPos) =>
                            _vm.itemDragged(
                                item,
                                newPos,
                                _vm.LocalEventItemType.CollapseTicket
                            ),"ticket-dropped":(item) =>
                            _vm.itemUpdate(
                                item,
                                _vm.LocalEventItemType.CollapseTicket
                            ),"remove-ticket":(item) =>
                            _vm.itemUpdate(
                                item,
                                _vm.LocalEventItemType.CollapseTicket,
                                _vm.REMOVE_ITEM
                            )}}):_vm._e()],1)}),0)]):_vm._e()]),_c('div',{staticClass:"event__bottom-actions"},[_c('button',{staticClass:"bottom-actions__create-collapse is-small",attrs:{"title":`Click to add a collapse to ${_vm.event.name}`},on:{"click":function($event){return _vm.$emit('collapse-create')}}},[_c('i',{staticClass:"date__icon oti oti-plus ot-button-icon--left"}),_vm._v(" Create group ")]),_c('OtCrudNestedPicker',{staticClass:"bottom-actions__add-tickets",attrs:{"relation":_vm.shop.tickets,"preselected-sums-model":_vm.event,"lock-level-two":true},on:{"success":_vm.refreshShop,"error":(e) => _vm.fail(e, 'Something went wrong while saving the ticket selection')},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('button',{staticClass:"ot-button is-small"},[_vm._v(" Add tickets ")])]},proxy:true},{key:"item",fn:function(item){return [_c('TicketListItem',{attrs:{"ticket":item}})]}}])})],1),(!_vm.last)?_c('div',{staticClass:"event__separator ot-separator"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }