
import Component from 'vue-class-component';
import { Prop, Mixins, Watch } from 'vue-property-decorator';

// Tickets logic
import TicketsItemOptions from './ItemOptions.vue';
import TicketsDragDrop from './DragDrop.vue';

// Tickets types
import { LocalEventTicket } from './types';

@Component({
    components: {
        TicketsItemOptions,
    },
})
export default class TicketsTicket extends Mixins(TicketsDragDrop) {

    @Prop({ required: true })
    private ticket!: LocalEventTicket;

    // The name of the event for which the ticket is,
    // being painted in the row of a ticket
    @Prop({ required: true })
    private eventName!: string;

    /**
     *
     *  If it's about a bottom ticket, either at the bottom
     *  of all top level items, at the bottom of a series
     *  tickets or at the bottom of a collapse.
     *
     *  (i) Different styling gets applied if so
     *
     */
    @Prop({ type: Boolean, required: true, default: false })
    private bottom!: boolean;

    public mounted(): void {
        // Needed because it's used for dragging position computation
        this.ticket.ref = this;

        // Init the drag and drop logic, which is mixed in
        this.makeDraggable();
    }

    /**
     *
     *  Inits the drag and drop logic, and makes sure to re-init
     *  the drag and drop logic when the ticket changes.
     *
     *  (i) What happens when it got reordered
     *
     */
    @Watch('ticket')
    private makeDraggable(): void {
        this.initDragDrop(
            this.ticket,
            this.$el as HTMLElement,
            this.$refs.options,
            true,
        );
    }

}
