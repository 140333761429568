import VueRouter, {
    Route,
    RouteConfig,
} from 'vue-router';
import Vue, { VueConstructor } from 'vue';
import Dashboard from './layout/Dashboard.vue';
import shopRoutes from './pages/shop/router';
import authRoutes from './pages/auth/router';
import eventRoutes from './pages/event/router';
import companyRoutes from './pages/company/router';
import ErrorView from './pages/Error.vue';

const RouterView = {
    render: (h: (c: string) => Vue) => h('router-view'),
} as unknown as VueConstructor;

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/error/:log_id?',
        name: 'error',
        component: ErrorView,
        meta: {
            disableAuth: true,
        },
    },
    {
        path: '/auth',
        children: [ ...authRoutes ],
        component: RouterView,
        meta: {
            disableAuth: true,
        },
    },
    {
        path: '/',
        component: Dashboard,
        name: 'home',
        redirect: { path: 'shops' },
        children: [ ...shopRoutes, ...companyRoutes, ...eventRoutes ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to: Route, from: Route, savedPosition) {
        if ((to.meta && to.meta.modal) || (from.meta && from.meta.modal)) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
});

export default router;
