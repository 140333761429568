import { VueI18n } from '@openticket/vue-localization';
import Vue, { VueConstructor } from 'vue';
import VueRouter, { Route, RouteRecord } from 'vue-router';

interface Value {
    record: RouteRecord;
    key: string;
    value: string;
}

interface Translator {
    (slug: string, values?: { [key: string]: string }): VueI18n.TranslateResult;
}

export default class VueDocumentTitlePlugin {

    private values: Value[] = [];

    private translator: null | Translator = null;

    static install(_Vue: VueConstructor, $router: VueRouter): void {
        const title = new VueDocumentTitlePlugin();

        _Vue.prototype.$documentTitle = title;

        $router.afterEach((to: Route) => {
            title.update(to);
        });
    }

    setValue(component: Vue, key: string, value: string): void {
        try {
            // Title replacement values are set for the current matched route record's default instance.
            // When changing to other routes, parts of this chain can be kept
            // (i.e. (parent) route records shared between both the from and to routes).
            const record: RouteRecord | undefined = component.$route.matched
                .find((routeRecord: RouteRecord) => routeRecord.instances
                    && routeRecord.instances.default
                    && routeRecord.instances.default === component);

            if (!record) {
                return;
            }

            // For each key, only one value can exist, independent of route record they belong to.
            const val: undefined | Value = this.values.find((existing: Value) => existing.key === key);

            if (val) {
                val.record = record;
                val.value = value;
            } else {
                this.values.push({
                    record,
                    key,
                    value,
                });
            }

            this.update(component.$route);
        } catch (e) {
            // Actions regarding the document title should never break execution flow anywhere!
            console.error('Failed to set document title value', e);
        }
    }

    setTranslator(translator: Translator): void {
        try {
            this.translator = translator;
        } catch (e) {
            // Actions regarding the document title should never break execution flow anywhere!
            // Note, if the above fails, yeah, the app is most likely f-ed anyway,
            // however, to ne thorough, the try-catch is implemented here as well.
            console.error('Failed to set document title value', e);
        }
    }

    update(to: Route): void {
        try {
            // Remove any values which do not match any of the matched routes for the destination of the page transition.
            this.values = this.values.filter((value: Value) => to.matched.some((record: RouteRecord) => record === value.record));

            // The default suffix, so something is always shown,
            // even if the translator does not exist, is not set yet,
            // or the translation value is not defined.
            let suffix = 'Dashboard';

            if (this.translator) {
                const values = this.values.reduce((carry: { [key: string]: string }, value: Value) => {
                    carry[value.key] = value.value;

                    return carry;
                }, {});

                suffix = this.translate('dashboard.document_title.main', values, suffix);

                const titles: string[] = to.matched.map((record: RouteRecord) => {
                    if (!record.meta.title) {
                        return '';
                    }

                    return this.translate(record.meta.title, values, '');
                })
                    .filter((part: string) => !!part)
                    .reverse();

                window.document.title = titles.length ? `${titles.join(' | ')} | ${suffix}` : suffix;
            } else {
                // Fallback
                window.document.title = suffix;
            }
        } catch (e) {
            // Setting the document title should never break execution anywhere!
            console.error('Failed to set document title', e);
        }
    }

    private translate(slug: string, values: undefined | { [key: string]: string }, fallback: string): string {
        if (this.translator) {
            const translation: VueI18n.TranslateResult = this.translator(slug, values);

            if (translation && typeof (translation as unknown) === 'string' && translation !== slug) {
                return translation as string;
            }
        }

        return fallback;
    }

}
