
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AuthLogin extends Vue {

    created(): void {
        const state = (this.$route.query.redirectUrl || '/') as string;

        window.location.href = this.$auth.getAuthorizationURL(state);
    }

}
