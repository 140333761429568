
import { EventModel, TicketModel } from '@openticket/lib-management';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import EventFormHeader from '../components/EventFormHeader.vue';
import GenericTicketForm from './generic/TicketForm.vue';

@Component({
    components: {
        EventFormHeader,
        GenericTicketForm,
    },
})
export default class EditTicket extends Vue {

    event: EventModel | null = null;

    ticket: TicketModel | null = null;

    @Ref('ticket_form')
        form!: GenericTicketForm;

    async created(): Promise<void> {
        const { eventGuid } = this.$route.params;
        const { ticketGuid } = this.$route.params;

        const [ event, ticket ] = await Promise.all([
            this.$management.events.get(eventGuid),
            ticketGuid
                ? this.$management.tickets.get(ticketGuid)
                : this.$management.tickets.new(),
        ]);

        this.event = event;
        this.ticket = ticket;
    }

    async submit(): Promise<void> {
        const isNew = this.$route.params.ticketGuid;
        const ticket = await this.form.submit();

        this.$notifications.success('Ticket saved');

        if (isNew) {
            // Add guid to current route first,
            // so the user can go back to the saved
            // ticket via browser navigation
            await this.$router.replace({
                name: 'events.tickets.edit',
                params: {
                    ticketGuid: ticket.guid,
                },
            });
        }

        await this.$router.push({
            name: 'events.tickets',
        });
    }

}
