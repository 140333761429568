
import { Color, WCAGConformanceLevel } from '@peterdekok/color-compare';
import type { CompareResult } from '@peterdekok/color-compare';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, ProvideReactive, Watch } from 'vue-property-decorator';

export interface Comparison {
    key: string;
    result: CompareResult | null;
    target: Color | null;
    related: Color | null;
    deregister: () => void;
}

export interface ComparisonRegistration {
    (key: string, related: Color | null): Comparison;
}

function contrastRatioToRGBRating(ratio: number, lower: number, middle: number, upper: number) {
    const red = (1 - ((ratio - middle) / (upper - middle))) * 255;
    const green = 255 * ((ratio - lower) / (middle - lower));

    return `rgb(${red}, ${green}, 0)`;
}

@Component
export default class ColorConformationLevels extends Vue {

    @Prop({ required: true, default: null })
        target!: string | null;

    comparisons: { [key: string]: Comparison } = {};

    comparisonSummary: { level: WCAGConformanceLevel; minContrast: number } | null = null;

    showDetails = false;

    get comparisonSummaryInfo(): string {
        if (!this.comparisonSummary) {
            return 'dashboard.components.color_conformation_levels.summary.info.undetermined';
        }

        switch (this.comparisonSummary.level) {
            case 'aaa':
                return 'dashboard.components.color_conformation_levels.summary.info.strong';
            case 'aa':
                return 'dashboard.components.color_conformation_levels.summary.info.good';
            case 'a':
                return 'dashboard.components.color_conformation_levels.summary.info.poor';
            default:
                return 'dashboard.components.color_conformation_levels.summary.info.weak';
        }
    }

    get comparisonSummaryIcon(): string {
        if (this.comparisonSummary) {
            return this.showDetails ? 'oti-info-active' : 'oti-info';
        }

        return this.showDetails ? 'oti-warning-active' : 'oti-warning';
    }

    get showComparisonWarning(): boolean {
        return !this.comparisonSummary;
    }

    get showContrastWarning(): boolean {
        return !this.comparisonSummary || !this.comparisonSummary.level;
    }

    get targetColor(): Color | null {
        if (!this.target) {
            return null;
        }

        try {
            return new Color(this.target);
        } catch (e) {
            return null;
        }
    }

    get summaryRGB(): string {
        if (!this.comparisonSummary) {
            return 'var(--ot-color-core-accent-primary)';
        }

        return contrastRatioToRGBRating(this.comparisonSummary.minContrast, 2.5, 4, 7);
    }

    @Watch('target')
    onTargetChanged(): void {
        for (const key in this.comparisons) {
            this.comparisons[key].target = this.targetColor;

            const { related } = this.comparisons[key];

            if (this.targetColor && related) {
                this.comparisons[key].result = this.targetColor.compare(related);
            } else {
                this.comparisons[key].result = null;
            }
        }

        this.updateComparisonSummary();
    }

    updateComparisonSummary(): void {
        if (!Object.keys(this.comparisons).length) {
            this.comparisonSummary = null;

            return;
        }

        this.comparisonSummary = Object.values(this.comparisons)
            .reduce((carry: { level: WCAGConformanceLevel, minContrast: number } | null, comparison: Comparison) => {
                if (!carry || !comparison.result) {
                    return null;
                }

                if (comparison.result.rawPerceivableValues.contrast < carry.minContrast) {
                    return {
                        level: comparison.result.wcagConformance,
                        minContrast: comparison.result.rawPerceivableValues.contrast,
                    };
                }

                return carry;
            }, {
                level: 'aaa',
                minContrast: 21,
            });
    }

    @ProvideReactive('color-comparison-registration')
    comparisonRegistration(key: string, related: Color | null): Comparison {
        if (!this.comparisons[key]) {
            this.comparisons[key] = Vue.observable({
                key,
                target: this.targetColor,
                related: null,
                result: null,
                deregister: () => this.deregister(key),
            });
        }

        this.comparisons[key].related = related;

        if (this.targetColor && related) {
            this.comparisons[key].result = this.targetColor.compare(related);
        } else {
            this.comparisons[key].result = null;
        }

        this.updateComparisonSummary();

        return this.comparisons[key];
    }

    deregister(key: string): void {
        delete this.comparisons[key];

        this.updateComparisonSummary();
    }

    startTransition(el: HTMLElement): void {
        el.style.height = `${el.scrollHeight}px`;
    }

    endTransition(el: HTMLElement): void {
        el.style.height = '';
    }

}
