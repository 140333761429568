
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { CrossWindowClient } from '@openticket/sdk-shop';

@Component
export default class ShopPreview extends Vue {

    @Prop() url!: string;

    @Prop() iframeUrl!: string;

    @Ref('container')
        container!: HTMLElement;

    @Ref('iframe')
        iframe!: HTMLIFrameElement;

    private crossWindowClient: CrossWindowClient | null = null;

    private onLoad(): void {
        const win = this.iframe.contentWindow as Window;

        this.crossWindowClient = new CrossWindowClient(win, {
            type: 'dashboard',
        });

        this.$emit('load', this.crossWindowClient);
    }

    public refresh(): void {
        this.iframe.src = `${this.iframe.src}`;
    }

    public get noCacheUrl(): string {
        return `${this.iframeUrl}?nocache`;
    }

}
