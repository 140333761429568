
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Ref } from 'vue-property-decorator';
import { EventModel } from '@openticket/lib-management';
import EventForm from '../generic/EventForm.vue';
import FormHeader from '../../components/FormHeader.vue';
import FormFooter from '../../components/FormFooter.vue';

@Component({
    components: {
        EventForm,
        FormHeader,
        FormFooter,
    },
})
export default class CreateEventForm extends Vue {

    @InjectReactive('event')
        event!: EventModel;

    @Ref('event_form')
        form!: EventForm;

    async submit(next = false): Promise<void> {
        const isNew = !this.$route.params.eventGuid;

        const event = await this.form.submit();

        if (isNew) {
            this.$notifications.success('Event created');

            // Add guid to current route first,
            // so the user can go back to the saved
            // event via browser navigation

            if (!next) {
                await this.$router.replace({
                    name: 'events.new.edit',
                    params: {
                        eventGuid: event.guid,
                    },
                });
            }
        } else {
            this.$notifications.success('Event updated');
        }

        if (next) {
            await this.$router.push({
                name: 'events.new.tickets.new',
                params: {
                    eventGuid: event.guid,
                },
            });
        }
    }

}
