
import { LocationModel, ILocationModelProps } from '@openticket/lib-management';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class LocationForm extends Vue {

    @Prop() private form!: ModelForm<LocationModel, ILocationModelProps>;

    @Prop() private cancel!: () => void;

    @Prop() private save!: () => void;

}
