
import { EventModel } from '@openticket/lib-management';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EventListItem extends Vue {

    @Prop() event!: EventModel;

}
