
import { CompanyModel } from '@openticket/lib-auth';
import ManagementClient, { EventModel } from '@openticket/lib-management';
import { IInlineModal } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide, Ref } from 'vue-property-decorator';
import CompanySelectModal from './modals/CompanySelectModal.vue';
import DashboardLayout from './DashboardLayout.vue';

export interface DashboardInteractionController {
    currentEvent: EventModel | null;

    setCurrentEvent(event: EventModel): void;
    openCompanySelectModal(): void;
    selectCompany(company: CompanyModel): void;
}

@Component({
    components: {
        DashboardLayout,
        CompanySelectModal,
    },
})
export default class Dashboard extends Vue {

    message = '';

    initialized = false;

    @Provide('dashboard')
        dashboard: DashboardInteractionController = {
            currentEvent: null,
            setCurrentEvent: (event) => this.setCurrentEvent(event),
            openCompanySelectModal: () => this.openCompanySelectModal(),
            selectCompany: (company) => this.selectCompany(company),
        };

    @Ref('company_select_modal')
        companySelectModal!: IInlineModal;

    created(): void {
        const management = new ManagementClient({
            http: this.$auth.$http,
            companyId: this.$company.guid,
            baseUrl: process.env.VUE_APP_MANAGEMENT_API_URL,
            shopUrl: process.env.VUE_APP_SHOP_URL || this.$whitelabel.shop.url,
        });

        Vue.prototype.$management = Vue.observable(management);

        this.$documentTitle.setValue(this, 'whitelabel', this.$whitelabel.name);

        window.addEventListener('keydown', this.shortcutListener);
    }

    async mounted(): Promise<void> {
        await this._checkCompanyParam();
        this._ensureCompanyParam();

        const seed = await this.$management.getSeed();

        if (seed.current_event) {
            this.setCurrentEvent(seed.current_event);
        }

        this.initialized = true;
    }

    private async _checkCompanyParam(): Promise<void> {
        const asCompany = this.$route.query.as as string;

        if (!asCompany) {
            // Add ?as param after auth init
            await this.$router.replace({
                path: this.$route.fullPath,
                query: {
                    ...this.$route.query,
                    as: this.$company.guid,
                },
            });
        }
    }

    private _ensureCompanyParam(): void {
        this.$router.beforeEach((to, _, next) => {
            // Pass ?as param to new route
            if (!to.query.as && this.$auth.hasToken) {
                // Do not use next() here, as a redirect will cause the
                // router.push promise to be rejected.
                void this.$router.push({
                    path: to.path,
                    params: to.params,
                    query: {
                        ...to.query,
                        as: this.$company.guid,
                    },
                });
            } else {
                next();
            }
        });
    }

    destroyed(): void {
        window.removeEventListener('keydown', this.shortcutListener);
    }

    setCurrentEvent(event: EventModel): void {
        this.dashboard.currentEvent = event;
    }

    openCompanySelectModal(): void {
        this.companySelectModal.open();
    }

    selectCompany(company: CompanyModel): void {
        Vue.prototype.$company = company;

        this.$management.setCompanyScope(company.guid);

        this.message = `Selecting ${company.props.name}...`;

        void this.$router.push({
            name: 'events',
        });

        setTimeout(() => {
            this.message = '';
        }, 500);
    }

    shortcutListener(event: KeyboardEvent): void {
        if (event.ctrlKey) {
            switch (event.key) {
                case 'k':
                    this.openCompanySelectModal();
                    break;
                default:
                    return;
            }
            event.preventDefault();
        }
    }

}
