
import Vue from 'vue';
import Component from 'vue-class-component';
import {
    Inject,
    InjectReactive,
    Ref,
    Watch,
} from 'vue-property-decorator';
import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import { ShopModel } from '@openticket/lib-management';
import { CrossWindowClient } from '@openticket/sdk-shop';
import {
    DialogController,
    ModalController,
} from '@openticket/vue-dashboard-components';
import LiveShopPreview from '../components/LiveShopPreview.vue';
import ShopSettingsOrder from './shopsettings/ShopSettingsOrder.vue';
import ShopSettingsStyle from './shopsettings/ShopSettingsStyle.vue';
import ShopSettingsShop from './shopsettings/ShopSettingsShop.vue';

function sendLegacyDashboardCallback(): void {
    try {
        if (window.opener && window.opener.postMessage) {
            window.opener.postMessage('shop-settings-updated', 'https://dashboard.eventix.io');
        }
    } catch (e) {
        console.debug('error while calling legacy dashboard', e);
    }
}

@Component({
    components: {
        ShopSettingsOrder,
        ShopSettingsStyle,
        ShopSettingsShop,
        LiveShopPreview,
    },
})
export default class ShopSettings extends Vue {

    @InjectReactive('shop')
    private shop!: ShopModel;

    @InjectReactive('settings')
    private settings!: CustomShopSettingsClient;

    @Inject('modal')
    private modal!: ModalController;

    @Inject('dialog')
    private dialog!: DialogController;

    @Ref('preview')
    private preview!: LiveShopPreview;

    private crossWindowClient: CrossWindowClient | null = null;

    private hasLocalChanges = false;

    private currentTab = Object.keys(this.tabs)[0];

    private get isOpenedFromOutside(): boolean {
        return Object.prototype.hasOwnProperty.call(this.$route.query, 'legacy');
    }

    private get tabs(): { [key: string]: string } {
        return {
            style: this.$t('dashboard.shop.settings.tab.style') as string,
            shop: this.$t('dashboard.shop.settings.tab.shop') as string,
            order: this.$t('dashboard.shop.settings.tab.order') as string,
        };
    }

    private created(): void {
        this.modal.registerBeforeClose(() => this.beforeClose());
    }

    private async beforeClose(): Promise<boolean> {
        if (this.isOpenedFromOutside) {
            return false;
        }

        if (this.hasLocalChanges) {
            const dialogResponse = await this.dialog.confirm({
                title: this.$t('dashboard.common.confirm.undo_unsaved') as string,
                description: 'This action cannot be undone', // TODO
                type: 'is-danger',
            });

            if (!dialogResponse) {
                return false;
            }
        }

        void this.settings.refresh();

        return true;
    }

    private async onLoadPreview(client: CrossWindowClient): Promise<void> {
        this.crossWindowClient = client;
        await client.connecting;

        this.crossWindowClient.setStaticShopSettings(this.settings.static);
    }

    private async save(close = false): Promise<void> {
        await this.settings.updateStaticSettings(this.settings.static);

        this.hasLocalChanges = false;

        this.$notifications.success(this.$t('dashboard.common.notification.save.success'));

        if (close) {
            await this.modal.close();
        }

        sendLegacyDashboardCallback();
    }

    private async softResetShopSettings(): Promise<void> {
        await this.settings.refresh();
        this.hasLocalChanges = false;
    }

    private onReset(): void {
        this.hasLocalChanges = false;

        this.preview.refresh();

        sendLegacyDashboardCallback();

        if (this.crossWindowClient) {
            this.crossWindowClient.setStaticShopSettings(this.settings.static);
        }
    }

    @Watch('settings.static', { deep: true })
    private onSettingsChange(): void {
        this.hasLocalChanges = true;

        if (this.crossWindowClient) {
            this.crossWindowClient.setStaticShopSettings(this.settings.static);
        }
    }

}
