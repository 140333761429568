
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch, Inject } from 'vue-property-decorator';

// Management SDK
import {
    ShopModel,
    IShopModelProps,
    CollapseModel,
    TicketModel, ICollapseModelProps, ITicketModelProps,
} from '@openticket/lib-management';

// Shop SDK
import {
    Event, EventItem, Ticket, Collapse,
} from '@openticket/sdk-shop';

// SDK helpers
import { ModelForm } from '@openticket/lib-sdk-helpers';

// Tickets logic
import TicketsCollapse from './Collapse.vue';
import TicketsTicket from './Ticket.vue';

// Tickets types
import {
    NewDraggablePosition,
    LocalEventItem,
    LocalEventItemType,
    LocalEventItemIndex,
    LocalEventItemLevel,
    LocalEventTicket,
    LocalEventCollapse,
    Update,
    LocalEventItemNeighbors,
    TicketUpdate,
    RemoveTicketsUpdate,
} from './types';

/**
 *  Types of possible events this component emits.
 */
enum UpdateEvent {
    CollapseEvent = 'collapse-update',
    TicketEvent = 'ticket-update',
}

/**
 *  Forward as parameter to the update's done method to
 *  discard the update and revert pending changes.
 */
export const DISCARD_UPDATE: true = true as const;

@Component({
    components: {
        TicketsCollapse,
        TicketsTicket,
    },
})
export default class TicketsEvent extends Vue {

    @Prop({ required: true })
    protected form!: ModelForm<ShopModel, IShopModelProps>;

    @Prop({ required: true })
    private shop!: ShopModel;

    // The SDK model is used because it includes the items
    // to paint, which the dashboard model does not
    @Prop({ required: true })
    private event!: Event;

    // If it's about the last event in the view,
    // so it knows when to add a border bottom
    @Prop({ type: Boolean, required: true, default: false })
    private last!: boolean;

    // Used to refresh the shop when tickets have been
    // selected using the nested picker
    @Inject('refreshShop')
    private refreshShop!: VoidFunction;

    // Used to throw an error when selecting tickets
    // using the nested picker fail
    @Inject('fail')
    private fail!: (error: Error, userMessage: string) => void;

    // Expose the local event item type enum to the view
    private LocalEventItemType = LocalEventItemType;

    // Expose const for removing an item, which should be forwarded as third
    // param to the update method to remove the item
    private REMOVE_ITEM = true;

    // Disables the user from dragging items while loading (after an update)
    private loading = false;

    // Used to make sure the group components only get painted if true, so the ref
    // of the event is available at time of painting for the group component
    private isMounted = false;

    // Holds the local event items; the actual event items wrapped in
    // objects to enable front-end drag/drop logic
    private localItems: LocalEventItem[] = [];

    // Holds the timeout for acting upon the movement of an item
    private itemMovedDelay!: void | number | undefined;

    /**
     *  Only let it render the items after the event
     *  component has been mounted, so the ref of
     *  the event is defined for forwarding it as
     *  border element to the items.
     */
    public mounted(): void {
        this.isMounted = true;
    }

    /**
     *  Used to get the date of the event, formatted
     *  according to the user's settings
     */
    private get eventDate(): string {
        if (!this.event.start || !this.event.end) {
            return '';
        }

        return this.$l.dateTimeRangeCollapseLong(
            this.event.start,
            this.event.end,
        );
    }

    /**
     *  Returns the items sorted based on their current index.
     */
    private get sortedLocalItems(): LocalEventItem[] {
        if (!this.localItems.length) {
            return [];
        }

        return this.localItems.sort(
            (
                localItemA: LocalEventItem,
                localItemB: LocalEventItem,
            ): number => (localItemA.index.current === localItemB.index.current
                ? 0
                : localItemA.index.current - localItemB.index.current),
        );
    }

    /**
     *
     *  Used to get a flat Array - not taking collapse boundaries
     *  into account - of all the tickets of the event.
     *
     *  (i) Used for getting the before/after anchors for sorting,
     *  because sorting always happens on ticket level.
     *
     */
    private get tickets(): LocalEventTicket[] {
        const tickets: LocalEventTicket[] = [];

        for (const localItem of this.localItems) {
            if (localItem.type === LocalEventItemType.Ticket) {
                tickets.push(localItem as LocalEventTicket);
            } else {
                tickets.push(...(localItem as LocalEventCollapse).tickets);
            }
        }

        return tickets;
    }

    /**
     *  Used to get the currently selected tickets,
     *  based on the ticket's remove state - which
     *  is bound to his checkbox.
     */
    private get selectedTickets(): LocalEventTicket[] {
        return this.tickets.filter((localEventTicket: LocalEventTicket) => localEventTicket.remove);
    }

    /**
     *
     *  Used to get the correct label for the ticket's
     *  toggle button, based on the fact if a ticket
     *  has been selected.
     *
     *  @todo Translate
     *
     */
    private get toggleTicketsLabel(): string {
        return this.selectedTickets.length ? 'Deselect' : 'Select';
    }

    /**
     *  Used to sync the local event items with the actual event's items;
     *  wrapping them in a local object to add some front-end properties
     *  to enable the dragging and dropping of items.
     */
    @Watch('event.items', { immediate: true, deep: true })
    private syncLocalItems(): void {
        this.event.items.forEach((eventItem: EventItem, index: number) => {
            let alreadyLocal = false;

            /**
             *  Because the components use the local event item's
             *  item ref to paint details, we only have to sync
             *  tickets of a collapse on an update.
             */
            this.localItems.every((localEventItem: LocalEventItem) => {
                if (eventItem.item.guid === localEventItem.guid) {
                    alreadyLocal = true;

                    /**
                     * Also sync the item's props.
                     *
                     * (!) Necessary because the ticket and groups logic
                     * is build upon Shop SDK models, but the parent needs
                     * Management SDK models to save changes.
                     *
                     * @todo Sync model types
                     */
                    localEventItem.item.props = ({ ...eventItem.item } as unknown as ICollapseModelProps | ITicketModelProps);

                    if (localEventItem.type === LocalEventItemType.Collapse) {
                        this.enrichLocalEventCollapse(
                            localEventItem as LocalEventCollapse,
                            eventItem.tickets,
                        );
                    }
                }

                return !alreadyLocal;
            });

            // Do not add it locally twice
            if (alreadyLocal) {
                return;
            }

            // Create a default local event item
            const localItem: LocalEventItem = this.createLocalEventItem(
                eventItem.item.guid,
                index,
                eventItem.type,
                eventItem.item,
            );

            // Enrich the default local event item according to his type
            switch (eventItem.type) {
                case 'collapse':

                    this.enrichLocalEventCollapse(
                        localItem as LocalEventCollapse,
                        eventItem.tickets,
                    );

                    break;

                case 'ticket':

                    this.enrichLocalEventTicket(
                        localItem as LocalEventTicket,
                        index,
                    );

                    break;

                default:

                    throw new Error(`Event.vue → Event item type not recognized: ${eventItem.type}`);
            }

            this.localItems.push(localItem);
        });
    }

    /**
     *
     *  Used to create a default local event item object, which is used
     *  as basis for both local event collapses as local event tickets.
     *
     *  (i) Also transforms SDK models to dashboard models so they
     *  can be used by the dashboard.
     *
     */
    private createLocalEventItem(
        guid: string,
        index: number,
        type: 'collapse' | 'ticket',
        item: Collapse | Ticket,
    ): LocalEventItem {
        let transformedModel: CollapseModel | TicketModel;

        switch (type) {
            case 'collapse':

                transformedModel = this.shop.collapses.new({
                    guid: (item as Collapse).guid,
                    title: (item as Collapse).title,
                });

                break;

            case 'ticket':

                transformedModel = this.shop.tickets.new({
                    guid: (item as Ticket).guid,
                    name: (item as Ticket).name,
                });

                break;

            default:

                throw new Error(`Event.vue → createLocalEventItem: unknown type "${type}"`);
        }

        return {
            guid,
            index: {
                current: index,
                original: index,
            },
            type,
            item: transformedModel,
            dragged: false,
        };
    }

    /**
     *  Used to enrich a local event collapse; transforming and
     *  adding his tickets as local event tickets.
     */
    private enrichLocalEventCollapse(
        collapse: LocalEventCollapse,
        tickets: Ticket[],
    ): void {
        collapse.tickets = tickets.map((ticket: Ticket, index: number) => {
            const localItem: LocalEventItem = this.createLocalEventItem(
                ticket.guid,
                index,
                'ticket',
                ticket,
            );

            this.enrichLocalEventTicket(
                localItem as LocalEventTicket,
                index,
                collapse,
            );

            return localItem as LocalEventTicket;
        });
    }

    /**
     *
     *  Used to enrich a local event ticket; adding his collapse
     *  object that keeps track of his current/new collapse stats.
     *
     *  (i) Read more about a ticket's collapse stats in the
     *  'ticket-groups_ticket.vue' file
     *
     */
    private enrichLocalEventTicket(
        ticket: LocalEventTicket,
        index: number,
        collapse?: LocalEventCollapse,
    ): void {
        /**
         *  Set the ticket specific remove state, which
         *  is bound to his remove checkbox.
         */
        ticket.remove = false;

        /**
         *  Give the ticket the same top level index as his
         *  collapse, so the moving around works as expected
         *  when moving a ticket out of a collapse
         */
        if (collapse) {
            ticket.index = {
                ...collapse.index,
            };
        }

        ticket.collapse = {
            current: {
                item: collapse || null,
                index,
                original_index: index,
            },
            new: {
                item: null,
                index: null,
            },
            uncollapsed: false,
        };
    }

    /**
     *  Used to toggle the removal state of every
     *  ticket, based on the fact if there are
     *  currently tickets selected.
     */
    private toggleTickets(): void {
        const state = !this.selectedTickets.length;

        for (const ticket of this.tickets) {
            ticket.remove = state;
        }
    }

    /**
     *
     *  Used for determining if the ticket that currently
     *  is being painted is at the bottom - of the list, a
     *  series of tickets or collapse.
     *
     *  (i) Different styling is applied to bottom
     *  tickets to keep the design intact
     *
     */
    private isBottomTicket(index: number): boolean {
        const nextItem: LocalEventItem = this.sortedLocalItems[index + 1];

        return nextItem
            ? nextItem.type === LocalEventItemType.Collapse
            : index === this.sortedLocalItems.length - 1;
    }

    /**
     *
     *  Invoked every time an item got moved/dragged and makes
     *  sure to correctly move items around in the front-end.
     *
     *  (i) Can be a collapse, ticket or collapse ticket
     *
     */
    private itemDragged(
        draggedItem: LocalEventItem,
        newPosition: NewDraggablePosition,
        type: LocalEventItemType,
    ): void {
        if (this.itemMovedDelay) {
            this.itemMovedDelay = clearTimeout(this.itemMovedDelay);
        }

        /**
         *  Directly move collapses around and give
         *  tickets some time to hover above their
         *  designated spot before acting.
         */
        const delay: number = type === LocalEventItemType.Collapse ? 0 : 225;

        /**
         *  Execute the logic with the above determined delay.
         */
        this.itemMovedDelay = window.setTimeout(() => {
            let onItem = false;

            /**
             *  Check every local item to see if the dragged
             *  item is dragged upon that item.
             */
            this.localItems.every((targetItem: LocalEventItem) => {
                /**
                 *
                 *  Do not check itself and make sure there
                 *  is a (Vue) ref to get the DOM element.
                 *
                 *  (i) The ref should have been set in
                 *  every local item's component mount()
                 *
                 */
                if (targetItem.guid === draggedItem.guid || !targetItem.ref) {
                    return true;
                }

                /**
                 *  Get the client rect and compute it's middle to
                 *  determine if the dragged item is dragged upon
                 *  this local item
                 */
                const targetItemRect: DOMRect = targetItem.ref.$el.getBoundingClientRect();
                const targetItemMiddle: number = targetItemRect.top + targetItemRect.height / 2;

                /**
                 *  The dragged item can be a collapse, ticket or collapse ticket.
                 */
                switch (type) {
                    // Dragged collapse
                    case LocalEventItemType.Collapse:
                        if (
                            draggedItem.index.current < targetItem.index.current
                        ) {
                            onItem = newPosition.bottom > targetItemMiddle
                                && newPosition.bottom < targetItemRect.bottom;
                        } else {
                            onItem = newPosition.top > targetItemRect.top
                                && newPosition.top < targetItemMiddle;
                        }

                        /**
                         *  Directly swap top level items when a collapse is
                         *  being dragged (hence the 0 delay for collapses)
                         */
                        if (onItem) {
                            const draggedItemIndex: number = draggedItem.index.current;
                            const targetItemIndex: number = targetItem.index.current;

                            draggedItem.index.current = targetItemIndex;
                            targetItem.index.current = draggedItemIndex;
                        }

                        break;

                    // Dragged (collapse) ticket
                    case LocalEventItemType.Ticket:
                    case LocalEventItemType.CollapseTicket: {
                        const ticket: LocalEventTicket = draggedItem as LocalEventTicket;

                        onItem = newPosition.middle > targetItemRect.top
                            && newPosition.middle < targetItemRect.bottom;

                        if (onItem) {
                            let newIndex!: number;
                            let currentIndex!: number | null;

                            /**
                             *  The following vars are used if a ticket got dragged on a
                             *  collapse; it saves the collapse it got dragged on and what
                             *  kind of collapse it is for the ticket - his current or his
                             *  new one - to forward to the method that moves a ticket
                             *  around (moveTicket).
                             */
                            let collapse!: LocalEventCollapse;
                            let target!: 'current' | 'new';

                            /**
                             *  A ticket can be dragged on a collapse or
                             *  ticket (so not on a collapse ticket).
                             */
                            switch (targetItem.type) {
                                // Ticket got dragged on collapse
                                case LocalEventItemType.Collapse: {
                                    /**
                                     *
                                     *  Remove the ticket out of the (first level) local items list
                                     *  if it's added there because it got temporarily dragged
                                     *  out of a collapse.
                                     *
                                     *  (i) It's in a collapse originally if a current guid has been set
                                     *
                                     */
                                    if (
                                        (ticket.collapse.current.item && ticket.collapse.current.item.guid)
                                        && this.localItems.indexOf(ticket) > -1
                                    ) {
                                        this.removeTicket(ticket);
                                    }

                                    collapse = targetItem as LocalEventCollapse;

                                    /**
                                     *  Ensure the collapse includes a ref, because it's
                                     *  needed to access the tickets of the collapse.
                                     */
                                    if (!collapse.ref) {
                                        throw Error(`Event.vue → Collapse doesn't have ref: ${collapse}`);
                                    }

                                    /**
                                     *  It's about his current collapse by default, until it
                                     *  seems it's about his new one (below).
                                     */
                                    target = 'current';

                                    /**
                                     *  A ticket is not uncollapsed when it
                                     *  got dragged on a collapse.
                                     */
                                    ticket.collapse.uncollapsed = false;

                                    /**
                                     *  It's not about his current collapse, so
                                     *  update his stats for this collapse.
                                     */
                                    const currentCollapseGuid = ticket.collapse.current.item
                                        ? ticket.collapse.current.item.guid
                                        : null;

                                    if (currentCollapseGuid !== collapse.guid) {
                                        target = 'new';

                                        /**
                                         *  Only add when not already added.
                                         */
                                        const newCollapseGuid = ticket.collapse.new.item ? ticket.collapse.new.item.guid : null;

                                        if (newCollapseGuid !== collapse.guid) {
                                            // Assign the guid of this collapse to his `new` stats
                                            ticket.collapse.new.item = collapse;

                                            // The index is added by the moveTicket() method below
                                            collapse.tickets.push(ticket);
                                        }
                                    }

                                    /**
                                     *  Get the tickets of the collapse to compute the
                                     *  height per ticket and to make sure the computed
                                     *  index doesn't get out of bound.
                                     */
                                    const collapseTickets: Element = collapse.ref.$refs.tickets as Element;
                                    const collapseTicketsRect: DOMRect = collapseTickets.getBoundingClientRect();
                                    const relativePosition: number = newPosition.middle - collapseTicketsRect.top;

                                    /**
                                     *  Compute the height per ticket based on the
                                     *  current tickets in the collapse.
                                     */
                                    const numberOfTickets: number = collapse.tickets.length;
                                    const heightPerTicket: number = collapseTicketsRect.height / numberOfTickets;

                                    /**
                                     *  Compute his index based on the relative position and
                                     *  the height per ticket and save his current index
                                     *  to forward to the moveTicket() method.
                                     */
                                    newIndex = Math.floor(
                                        relativePosition / heightPerTicket,
                                    );

                                    currentIndex = ticket.collapse[target].index;

                                    /**
                                     *  Because the index is computed above, we need to
                                     *  make sure it doesn't get out of bound.
                                     */
                                    if (newIndex < 0) {
                                        newIndex = 0;
                                    } else if (newIndex > numberOfTickets - 1) {
                                        newIndex = numberOfTickets - 1;
                                    }

                                    break;
                                }

                                /**
                                 *  Ticket got dragged on (top level) ticket.
                                 */
                                case LocalEventItemType.Ticket: {
                                    /**
                                     *  Add it to the first level items if not already added.
                                     */
                                    if (this.localItems.indexOf(ticket) === -1) {
                                        // Let's the update method know it got moved top level
                                        ticket.collapse.new.item = null;
                                        ticket.collapse.uncollapsed = true;

                                        // The index is added by the moveTicket() method below
                                        this.localItems.push(ticket);
                                    }

                                    newIndex = targetItem.index.current;
                                    currentIndex = draggedItem.index.current;

                                    break;
                                }

                                default:

                                    throw new Error(`Event.vue → itemDragged: unknown target item type "${targetItem.type}"`);
                            }

                            // Only move the ticket if he's not already at the computed index
                            if (newIndex !== currentIndex) {
                                this.moveTicket(
                                    ticket,
                                    newIndex,
                                    target,
                                    collapse,
                                );
                            }

                            /**
                         *  Remove the ticket (placeholder) of a ticket that is being dragged from
                         *  a collapse on which he is not dragged, because it might be added (as
                         *  placeholder) if a user did drag this ticket upon this collapse before.
                         */
                        } else if (
                            draggedItem.type === 'ticket'
                            && targetItem.type === 'collapse'
                        ) {
                            const collapse: LocalEventCollapse = targetItem as LocalEventCollapse;
                            const currentCollapseGuid = ticket.collapse.current.item ? ticket.collapse.current.item.guid : null;

                            if (
                                currentCollapseGuid !== collapse.guid
                                && collapse.tickets.indexOf(ticket) > -1
                            ) {
                                this.removeTicket(ticket, collapse);
                            }
                        }

                        break;
                    }

                    default:

                        throw new Error(`Event.vue → itemDragged: unknown type "${type}"`);
                }

                /**
                 *  Stop iterating once the item on which
                 *  the item is dragged has been found.
                 */
                return !onItem;
            });

            /**
             *  A ticket got dragged on an empty space; which can be
             *  either at the top of the event, at the bottom of the
             *  event or between two collapses.
             */
            if (!onItem && type !== LocalEventItemType.Collapse) {
                const ticket: LocalEventTicket = draggedItem as LocalEventTicket;

                /**
                 *  Find the item which he's after by going through the items from
                 *  bottom to top; he's after the item once his middle or bottom position
                 *  is below the bottom of the item that is being checked.
                 */
                let after!: LocalEventItem;

                this.sortedLocalItems
                    .slice()
                    .reverse()
                    .every((localItem: LocalEventItem) => {
                        if (!localItem.ref) {
                            return;
                        }

                        const localItemRect: DOMRect = localItem.ref.$el.getBoundingClientRect();

                        if (
                            newPosition.middle > localItemRect.bottom
                            || newPosition.bottom > localItemRect.bottom
                        ) {
                            after = localItem;
                        }

                        return !after;
                    });

                /**
                 *  Make sure to move it to index zero
                 *  if it's not after an item
                 */
                const index = !after ? 0 : after.index.current + 1;

                /**
                 *  Uncollape the ticket if it's not added to
                 *  the first level items already, otherwise make
                 *  sure to move it to the right index.
                 */
                if (this.localItems.indexOf(ticket) === -1) {
                    this.uncollapseTicket(ticket, index);
                } else if (index !== ticket.index.current) {
                    this.moveTicket(ticket, index);
                }
            }

            if (this.itemMovedDelay) {
                this.itemMovedDelay = clearTimeout(this.itemMovedDelay);
            }
        }, delay);
    }

    /**
     *
     *  Used to uncollapse a ticket; meaning he's getting
     *  moved from a collapse to top level.
     *
     *  @param ticket The ticket to uncollapse
     *  @param index The index where the ticket should be placed
     *
     */
    private uncollapseTicket(ticket: LocalEventTicket, index: number): void {
        /**
         *  Let's the update method know it got moved top level.
         */
        ticket.collapse.new.item = null;
        ticket.collapse.uncollapsed = true;

        /**
         *  His old index, which is equal to the index
         *  of the collapse he's coming from.
         */
        const oldIndex: number = ticket.index.current;

        /**
         *  Add the item to the first level local items.
         */
        this.localItems.push(ticket);

        /**
         *  Move every other local item out of the way.
         */
        this.sortedLocalItems.forEach((localItem: LocalEventItem) => {
            if (localItem.guid === ticket.guid) {
                return;
            }

            const itemIndex: number = localItem.index.current;

            /**
             *  If the index of the item to check is equal to the index
             *  at which the ticket should be placed, the direction is
             *  determined based on the ticket's old index (which is
             *  equal to the collapse he's coming from). Otherwise it's
             *  determined based on the fact if the ticket is placed
             *  above or below the item that is being checked.
             */
            let direction: 'up' | 'down' | null = null;

            if (index === itemIndex) {
                direction = index < oldIndex
                    ? 'up'
                    : 'down';
            } else {
                direction = index >= itemIndex
                    ? 'up'
                    : 'down';
            }

            /**
             *  Move the local item that is being checked
             *  accordingly to a new index in the list.
             */
            switch (direction) {
                case 'up':

                    if (localItem.index.current > 0) {
                        localItem.index.current--;
                    }

                    break;

                case 'down':

                    if (localItem.index.current < this.sortedLocalItems.length - 1) {
                        localItem.index.current++;
                    }

                    break;

                default:

                    throw new Error(`Event.vue → uncollapseTicket: invalid direction provided "${direction}"`);
            }
        });

        // Assign the ticket the forwarded index
        ticket.index.current = index;
    }

    /**
     *
     *  Used for moving a ticket to a specific index in a
     *  specific local collapse or in first level while the
     *  user is dragging it.
     *
     *  (i) Every ticket holds his own index for both the
     *  collapses he's in as the index for first level - the
     *  latter in the default index object.
     *
     */
    private moveTicket(
        ticket: LocalEventTicket,
        newIndex: number,
        target?: 'current' | 'new',
        collapse?: LocalEventCollapse,
    ): void {
        /**
         *  Holding every logic for moving a ticket around;
         *  either in top level or in a collapse.
         */
        const move = {

            // Ticket got moved on top level
            topLevel: (localItemIndex: LocalEventItemIndex, oldIndex: number) => {
                const currentIndex: number = localItemIndex.current;

                /**
                 *  Update all the indexes of the items that are between
                 *  the position that the dragged ticket is coming from
                 *  and where it is going to accordingly.
                 */
                switch (newIndex > oldIndex ? 'down' : 'up') {
                    case 'down':

                        if (currentIndex > oldIndex && currentIndex <= newIndex) {
                            localItemIndex.current--;
                        }

                        break;

                    case 'up':

                        if (currentIndex >= newIndex && currentIndex < oldIndex) {
                            localItemIndex.current++;
                        }

                        break;

                    default:

                        throw new Error('Event.vue → moveTicket → move.topLevel: invalid direction');
                }
            },

            // Ticket got moved in collapse
            inCollapse: (ticketCurrentIndex: { index: number | null }, oldIndex: number | null) => {
                /**
                 *  Every ticket should be moved to a lower position (higher index) if the
                 *  new ticket is moved in from above (new index equals null if so).
                 *  Otherwise it will just be added to the bottom - getting the highest
                 *  index by default because of the logic in the itemDragged.
                 */
                if (oldIndex === null) {
                    if (newIndex === 0 && ticketCurrentIndex.index !== null) {
                        ticketCurrentIndex.index++;
                    }

                    return;
                }

                /**
                 *  It is always about updating the current indexes of
                 *  the tickets, because items are painted based on a
                 *  sorted list based on their current index.
                 */
                const collapseIndex: number | null = ticketCurrentIndex.index;

                if (collapseIndex === null || Number.isNaN(collapseIndex)) {
                    return;
                }

                /**
                 *  Update all the indexes of the tickets that are
                 *  between the position that the dragged ticket is
                 *  coming from and where it is going to accordingly.
                 */
                switch (newIndex > oldIndex ? 'down' : 'up') {
                    case 'down':

                        if (collapseIndex > oldIndex && collapseIndex <= newIndex && ticketCurrentIndex.index !== null) {
                            ticketCurrentIndex.index--;
                        }

                        break;

                    case 'up':

                        if (collapseIndex >= newIndex && collapseIndex < oldIndex && ticketCurrentIndex.index !== null) {
                            ticketCurrentIndex.index++;
                        }

                        break;

                    default:

                        throw new Error('Event.vue → moveTicket → move.inCollapse: invalid direction');
                }
            },
        };

        /**
         *  Move the ticket around in top level if collapse
         *  (of the ticket) is not forwarded.
         */
        if (!collapse) {
            const oldIndex: number = ticket.index.current;

            this.localItems.forEach((localItem: LocalEventItem) => {
                if (localItem.guid === ticket.guid) {
                    return;
                }

                move.topLevel(localItem.index, oldIndex);
            });

            ticket.index.current = newIndex;

            return;
        }

        /**
         *
         *  If there is a collapse forwarded, the target of
         *  the movement should also have been forwarded.
         *
         *  The target is in which collapse of the ticket
         *  we're moving him around: his current or his new
         *  one, as a ticket can be in two collapses at the
         *  same time while dragging: his current one
         *  and his new one.
         *
         */
        if (target) {
            const oldIndex: number | null = ticket.collapse[target].index;

            collapse.tickets.forEach((collapseTicket: LocalEventTicket) => {
                if (
                    collapseTicket.guid === ticket.guid
                    || collapseTicket.collapse.current.index === null
                ) {
                    return;
                }

                move.inCollapse(collapseTicket.collapse.current, oldIndex);
            });

            /**
             *  Update the index of the item's target after
             *  every other index has been updated.
             */
            ticket.collapse[target].index = newIndex;
        }
    }

    /**
     *
     *  Used to remove a ticket out of either the top level items
     *  or a local collapse, making sure the index sequence that
     *  stays behind is correct.
     *
     *  (i) This fn does not actually save the removal of the ticket
     *
     */
    private removeTicket(
        ticket: LocalEventTicket,
        collapse?: LocalEventCollapse,
    ): void {
        const ticketIndex: number = (collapse ? collapse.tickets : this.localItems).indexOf(ticket);

        if (ticketIndex === -1) {
            return;
        }

        if (!collapse) {
            /**
             *  Close the gap in the indexes that the ticket
             *  that gets removed leaves to make sure the
             *  index sequence stays correct.
             */
            this.localItems.forEach((localItem: LocalEventItem) => {
                if (localItem.index.current > ticket.index.current) {
                    localItem.index.current--;
                }
            });

            this.localItems.splice(ticketIndex, 1);
        } else {
            // Index of the ticket in the collapse, as hold by the ticket itself
            let index: number | null = null;

            if (
                ticket.collapse.current.item
                && ticket.collapse.current.item.guid === collapse.guid
            ) {
                index = ticket.collapse.current.index;
            }

            if (
                ticket.collapse.new.item
                && ticket.collapse.new.item.guid === collapse.guid
            ) {
                index = ticket.collapse.new.index;
            }

            if (index === null) {
                return;
            }

            /**
             *  Reset the new collapse values to null
             *  to keep it working as expected.
             */
            ticket.collapse.new.item = null;
            ticket.collapse.new.index = null;

            /**
             *  Unnecessary to update the indexes if
             *  the last ticket is removed.
             */
            if (index === collapse.tickets.length - 1) {
                collapse.tickets.splice(ticketIndex, 1);

                return;
            }

            /**
             *  Close the gap in the indexes that the ticket that
             *  gets removed leaves to make sure the index
             *  sequence stays correct.
             */
            collapse.tickets.forEach((collapseTicket: LocalEventTicket) => {
                if (collapseTicket === ticket || index === null) {
                    return;
                }

                if (
                    collapseTicket.collapse.current.index !== null
                    && collapseTicket.collapse.current.index > index
                ) {
                    collapseTicket.collapse.current.index--;
                }
            });

            collapse.tickets.splice(ticketIndex, 1);
        }
    }

    /**
     *  Used to emit an update event for
     *  removing the selected tickets.
     */
    private removeSelectedTickets(): void {
        /**
         *
         *  Update the _remove state that is actually
         *  used to remove an item if true.
         *
         *  (i) The ticket's `remove` state is attached
         *  to his checkbox and only used to determine
         *  if it should be removed.
         *
         */
        for (const ticket of this.selectedTickets) {
            ticket._remove = ticket.remove;
        }

        /**
         *  Construct and emit the update.
         */
        const update: RemoveTicketsUpdate = {
            tickets: this.selectedTickets,
            includesLastTicket: this.selectedTickets.length === this.tickets.length,
            done: this.itemsUpdated,
        };

        this.$emit('tickets-remove', update);
    }

    /**
     *  Invoked when an item got dropped: either a collapse, ticket or
     *  collapse ticket. Makes sure to emit a correct update - including
     *  the right update data - to the parent if needed.
     */
    private itemUpdate(
        item: LocalEventItem,
        type: LocalEventItemType,
        remove?: boolean,
    ): void {
        if (this.itemMovedDelay) {
            this.itemMovedDelay = clearTimeout(this.itemMovedDelay);
        }

        /**
         *  Default item update.
         */
        const update: Update = {
            type,
            guid: item.guid,
            item: item.item,
            sort: {
                tickets: [],
                level: LocalEventItemLevel.First,
                neighbors: {
                    before: null,
                    after: null,
                },
            },
            done: this.itemsUpdated,
        };

        switch (type) {
            /**
             *  Collapse got dropped.
             */
            case LocalEventItemType.Collapse: {
                const collapse: LocalEventCollapse = item as LocalEventCollapse;

                update.sort.tickets = collapse.tickets;

                /**
                 *  Directly emit a removal update.
                 */
                if (remove) {
                    return this.emitUpdate(type, item, update, remove);
                }

                /**
                 *  Collapse got moved.
                 */
                if (collapse.index.current !== collapse.index.original) {
                    this.emitUpdate(type, item, update, remove);
                }

                break;
            }

            /**
             *  Ticket got dropped.
             */
            case LocalEventItemType.Ticket:
            case LocalEventItemType.CollapseTicket: {
                const ticket: LocalEventTicket = item as LocalEventTicket;

                /**
                 *  Add himself as only ticket to the ticket's
                 *  Array, because the parent always uses the
                 *  first ticket in the Array to sort.
                 */
                update.sort.tickets = [ ticket ];

                /**
                 *  Collapse ticket got removed out of collapse.
                 */
                if (ticket.collapse.uncollapsed) {
                    (update as TicketUpdate).collapse = {
                        added: null,
                        removed: ticket.collapse.current.item,
                        uncollapsed: true,
                    };

                    return this.emitUpdate(type, item, update, remove);
                }

                /**
                 *  Ticket got moved (on first level).
                 */
                if (
                    type === LocalEventItemType.Ticket
                    && ticket.index.current !== ticket.index.original
                ) {
                    return this.emitUpdate(type, item, update, remove);
                }

                /**
                 *  The following updates are about moving a ticket
                 *  on the second level: within a collapse.
                 */
                update.sort.level = LocalEventItemLevel.Second;

                /**
                 *  Collapse ticket got moved to another collapse
                 *  if he has a new collapse guid which is not
                 *  equal to his current collapse guid.
                 */
                const newCollapseGuid = ticket.collapse.new.item ? ticket.collapse.new.item.guid : null;
                const currentCollapseGuid = ticket.collapse.current.item ? ticket.collapse.current.item.guid : null;

                if (newCollapseGuid !== null && newCollapseGuid !== currentCollapseGuid) {
                    (update as TicketUpdate).collapse = {
                        added: ticket.collapse.new.item,
                        removed: ticket.collapse.current.item,
                        uncollapsed: false,
                    };

                    return this.emitUpdate(type, item, update, remove);
                }

                /**
                 *  Collapse ticket got moved within his current
                 *  collapse if his current index isn't equal (anymore)
                 *  to his original index.
                 */
                if (ticket.collapse.current.index !== ticket.collapse.current.original_index) {
                    this.emitUpdate(type, item, update, remove);
                }

                break;
            }

            default:

                throw new Error(`Event.vue → itemUpdate: invalid type provided "${type}"`);
        }
    }

    /**
     *  Used to emit the right update, including the adding of
     *  his neighbors if it's not about a removal.
     */
    private emitUpdate(
        type: LocalEventItemType,
        item: LocalEventItem,
        update: Update,
        remove?: boolean,
    ): void {
        /**
         *  Emit the right event based on the type
         *  of item to emit the event for.
         */
        const event: UpdateEvent = type === LocalEventItemType.Collapse
            ? UpdateEvent.CollapseEvent
            : UpdateEvent.TicketEvent;

        /**
         *  Find and add his neighbors if it's not about a removal.
         */
        if (!remove) {
            update.sort.neighbors = this.getItemNeighbors(update);
        } else {
            /**
             *  Lets the parent know it's about a removal.
             */
            update.remove = true;

            /**
             *  Makes sure the item is removed from the
             *  local items  when the parent invokes
             *  the update.done() callback
             */
            item._remove = true;
        }

        /**
         *  Disables the user from moving items around
         *  while the parent is handling an update.
         */
        this.loading = true;

        this.$emit(event, update);
    }

    /**
     *  Used to find the neighbors (before and after)
     *  of an item for the forwarded update.
     */
    private getItemNeighbors(update: Update): LocalEventItemNeighbors {
        let before: LocalEventItem | null = null;
        let after: LocalEventItem | null = null;

        /**
         *  Determine the items to find the neighbors in: only
         *  first level items in case of a collapse, making
         *  sure his own tickets are ignored.
         */
        const items: LocalEventItem[] = update.type === LocalEventItemType.Collapse
            ? this.localItems
            : this.tickets;

        /**
         *  Used to find the neighbors of the item at the
         *  forwarded index in the determined items.
         */
        const findNeighbors = (index: number): boolean | void => {
            before = items[index + 1];
            after = items[index - 1];

            /**
             *  Get the first or last ticket of a
             *  collapse, in case the item got moved
             *  before or after a collapse.
             */
            if (before && before.type === LocalEventItemType.Collapse) {
                [ before ] = (before as LocalEventCollapse).tickets;
            }

            if (after && after.type === LocalEventItemType.Collapse) {
                after = (after as LocalEventCollapse).tickets[(after as LocalEventCollapse).tickets.length - 1];
            }

            return !(before || after);
        };

        /**
         *  Find the neighbors of the item.
         */
        items.every((localItem: LocalEventItem, index: number) => (update.item.guid === localItem.guid
            ? findNeighbors(index)
            : true));

        return { before, after };
    }

    /**
     *
     *  Used to make local changes final, or reverting them to
     *  their original in case of an error.
     *
     *  (i) Forwarded with an update and to be invoked by the parent
     *
     *  @param discardUpdate Forwarding true discards
     *  the update and reverts any pending changes.
     *
     */
    private itemsUpdated(discardUpdate = false): void {
        /**
         *
         *  Holding every ticket that was in a collapse that got removed,
         *  so we can add them to the items at the end of the method
         *
         *  (i) Adding them in the filter method creates weird behaviour
         *
         */
        const uncollapsedTickets: LocalEventTicket[] = [];

        /**
         *  Object holding the needed update methods.
         */
        const update = {

            /**
             *  Used to make an item's index final on success, or
             *  revert it to it's original in case of an error.
             */
            item(localItem: LocalEventItem): void {
                if (discardUpdate) {
                    localItem.index.current = localItem.index.original;
                } else {
                    localItem.index.original = localItem.index.current;
                }
            },

            /**
             *  Used to make a ticket's indexes (top level and
             *  collapse level) final on success, or revert it
             *  to their originals' in case of an error.
             */
            ticket(ticket: LocalEventTicket): void {
                if (discardUpdate) {
                    ticket._remove = false;

                    ticket.collapse.current.index = ticket.collapse.current.original_index;
                } else if (ticket.collapse.uncollapsed) {
                    ticket.collapse.current.item = null;
                    ticket.collapse.current.index = null;
                    ticket.collapse.current.original_index = null;
                    ticket.collapse.uncollapsed = false;

                    /**
                 *  Make his new collapse his current collapse
                 *  if it got moved to another collapse.
                 */
                } else {
                    ticket.collapse.current.item = ticket.collapse.new.item || ticket.collapse.current.item;

                    const index: number | null = typeof ticket.collapse.new.index === 'number'
                        ? ticket.collapse.new.index
                        : ticket.collapse.current.index;

                    ticket.collapse.current.index = index;
                    ticket.collapse.current.original_index = index;
                }

                /**
                 *  Always reset the data of his new collapse,
                 *  as it can not be in a `new` collapse just
                 *  after an update finished.
                 */
                ticket.collapse.new.item = null;
                ticket.collapse.new.index = null;
            },

            /**
             *  Used to make sure a ticket that was in a collapse
             *  that got removed gets the right indexes and gets
             *  added to the (top level) items at the end.
             */
            ticketFromRemovedCollapse(
                ticket: LocalEventTicket,
                ticketIndex: number,
                collapseIndex: number,
            ): void {
                /**
                 *  Makes sure they are added starting from the
                 *  index of the collapse that got removed.
                 */
                const newIndex: number = collapseIndex * (ticketIndex + 1);

                ticket.index.current = newIndex;
                ticket.index.original = newIndex;

                ticket.collapse.current.item = null;
                ticket.collapse.current.index = null;
                ticket.collapse.current.original_index = null;

                uncollapsedTickets.push(ticket);
            },

        };

        /**
         *  Indexes should be updated if an item
         *  gets removed on first level.
         */
        let updateFirstLevelIndexes = false;

        /**
         *  Update every local item and directly remove them if needed.
         */
        this.localItems = this.localItems.filter((localItem: LocalEventItem) => {
            let removeFromItems = false;

            update.item(localItem);

            switch (localItem.type) {
                case 'collapse': {
                    const collapse: LocalEventCollapse = localItem as LocalEventCollapse;

                    if (collapse._remove) {
                        /**
                         *  Reset the removal prop of the collapse if it was
                         *  about to be removed, but it got discarded.
                         */
                        if (discardUpdate) {
                            collapse._remove = false;

                            /**
                         *  The indexes of the top level items should
                         *  only be updated if the collapse doesn't
                         *  leave tickets behind in top level.
                         */
                        } else if (!collapse.tickets.length) {
                            updateFirstLevelIndexes = true;

                            removeFromItems = true;
                        } else {
                            collapse.tickets.forEach((collapseTicket: LocalEventTicket, ticketIndex: number) => {
                                update.ticketFromRemovedCollapse(
                                    collapseTicket,
                                    ticketIndex,
                                    collapse.index.current,
                                );

                                /**
                                 *  Make sure to paint every item on which
                                 *  the ticket would be place a position lower
                                 *  to keep the index sequence intact.
                                 */
                                this.sortedLocalItems.forEach((item: LocalEventItem, index: number) => {
                                    if (index > collapseTicket.index.current) {
                                        item.index.current++;
                                        item.index.original++;
                                    }
                                });
                            });

                            updateFirstLevelIndexes = true;

                            removeFromItems = true;
                        }
                    } else {
                        /**
                         *  If the indexes of the collapse's tickets
                         *  should be updated, which is needed when
                         *  tickets got removed.
                         */
                        let updateIndexes = false;

                        /**
                         *  Update every ticket of the collapse and
                         *  remove it if it should be removed.
                         */
                        collapse.tickets = collapse.tickets.filter((collapseTicket: LocalEventTicket, ticketIndex: number) => {
                            update.ticket(collapseTicket);

                            const currentCollapseGuid = collapseTicket.collapse.current.item
                                ? collapseTicket.collapse.current.item.guid
                                : null;

                            /**
                             *  The ticket is still in the collapse if it
                             *  shouldn't be removed, it is not uncollapsed
                             *  and when the ticket's current collapse guid
                             *  is still equal to this collapse's guid.
                             */
                            const inCollapse: boolean = !collapseTicket._remove
                                && !collapseTicket.collapse.uncollapsed
                                && collapse.guid === currentCollapseGuid;

                            if (!inCollapse) {
                                updateIndexes = ticketIndex !== collapse.tickets.length - 1;
                            } else {
                                /**
                                 *  Give the ticket the same top level index as his
                                 *  collapse, so the moving around works as expected
                                 *  when moving a ticket out of a collapse.
                                 */
                                collapseTicket.index = {
                                    ...collapse.index,
                                };
                            }

                            return inCollapse;
                        });

                        /**
                         *  Only update the collapse's ticket indexes
                         *  if a ticket got removed and if there are
                         *  tickets left.
                         */
                        if (updateIndexes && collapse.tickets.length) {
                            collapse.tickets.forEach((collapseTicket: LocalEventTicket, index: number) => {
                                collapseTicket.collapse.current.index = index;
                                collapseTicket.collapse.current.original_index = index;
                            });
                        }
                    }

                    break;
                }

                case 'ticket': {
                    const ticket: LocalEventTicket = localItem as LocalEventTicket;

                    /**
                     *  Either reset the remove state or let it
                     *  remove the ticket from the items if one
                     *  of his remove states is true.
                     */
                    if (ticket._remove) {
                        if (discardUpdate) {
                            ticket._remove = false;
                        } else {
                            removeFromItems = true;

                            updateFirstLevelIndexes = true;
                        }
                    } else {
                        update.ticket(ticket);

                        /**
                         *  Remove it out of the first level items if it got
                         *  moved into a collapse, which is true if the
                         *  ticket's current collapse guid has been set.
                         */
                        removeFromItems = ticket.collapse.current.item !== null;
                    }

                    break;
                }

                default:

                    throw new Error(`Event.vue → itemsUpdated → invalid local item type: "${localItem.type}"`);
            }

            return !removeFromItems;
        });

        /**
         *  Add every ticket that was in a collapse
         *  that got moved to the top level items.
         */
        if (uncollapsedTickets.length) {
            this.localItems.push(...uncollapsedTickets);
        }

        /**
         *  Make sure the index sequence is correct
         *  when an item got removed to keep the
         *  indexing works as expected.
         */
        if (updateFirstLevelIndexes && this.sortedLocalItems.length) {
            this.sortedLocalItems.forEach((localItem: LocalEventItem, index: number) => {
                localItem.index.current = index;
                localItem.index.original = index;
            });
        }

        /**
         *  Allows the user to move items around again.
         */
        this.loading = false;

        /**
         *  Uncomment to log all the indexes after an update.
         */
        // setTimeout(() => {

        //     this.localItems.forEach((localItem: LocalEventItem) => {

        //         let name!: string;
        //         let tickets!: LocalEventTicket[];

        //         switch (localItem.type) {

        //             case 'ticket':

        //                 name = (localItem as LocalEventTicket).item.name;

        //                 break;

        //             case 'collapse':

        //                 name = (localItem as LocalEventCollapse).item.title;
        //                 tickets = (localItem as LocalEventCollapse).tickets;

        //                 break;

        //         }

        //         console.warn(`[${ localItem.type.toUpperCase() }] ${ name } |`, localItem.index.current);

        //         if (localItem.type === 'collapse' && tickets.length) {

        //             tickets.forEach((collapseTicket: LocalEventTicket) => {

        //                 console.warn(
        //                     `>>> ${ collapseTicket.item.name } |`,
        //                     collapseTicket.collapse.current.index,
        //                 );

        //             });

        //         }

        //     });

        // });
    }

}
