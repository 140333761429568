
import Vue from 'vue';
import Component from 'vue-class-component';
import {
    Provide, InjectReactive, Watch, ProvideReactive, Inject,
} from 'vue-property-decorator';

// Management SDK
import {
    EventModel,
    ShopModel,
    IShopModelProps,
    CollapseModel,
    TicketModel,
} from '@openticket/lib-management';

// SDK helpers
import { ModelForm } from '@openticket/lib-sdk-helpers';

// Shop SDK
import { OpenTicketShop, Event, Collapse } from '@openticket/sdk-shop';

// Tickets logic
import { DialogController } from '@openticket/vue-dashboard-components';
import TicketsEvents from '../components/tickets/Events.vue';

// Tickets types
import {
    LocalEventItemLevel,
    LocalEventItemType,
    LocalEventTicket,
    Update,
    TicketUpdate,
    RemoveTicketsUpdate,
} from '../components/tickets/types';

export const DISCARD_UPDATE: true = true as const;

@Component({
    components: {
        TicketsEvents,
    },
})
export default class ShopTickets extends Vue {

    @InjectReactive('shop')
    private shop!: ShopModel;

    @InjectReactive('form')
    private form!: ModelForm<ShopModel, IShopModelProps>;

    @Inject('dialog')
    private dialog!: DialogController;

    // Providing the already loaded events to the collapse form,
    // so it doesn't have to get the event if already loaded
    @ProvideReactive('events')
    private events!: { [guid: string]: EventModel };

    // Used for working with the actual data of the (SDK) shop
    private ticketShop: OpenTicketShop = new OpenTicketShop();

    /**
     *  The shop SDK throws an error if the shop does not contain events/tickets,
     *  which is correct for the shop, but not for the dashboard. Setting this value
     *  to true when that error occurs lets the TicketsEvents render, which
     *  on his turn renders an EmptyMessage if there are no events.
     */
    private noEvents = false;

    /**
     *
     *  Used to get the correct heading, including
     *  the shop's amount of events and tickets.
     *
     *  @todo Translate
     *
     */
    private get heading(): string {
        const tickets = this.ticketShop.data.tickets.length === 1 ? 'ticket' : 'tickets';
        const events = this.ticketShop.data.events.length === 1 ? 'event' : 'events';

        return `${this.ticketShop.data.tickets.length} ${tickets}, ${this.ticketShop.data.events.length} ${events}`;
    }

    /**
     *  Make sure the shop that is injected has been initialized
     *  and includes a guid to initialize the ticketshop.
     */
    public async mounted(): Promise<void> {
        if (this.shop) {
            await this.initTicketShop(this.shop.guid);

            window.Shop = this.ticketShop;
        }
    }

    /**
     *  The shop is provided by the parent Shop.vue, so
     *  we can start initializing the ticketshop when
     *  his shop is initialized.
     */
    @Watch('shop.guid')
    private async initTicketShop(shopId?: string): Promise<void> {
        if (!shopId || (this.ticketShop && this.ticketShop.is_initialized)) {
            return;
        }

        try {
            await this.ticketShop.init({
                baseUrl: process.env.VUE_APP_SHOP_API_URL,
                guid: shopId,
            });
        } catch (e) {
            console.error(e);

            this.noEvents = true;
        }
    }

    /**
     *  Used to update the provided events map, based on the ticketshop's events.
     */
    @Watch('ticketShop.data.events')
    private updateProvidedEvents(): void {
        this.events = {};

        for (const event of this.ticketShop.data.events) {
            this.events[event.guid] = this.$management.events.new({ guid: event.guid });
        }
    }

    /**
     *  Used to create a new collapse, which automatically creates
     *  a new collapse if initialised without collapse guid.
     */
    private createCollapse(event: Event): void {
        void this.$router.push({
            name: 'shops.edit.tickets.collapse.new',
            params: {
                event_id: event.guid,
            },
        });
    }

    /**
     *  Used to open the details of a collapse for editing them.
     */
    private editCollapse(collapse: CollapseModel, event: Event): void {
        void this.$router.push({
            name: 'shops.edit.tickets.collapse',
            params: {
                collapse_id: collapse.guid,
                event_id: event.guid,
            },
        });
    }

    /**
     *
     *  Provided as callback to the CollapseForm for when a collapse is saved.
     *
     *  (i) We're updating the title in this way because the CollapseForm doesn't
     *  know to which event the collapse belongs and there's a management model
     *  created for the collapse within the tickets and groups logic, therefor
     *  losing any bindings to the collapses map.
     *
     *  @todo Reload shop data if new collapse is added, but: empty collapses aren't painted
     *
     */
    @Provide('collapseSaved')
    private collapseUpdated(collapse: CollapseModel): void {
        this.ticketShop.data.events.every((event: Event): boolean => {
            let eventCollapse: Collapse | false = false;

            for (const item of event.items) {
                if (
                    item.type === 'collapse'
                    && item.item.guid === collapse.guid
                ) {
                    eventCollapse = item.item as Collapse;

                    break;
                }
            }

            if (eventCollapse) {
                eventCollapse.title = collapse.props.title;
            }

            return !eventCollapse;
        });
    }

    /**
     *
     *  Used to save changes to a collapse, including a new position and removal.
     *
     *  @todo Translate
     *
     */
    private async updateCollapse(update: Update<CollapseModel>): Promise<void> {
        if (!update.remove) {
            return this._sort(update);
        }

        if (update.remove) {
            const title = 'Remove collapse from shop';
            const description = `Are you sure you want to remove the collapse "${update.item.props.title}" from this shop?`;
            const confirmed = await this.dialog.confirm({
                title,
                description,
                type: 'is-danger',
            });

            if (!confirmed) {
                return update.done(DISCARD_UPDATE);
            }

            try {
                // @todo: Remove when this component is done
                console.warn(`# Unlink collapse "${update.item.props.title}" from shop`);

                await this.shop.collapses.unlink(update.item);

                update.done();
            } catch (e) {
                throw this.fail(e as Error, 'Failed to remove the collapse from the shop', update.done);
            }
        }
    }

    /**
     *  Used to actually save the update of a ticket.
     */
    private async updateTicket(update: TicketUpdate): Promise<void> {
        /**
         *
         *  Link and/or unlink a ticket from a collapse when needed.
         *
         *  @todo Translate error user messages
         *
         */
        if (update.collapse) {
            if (update.collapse.removed) {
                try {
                    // @todo: Remove when this component is done
                    console.warn(`# Unlink ticket "${update.item.props.name}" from collapse "${update.collapse.removed.item.props.title}}"`);

                    await update.collapse.removed.item.tickets.unlink(update.item);
                } catch (e) {
                    throw this.fail(e as Error, 'Failed to remove the ticket from the collapse', update.done);
                }
            }

            if (update.collapse.added) {
                try {
                    // @todo: Remove when this component is done
                    console.warn(`# Link ticket "${update.item.props.name}" to collapse "${update.collapse.added.item.props.title}}"`);

                    await update.collapse.added.item.tickets.link(update.item);
                } catch (e) {
                    throw this.fail(e as Error, 'Failed to add the ticket to the collapse', update.done);
                }
            }
        }

        /**
         *  Always sort the ticket.
         */
        await this._sort(update);
    }

    /**
     *
     *  Used to remove tickets from the shop.
     *
     *  @todo Translate
     *
     */
    private async removeTickets(update: RemoveTicketsUpdate): Promise<void> {
        /**
         *
         *  Wrapper for the fail method, always showing the
         *  user the same notification - no matter the
         *  underlying error that occured.
         *
         *  @todo Translate
         *
         */
        const fail = (error: Error) => {
            this.fail(error, 'Failed to remove the tickets from the shop', update.done);
        };

        /**
         *
         *  Construct the messages for the confirm and
         *  let the user confirm the removal.
         *
         *  @todo Translate
         *
         */
        const title = 'Remove tickets from shop';
        const description = 'Are you sure you want to remove the selected tickets from this shop? \n\n This will also remove the last ticket of the event. A shop can\'t contain empty events, so this will also remove the event from the shop.';
        const confirmed = await this.dialog.confirm({
            title,
            description,
            type: 'is-danger',
        });

        if (!confirmed) {
            return update.done(DISCARD_UPDATE);
        }

        /**
         *  Collect every collapse's tickets to detach them with a multi request.
         */
        const collapses: { [collapseGuid: string]: LocalEventTicket[]; } = {};

        for (const ticket of update.tickets) {
            if (!ticket.collapse.current.item) {
                continue;
            }

            const collapseGuid: string = ticket.collapse.current.item.guid;

            collapses[collapseGuid] = collapses[collapseGuid] || [];

            collapses[collapseGuid].push(ticket);
        }

        if (Object.keys(collapses).length) {
            for (const [ guid, tickets ] of Object.entries(collapses)) {
                try {
                    // @todo: Remove when this component is done
                    console.warn(`# Unlink tickets from collapse "${guid}}":`, tickets);

                    await this.$management.collapses.new({ guid }).tickets.unlinkMulti(
                        this._getManagementTickets(tickets),
                    );
                } catch (e) {
                    fail(e as Error);
                }
            }
        }

        /**
         *  Unlink every ticket from the shop.
         */
        try {
            // @todo: Remove when this component is done
            console.warn('# Unlink tickets from shop', update.tickets);

            await this.shop.tickets.unlinkMulti(
                this._getManagementTickets(update.tickets),
            );

            if (update.includesLastTicket) {
                await this.refreshShop();

                return;
            }

            update.done();
        } catch (e) {
            fail(e as Error);
        }
    }

    /**
     *  Used to sort a collapse or a ticket, because a
     *  collapse is also sorted based on his tickets.
     */
    private async _sort(update: Update): Promise<void> {
        /**
         *
         *  Wrapper for the fail method, always showing the
         *  user the same notification - no matter the
         *  underlying error that occured.
         *
         *  @todo Translate
         *
         */
        const fail = (error: Error): never => {
            this.fail(error, 'Failed to save the new position', update.done);
        };

        /**
         *  Ensure everything is present in the update to sort.
         */
        if (
            !update.sort
            || !update.sort.tickets
            || (!update.sort.neighbors.before && !update.sort.neighbors.after)
        ) {
            fail(new Error('Tickets.vue → _sort: Missing required sorting data in update'));
        }

        /**
         *
         *  The first ticket should always be sorted.
         *
         *  (i) The update always includes an Array of
         *  tickets: the tickets of a collapse in case
         *  of a collapse, the ticket itself in case
         *  of a ticket
         *
         */
        const ticket: TicketModel = update.sort.tickets[0].item;

        /**
         *  Determine the order and anchor.
         */
        let order: 'before' | 'after' | null = null;
        let anchor: TicketModel | null = null;

        /**
         *
         *  Used to determine the order and anchor,
         *  based on the forwarded starting point
         *  for the determination.
         *
         *  @param startingPoint The point to start with for determining
         *
         *  (i) In some situations we have to use the `before` as
         *  a starting point and in some de `after`
         *
         */
        const determineOrderAndAnchor = (startingPoint: 'before' | 'after') => {
            switch (startingPoint) {
                case 'before':

                    if (update.sort.neighbors.before) {
                        anchor = update.sort.neighbors.before.item;

                        order = 'before';
                    } else if (update.sort.neighbors.after) {
                        anchor = update.sort.neighbors.after.item;

                        order = 'after';
                    }

                    break;

                case 'after':

                    if (update.sort.neighbors.after) {
                        anchor = update.sort.neighbors.after.item;

                        order = 'after';
                    } else if (update.sort.neighbors.before) {
                        anchor = update.sort.neighbors.before.item;

                        order = 'before';
                    }

                    break;

                default:

                    throw fail(new Error(`Tickets.vue → _sort: Invalid starting point provided: "${startingPoint}"`));
            }
        };

        /**
         *  Determine the order and anchor based
         *  on the level and type of the event
         *  item that is sorted.
         */
        switch (update.sort.level) {
            case LocalEventItemLevel.First:

                switch (update.type) {
                    case LocalEventItemType.Collapse:

                        determineOrderAndAnchor('after');

                        break;

                    case LocalEventItemType.Ticket:
                    case LocalEventItemType.CollapseTicket:

                        determineOrderAndAnchor('before');

                        break;

                    default:

                        throw fail(new Error(`Tickets.vue → _sort: Invalid local event item type for the first level provided: "${update.type}"`));
                }

                break;

            case LocalEventItemLevel.Second:

                determineOrderAndAnchor('after');

                break;

            default:

                fail(new Error(`Tickets.vue → _sort: Invalid local event item level: "${update.sort.level}"`));
        }

        /**
         *  Ensure everything is determined to make a sort request.
         */
        if (!ticket || !order || !anchor) {
            fail(new Error('Tickets.vue → _sort: Could not determine sorting because of missing data'));
        }

        /**
         *  Sort the ticket.
         */
        try {
            console.warn(`# Sort "${ticket.props.name}" → ${order} → "${anchor!.props.name}"`);

            await this.shop.tickets.sortModel(ticket, order!, anchor!);
        } catch (e) {
            throw fail(e as Error);
        }

        /**
         *  Also sort the remaining tickets in case of
         *  a collapse to ensure their indexes are/keep
         *  concurrent within the collapse.
         */
        if (update.type === LocalEventItemType.Collapse) {
            try {
                await this._sortRemainingCollapseTickets(update.sort.tickets);
            } catch (e) {
                throw fail(e as Error);
            }
        }

        update.done();
    }

    /**
     * Called when the 'Add tickets' nested picker is saved
     */
    private async pickerSaved() {
        this.$notifications.success('Saved');
        await this.refreshShop();
    }

    /**
     *  Used to refresh the data of the shop.
     */
    @Provide('refreshShop')
    private async refreshShop(): Promise<void> {
        await this.ticketShop.refreshData();
    }

    /**
     *
     *  Used to let it fail if an operation fails. Shows the
     *  forwarded user message (as notification), discards
     *  the changes and throws the forwarded error.
     *
     *  @param error The error that was thrown
     *  @param userMessage The message for the user
     *  @param done The done() callback method
     *
     */
    @Provide('fail')
    private fail(error: Error, userMessage: string, done?: (discardUpdate?: true) => void): never {
        this.$notifications.danger(userMessage);

        if (done) {
            done(DISCARD_UPDATE);
        }

        throw error;
    }

    /**
     *
     *  Used to sort the remaining tickets in a collapse,
     *  ensuring their indexes are/keep concurrent
     *  when a collapse is moved.
     *
     *  @param tickets All the tickets of the collapse
     *
     *  (i) We do not need the collapse itself, because
     *  tickets are always sorted on first level
     *
     *  (i) We always use the `after` sorting, because
     *  we know there's always a ticket before it due
     *  to the fact a collapse must contain at least
     *  one ticket
     *
     */
    private async _sortRemainingCollapseTickets(tickets: LocalEventTicket[]): Promise<void> {
        // @todo: Remove when this component is done
        console.groupCollapsed('# Sort remaining collapse tickets ↓');

        let n: number;

        const numberOfTickets: number = tickets.length;
        const order = 'after';

        for (n = 1; n < numberOfTickets; n++) {
            const ticket: TicketModel = tickets[n].item;
            const anchor: TicketModel = tickets[n - 1].item;

            // @todo: Remove when this component is done
            console.warn(`Sort "${ticket.props.name}" → ${order} → "${anchor.props.name}"`);

            await this.shop.tickets.sortModel(ticket, order, anchor);
        }

        // @todo: Remove when this component is done
        console.groupEnd();
    }

    /**
     *
     *  Used to get management tickets (/models) for the
     *  provided local event tickets.
     *
     *  (i) Needed because the management SDK only
     *  works with his tickets models.
     *
     *  @param tickets The local event tickets to get
     *  the management tickets of
     *
     *  @return The management ticket models for the
     *  provided local event tickets
     *
     */
    private _getManagementTickets(tickets: LocalEventTicket[]): TicketModel[] {
        return tickets.map((ticket: LocalEventTicket) => this.shop.tickets.new({ guid: ticket.guid }));
    }

}
