/* eslint-disable sort-keys */
// While object iteration is not guaranteed to be in order,
// if it can be, that's better in this case.
// Therefore, disabling sorted keys for the genres.

type Genres = { [key: string]: string[] };

/**
 * Hardcoded event categories data.
 */
const genres: Genres = {
    festival: [ '00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall' ],
    business: [ 'convention', 'conference', 'training', 'workshop', 'master-class', 'network', 'course', 'exposition', 'gala' ],
    fashion: [ 'gala', 'conference', 'training', 'workshop', 'master-class', 'network', 'exposition', 'sample-sale', 'catwalk-show' ],
    sports: [ 'gala', 'training', 'running', 'obstacle run', 'football', 'golf', 'rugby', 'baseball', 'basketball', 'table tennis', 'volleyball', 'field hockey', 'cricket', 'rally', 'horse back riding' ],
    theatre: [ 'musical', 'comedy', 'dance', 'ballet', 'opera', 'play', 'kids', 'dancing' ],
    family: [],
    adult: [],
    circus: [],
    'art-film': [ 'screening', 'convention', 'conference', 'training', 'workshop', 'master-class', 'network', 'course', 'exposition', 'gala', 'reading' ],
    'food-drinks': [],
    concert: [ '00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall' ],
    club: [ '00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall' ],
    charity: [ 'convention', 'conference', 'master-class', 'network', 'exposition', 'gala' ],
    hobby: [],
    museum: [],
    gaming: [ 'e-sports', 'conference', 'network' ],
    'escape-room': [],
};

export default genres;
