var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.shop)?_c('div',{key:_vm.shop.guid || 'new',staticClass:"shop-form"},[_c('OtForm',{attrs:{"form":_vm.form}},[(_vm.form)?_c('div',{staticClass:"shop-form__content"},[_c('div',{staticClass:"shop-form__preview__container"},[_c('div',{staticClass:"shop-form__form"},[(!_vm.settings.isStaticDefault)?_c('OtNotification',{attrs:{"type":"is-warning","icon":"oti-alert","title":_vm.$t('dashboard.shop.has_custom_settings')}}):_vm._e(),(_vm.shop.guid)?_c('OtFormInput',{attrs:{"label":_vm.$t('dashboard.shop.name.label'),"description":_vm.$t('dashboard.shop.name.description'),"type":"text","name":"name"}}):_vm._e(),(_vm.shop.guid)?_c('OtFormField',{ref:"metadata",attrs:{"label":_vm.$t('dashboard.shop.metadata.label'),"description":_vm.$t('dashboard.shop.metadata.description'),"relation":_vm.shop.metadata,"label-prop":"name"},on:{"input":_vm.checkLocalChanges,"success":function($event){_vm.$notifications.success(
                                    _vm.$t(
                                        'dashboard.common.notification.save.success'
                                    )
                                )},"error":(e) => _vm.$notifications.danger(e)}},[_c('OtCrudPicker',{ref:"metadata",attrs:{"relation":_vm.shop.metadata},on:{"input":_vm.checkLocalChanges,"success":function($event){return _vm.$notifications.success('Saved')},"error":(e) => _vm.$notifications.danger(e)},scopedSlots:_vm._u([{key:"item",fn:function(item){return [_vm._v(" "+_vm._s(item.props.name)+" ")]}},{key:"form",fn:function({ form, save, cancel }){return [_c('MetadataForm',{attrs:{"form":form,"save":save,"cancel":cancel}})]}}],null,false,2403143739)})],1):_vm._e(),(_vm.shop.guid)?_c('div',[_c('br'),_c('OtInputField',{attrs:{"label":_vm.$t(
                                        'dashboard.shop.custom_settings.title'
                                    ),"description":_vm.$t(
                                        'dashboard.shop.custom_settings.description'
                                    )}},[_c('button',{staticClass:"ot-button is-dark is-small",on:{"click":function($event){return _vm.$router.push({
                                            name: 'shops.edit.settings',
                                        })}}},[_c('i',{staticClass:"ot-button-icon--left oti oti-tools is-small"}),_vm._v(" "+_vm._s(_vm.$t( 'dashboard.shop.custom_settings.title' ))+" ")])]),_c('br'),(_vm.showAdmin)?_c('OtInputField',{attrs:{"label":_vm.$t(
                                        'dashboard.shop.admin_settings.title'
                                    ),"description":_vm.$t(
                                        'dashboard.shop.admin_settings.description'
                                    )}},[_c('button',{staticClass:"ot-button is-dark is-small",on:{"click":function($event){return _vm.$router.push({
                                            name: 'shops.edit.admin',
                                        })}}},[_c('i',{staticClass:"oti oti-lock ot-button-icon--left"}),_vm._v(" "+_vm._s(_vm.$t( 'dashboard.shop.admin_settings.title' ))+" ")])]):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"shop-form__preview"},[_c('LiveShopPreview',{ref:"preview",attrs:{"url":_vm.shop.url,"iframe-url":_vm.shop.url}})],1)]),(!_vm.shop.isNew)?_c('OtFormCollapse',{attrs:{"title":_vm.$t('dashboard.common.titles.additional_settings'),"subtitle":_vm.$t('dashboard.shop.additional_settings.subtitle'),"optional":true}},[_c('div',{staticClass:"shop-form__collapse"},[_c('OtFormInput',{attrs:{"label":_vm.$t(
                                    'dashboard.shop.additional_settings.facebook_page_url.label'
                                ),"description":_vm.$t(
                                    'dashboard.shop.additional_settings.facebook_page_url.description'
                                ),"placeholder":_vm.$t(
                                    'dashboard.shop.additional_settings.facebook_page_url.placeholder'
                                ),"type":"url","name":"facebook_page_url"}}),_c('OtFormInput',{attrs:{"label":_vm.$t(
                                    'dashboard.shop.additional_settings.company_terms.label'
                                ),"description":_vm.$t(
                                    'dashboard.shop.additional_settings.company_terms.description'
                                ),"placeholder":_vm.$t(
                                    'dashboard.shop.additional_settings.company_terms.placeholder'
                                ),"type":"url","name":"company_terms"}}),_c('OtFormInput',{attrs:{"label":_vm.$t(
                                    'dashboard.shop.additional_settings.google_tag.label'
                                ),"description":_vm.$t(
                                    'dashboard.shop.additional_settings.google_tag.description'
                                ),"placeholder":_vm.$t(
                                    'dashboard.shop.additional_settings.google_tag.placeholder'
                                ),"type":"text","name":"google_tag"}}),_c('OtFormInput',{attrs:{"label":_vm.$t(
                                    'dashboard.shop.additional_settings.event_selection.label'
                                ),"description":_vm.$t(
                                    'dashboard.shop.additional_settings.event_selection.description'
                                ),"full-width":true,"type":"selectbar","name":"event_selection","options":{
                                auto: _vm.$t(
                                    'dashboard.shop.additional_settings.event_selection.option.auto'
                                ),
                                enabled: _vm.$t(
                                    'dashboard.shop.additional_settings.event_selection.option.enabled'
                                ),
                                disabled: _vm.$t(
                                    'dashboard.shop.additional_settings.event_selection.option.disabled'
                                ),
                            }}}),(_vm.shop.guid)?_c('OtFormField',{attrs:{"label":_vm.$t(
                                    'dashboard.shop.additional_settings.paymentmethods.label'
                                ),"description":_vm.$t(
                                    'dashboard.shop.additional_settings.paymentmethods.description'
                                )}},[_c('OtCrudPicker',{ref:"paymentmethods",attrs:{"relation":_vm.shop.paymentmethods,"label-prop":"name"},on:{"input":_vm.checkLocalChanges,"success":function($event){_vm.$notifications.success(
                                        _vm.$t(
                                            'dashboard.common.notification.save.success'
                                        )
                                    )},"error":(e) => _vm.$notifications.danger(e)}})],1):_vm._e()],1)]):_vm._e()],1):_vm._e(),_c('OtCardFooter',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('button',{staticClass:"ot-button is-dark",attrs:{"title":_vm.$t('dashboard.common.action.back.title')},on:{"click":function($event){return _vm.$router.push({ name: 'shops' })}}},[_c('i',{staticClass:"ot-button-icon--left oti oti-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('dashboard.common.action.back.text'))+" ")])]},proxy:true},{key:"right",fn:function(){return [_c('button',{staticClass:"ot-button",attrs:{"disabled":!_vm.hasLocalChanges,"title":_vm.$t('dashboard.common.action.save.title')},on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"ot-button-icon--left oti oti-check"}),_vm._v(" "+_vm._s(_vm.$t('dashboard.common.action.save.text'))+" ")]),_c('button',{staticClass:"ot-button",attrs:{"disabled":!_vm.hasLocalChanges,"title":_vm.$t(
                                'dashboard.common.action.save_and_close.title'
                            )},on:{"click":function($event){return _vm.save(true)}}},[_c('i',{staticClass:"ot-button-icon--left oti oti-check"}),_vm._v(" "+_vm._s(_vm.$t( 'dashboard.common.action.save_and_close.text' ))+" ")])]},proxy:true}],null,false,2097944935)})],1),(_vm.settingsInitialized || _vm.shop.isNew)?_c('OtRouterModal'):_vm._e()],1):_c('OtSpinner')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }