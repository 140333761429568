
import type { IShopModelProps, ShopModel } from '@openticket/lib-management';
import type { ModelForm } from '@openticket/lib-sdk-helpers';
import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { IFormPicker } from '@openticket/vue-dashboard-components';
import Axios from 'axios';
import type { AxiosInstance } from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import {
    InjectReactive,
    ProvideReactive,
    Ref,
    Watch,
} from 'vue-property-decorator';
import type { Route } from 'vue-router';
import LiveShopPreview from '../components/LiveShopPreview.vue';
import MetadataForm from '../components/MetadataForm.vue';

@Component({
    components: {
        LiveShopPreview,
        MetadataForm,
    },
})
export default class ShopForm extends Vue {

    @InjectReactive('shop')
    private shop!: ShopModel;

    @InjectReactive('form')
    private form!: ModelForm<ShopModel, IShopModelProps>;

    @ProvideReactive('settings')
    private settings: CustomShopSettingsClient = new CustomShopSettingsClient();

    @Ref('preview')
    private preview!: LiveShopPreview;

    @Ref('metadata')
    private metadata!: IFormPicker;

    @Ref('paymentmethods')
    private paymentmethods!: IFormPicker;

    private hasLocalChanges = false;

    private settingsInitialized = false;

    private showAdmin = false;

    private get isOpenedFromOutside(): boolean {
        return this.$route.query.legacy !== undefined;
    }

    async created(): Promise<void> {
        void this.initShopSettings(this.shop ? this.shop.guid : undefined);

        if (await this.$auth.isAdmin) {
            this.showAdmin = true;
        }
    }

    @Watch('shop.guid')
    private async initShopSettings(shopId?: string): Promise<void> {
        if (!shopId) {
            return;
        }

        await this.settings.init({
            baseUrl: process.env.VUE_APP_SHOP_SETTINGS_API_URL,
            disableCache: true,
            httpRead: Axios.create(),
            httpWrite: this.$auth.$http as AxiosInstance,
            shopId,
        });

        if (this.$route.query.as && typeof this.$route.query.as === 'string') {
            this.settings.setCompanyScope(this.$route.query.as);
        }

        Vue.observable(this.settings.static);

        this.settingsInitialized = true;
    }

    private async save(close = false): Promise<void> {
        if (!this.shop || !this.form) {
            throw Error(`${!this.shop ? 'Shop' : 'Form'} not initialized`);
        }

        try {
            await this.form.submit();

            await Promise.all([
                this.metadata.save(),
                this.paymentmethods.save(),
            ]);

            this.hasLocalChanges = false;

            if (close) {
                await this.$router.push({ name: 'shops' });
            } else if (this.$route.name === 'shops.new') {
                await this.initShopSettings(this.shop.guid);

                await this.$router.replace({
                    name: 'shops.edit',
                    params: { shop_id: this.shop.guid },
                });
            }

            this.$notifications.success(
                this.$t('dashboard.common.notification.save.success'),
            );
        } catch (e) {
            this.$notifications.danger(
                this.$t('dashboard.common.notification.save.fail'),
            );

            throw e;
        }
    }

    private get integrationCode(): string | undefined {
        if (!this.shop || !this.shop.guid) {
            return;
        }
        return `
<div class="ot-iframe"
 data-ot-url="${this.shop.url}"
 data-ot-guid="${this.shop.guid}">
</div>
<script src="https://pos.eventix.io/injector.js" />`;
    }

    @Watch('form.props', { deep: true })
    private checkLocalChanges() {
        this.hasLocalChanges = this.form.hasLocalChanges()
            || (!!this.metadata && this.metadata.hasLocalChanges)
            || (!!this.paymentmethods && this.paymentmethods.hasLocalChanges);
    }

    @Watch('$route')
    private onCloseModal(newRoute: Route, oldRoute: Route) {
        if (!this.$route.meta
            || (
                !this.$route.meta.modal && (oldRoute.name === 'shops.edit.settings' || oldRoute.name === 'shops.edit.admin')
            )
        ) {
            this.preview.refresh();
        }
    }

}
