
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Ref } from 'vue-property-decorator';
import { ParentRoute, SidebarItem } from '@openticket/vue-dashboard-components';
import { EventModel } from '@openticket/lib-management';
import { RouteRecord } from 'vue-router';
import ProfileDropdown from './dropdowns/ProfileDropdown.vue';
import CompanySelectModal from './modals/CompanySelectModal.vue';
import DashboardInteraction, { DashboardInteractionController } from './Dashboard.vue';

interface SidebarItemMetadata {
    scope?: string;
}

type ExtendedSidebarItem = SidebarItem<
    SidebarItem<never> & SidebarItemMetadata
> &
    SidebarItemMetadata;

@Component({
    components: {
        ProfileDropdown,
        CompanySelectModal,
        DashboardInteraction,
    },
})
export default class Dashboard extends Vue {

    @Inject('dashboard')
        dashboard!: DashboardInteractionController;

    @Ref('layout')
        layout!: { showSidebar: () => void };

    private async back(): Promise<void> {
        const { parentRoute } = this;

        if (parentRoute) {
            await this.$router.push({ name: parentRoute.name });
        }
    }

    private get parentRoute(): ParentRoute | null {
        const prevRouteSpec: RouteRecord | undefined = this.$route.matched.slice().reverse()
            .find((record: RouteRecord) => !!record.meta.prevRoute);

        if (prevRouteSpec) {
            return prevRouteSpec.meta.prevRoute!;
        }

        return null;
    }

    private isSelected(item: ExtendedSidebarItem): boolean {
        let routeScope = '';

        for (const route of this.$route.matched) {
            if (route.meta && route.meta.scope) {
                routeScope = route.meta.scope;
                break;
            }
        }

        if (!routeScope || !item.scope) {
            return false;
        }

        const routeParts = routeScope.split('.');
        const itemParts = item.scope.split('.');

        for (let i = 0; i < itemParts.length; i++) {
            if (itemParts[i] !== routeParts[i]) {
                return false;
            }
        }

        return true;
    }

    private get sidebarItems(): SidebarItem[] {
        const items: ExtendedSidebarItem[] = [];

        if (this.$whitelabel.name !== 'Eventix') {
            // Company item
            items.push({
                label: this.$company.props.name,
                icon: 'oti-home',
                scope: 'company',
                route: {
                    name: 'company.settings',
                },
                children: [
                    {
                        label: 'Members',
                        icon: 'oti-login',
                        scope: 'company.members',
                        route: {
                            name: 'company.members',
                        },
                    },
                    {
                        label: 'Settings',
                        icon: 'oti-tools',
                        scope: 'company.settings',
                        route: {
                            name: 'company.settings',
                        },
                    },
                ],
            });

            const event: EventModel | null = this.dashboard.currentEvent;

            if (event) {
                if (event.isNew) {
                    items.push({
                        label: 'New event',
                        icon: 'oti-event',
                        scope: 'event',
                        route: {
                            name: 'events.new',
                        },
                    });
                } else {
                    items.push({
                        label: event.props.name,
                        icon: 'oti-event',
                        scope: 'event',
                        route: {
                            name: 'events.home',
                            params: {
                                eventGuid: event.guid,
                            },
                        },
                    });
                }
            } else {
                items.push({
                    label: 'New event',
                    icon: 'oti-event',
                    scope: 'event',
                    route: {
                        name: 'events.new',
                    },
                });
            }

            items.push({
                label: 'Events overview',
                icon: 'oti-event',
                scope: 'events',
                route: {
                    name: 'events',
                },
            });
        }

        // Shop item
        items.push({
            label: 'Shop',
            icon: 'oti-shops',
            scope: 'shops',
            route: {
                name: 'shops',
            },
        });

        return items;
    }

}
