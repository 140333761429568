
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive, Watch } from 'vue-property-decorator';

import { IShopModelProps, ShopModel } from '@openticket/lib-management';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import { DialogController, ModalController } from '@openticket/vue-dashboard-components';

@Component
export default class ShopAdminForm extends Vue {

    @InjectReactive('shop')
    private shop!: ShopModel;

    @Inject('modal')
    private modal!: ModalController;

    @InjectReactive('form')
    private form!: ModelForm<ShopModel, IShopModelProps>;

    @Inject('dialog')
        dialog!: DialogController;

    private hasLocalChanges = false;

    public created(): void {
        this.modal.registerBeforeClose(() => this.shouldClose());
    }

    private async shouldClose(): Promise<boolean> {
        if (
            this.hasLocalChanges
            && !(await this.dialog.confirm({
                title: 'Undo unsaved changes?',
                description: 'This action cannot be undone',
                type: 'is-danger',
            }))
        ) {
            return false;
        }

        this.form.undo('from_name');
        this.form.undo('from_email');
        this.form.undo('greedy_date_selection');
        this.form.undo('seats_allow_orphan');

        return true;
    }

    private async save(): Promise<void> {
        try {
            await this.form.submit();

            this.hasLocalChanges = false;

            void this.modal.close();

            this.$notifications.success(this.$t('dashboard.common.notification.save.success'));
        } catch (e) {
            this.$notifications.danger(this.$t('dashboard.common.notification.save.fail'));

            throw e;
        }
    }

    @Watch('form.props', { deep: true })
    private checkFormChanges() {
        this.hasLocalChanges = this.form.hasLocalChanges();
    }

}
