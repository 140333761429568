
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ProfileHeader extends Vue {

    @Prop() name!: string;

    @Prop() subtitle!: string;

}
