
import Vue from 'vue';
import Component from 'vue-class-component';
import Confetti from 'canvas-confetti';
import { Ref } from 'vue-property-decorator';
import { Style } from '@openticket/lib-utils';

export interface ConfettiController {
    fire: () => void;
}

@Component
export default class ConfettiWrapper extends Vue implements ConfettiController {

    @Ref('canvas')
        canvas!: HTMLCanvasElement;

    func!: Confetti.CreateTypes;

    public fire(): void {
        if (!this.func) {
            return;
        }

        void this.func({
            colors: this.colors,
            spread: 120,
            particleCount: 200,
        });
    }

    mounted(): void {
        this.func = Confetti.create(this.canvas, {
            resize: true,
            useWorker: true,
        });
    }

    get colors(): string[] {
        return [
            Style.getCustomProperty('ot-color-core-brand'),
        ];
    }

}
