
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EmptyMessage extends Vue {

    @Prop({ type: String, default: '😴' })
    private emoji!: string;

    @Prop({ type: String, required: true })
    private title!: string;

    @Prop({ type: String })
    private text!: string;

}
