
import { EventModel } from '@openticket/lib-management';
import { InlineModalController } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Ref } from 'vue-property-decorator';
import { ConfettiController } from '@/components/ConfettiWrapper.vue';

@Component
export default class EventHome extends Vue {

    @Ref('new_event_modal')
        newEventModal!: InlineModalController;

    @Ref('confetti')
        confetti!: ConfettiController;

    @InjectReactive('event')
        event!: EventModel;

    mounted(): void {
        if (this.$route.query.new) {
            this.newEventModal.open();

            void this.$router.replace({
                name: this.$route.name as string,
            });
        }
    }

    finishNew(): void {
        void this.newEventModal.close();
        this.confetti.fire();
    }

}
