import { RouteConfig } from 'vue-router';

import Create from './views/Create.vue';
import CreateEventForm from './views/create/EventForm.vue';
import CreateTicketList from './views/create/TicketList.vue';
import CreateTicketForm from './views/create/TicketForm.vue';

import EditEvent from './views/EditEvent.vue';
import EditTicket from './views/EditTicket.vue';

import Event from './views/Event.vue';
import List from './views/List.vue';

import EventHome from './views/event/Home.vue';
import EventTicketList from './views/event/TicketList.vue';

/**
 *  create (card header, tabs)
 *      event
 *          form header
 *          event form
 *          form footer
 *          card footer
 *      ticket
 *          form header
 *          ticket form
 *          form footer
 *          card footer
 *      ticket list
 *          ticket list
 *          card footer
 *  edit
 *      event
 *          card header
 *          event form
 *          card footer
 *      ticket
 *          card header
 *          ticket form
 *          card footer
 *  event (event header)
 *      home
 *          tabs
 *          statistics
 *      ticket list
 *          ticket promo
 *          ticket list
 */

const routes: RouteConfig[] = [
    {
        /**
         * Overview of events
         */
        path: 'events',
        name: 'events',
        component: List,
        meta: {
            scope: 'events',
        },
    },
    {
        /**
         * Event edit page, wraps the GenericEventForm
         */
        path: 'events/:eventGuid/edit',
        name: 'events.edit',
        component: EditEvent,
        meta: {
            scope: 'event',
            prevRoute: {
                title: 'Event overview',
                name: 'events.home',
            },
        },
    },
    {
        /**
         * Ticket edit page inside an existing event, wraps the GenericTicketForm
         */
        path: 'events/:eventGuid/tickets/:ticketGuid/edit',
        name: 'events.tickets.edit',
        component: EditTicket,
        meta: {
            scope: 'event',
            prevRoute: {
                title: 'Event overview',
                name: 'events.tickets',
            },
        },
    },
    {
        /**
         * New ticket page inside an existing event, wraps the GenericTicketForm
         */
        path: 'events/:eventGuid/tickets/new',
        name: 'events.tickets.new',
        component: EditTicket,
        meta: {
            scope: 'event',
            prevRoute: {
                title: 'Event overview',
                name: 'events.home',
            },
        },
    },
    {
        /**
         * New event page wrapper, provides event model instance to chidlren.
         * Only accessible before the first step (saving the event model) is done.
         */
        path: 'events/new',
        component: Create,
        meta: {
            scope: 'event',
        },
        children: [
            {
                /**
                 * New event page form, wraps the GenericEventForm
                 */
                path: '',
                name: 'events.new',
                component: CreateEventForm,
            },
        ],
    },
    {
        /**
         * New event edit page (wizard flow), provides event model instance to children.
         * This page replaces 'events/new' after the event is saved and has a guid.
         */
        path: 'events/new/:eventGuid',
        component: Create,
        meta: {
            scope: 'event',
        },
        children: [
            {
                /**
                 * New event page form, wraps the GenericEventForm
                 */
                path: '',
                name: 'events.new.edit',
                component: CreateEventForm,
            },
            {
                /**
                 * Ticket overview within the event wizard flow, wraps the GenericTicketList
                 */
                path: 'tickets',
                name: 'events.new.tickets',
                component: CreateTicketList,
            },
            {
                /**
                 * New ticket page within the event wizard flow, wraps the GenericTicketForm
                 */
                path: 'tickets/new',
                name: 'events.new.tickets.new',
                component: CreateTicketForm,
            },
            {
                /**
                 * Edit ticket page within the event wizard flow, wraps the GenericTicketForm
                 */
                path: 'tickets/:ticketGuid',
                name: 'events.new.tickets.edit',
                component: CreateTicketForm,
            },
        ],
    },
    {
        /**
         * Event view page wrapper, provides event model instance to children
         */
        path: 'events/:eventGuid',
        component: Event,
        meta: {
            scope: 'event',
        },
        children: [
            {
                /**
                 * Event home page
                 */
                path: '',
                name: 'events.home',
                component: EventHome,
            },
            {
                /**
                 * Ticket overview for existing event, wraps the GenericTicketList
                 */
                path: 'tickets',
                name: 'events.tickets',
                component: EventTicketList,
            },
        ],
    },
];

export default routes;
