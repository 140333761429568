
import { EventModel } from '@openticket/lib-management';
import { Paginatable, Pagination } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Component
export default class EventSwitcher extends Vue {

    @Prop() event!: EventModel;

    @Prop() relation!: Paginatable<EventModel>;

    @Ref('dropdown')
        dropdown!: { close: () => void };

    searchValue = '';

    events = new Pagination<EventModel>(this.$management.events);

    async created(): Promise<void> {
        await this.events.init({
            defaults: {
                per_page: 5,
            },
        });
    }

    @Watch('searchValue')
    private async onSearchValue() {
        if (this.searchValue) {
            await this.events.addFilter('name', this.searchValue);
        } else {
            await this.events.removeFilter('name');
        }
    }

    @Watch('$route')
    onRouteChange(): void {
        this.dropdown.close();
    }

}
