
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Ref } from 'vue-property-decorator';
import { EventModel } from '@openticket/lib-management';
import GenericTicketForm from '../generic/TicketForm.vue';
import FormHeader from '../../components/FormHeader.vue';
import FormFooter from '../../components/FormFooter.vue';

@Component({
    components: {
        GenericTicketForm,
        FormHeader,
        FormFooter,
    },
})
export default class CreateTicketForm extends Vue {

    @InjectReactive('event')
        event!: EventModel;

    @Ref('ticket_form')
        form!: GenericTicketForm;

    async submit(): Promise<void> {
        const ticket = await this.form.submit();

        this.$notifications.success('Ticket created');

        // Add guid to current route first,
        // so the user can go back to the saved
        // event via browser navigation
        await this.$router.replace({
            name: 'events.new.tickets.edit',
            params: {
                ticketGuid: ticket.guid,
            },
        });

        await this.$router.replace({
            name: 'events.new.tickets',
        });
    }

}
