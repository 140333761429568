var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form"},[_c('ProfileHeader',{attrs:{"name":_vm.company.props.name || _vm.$t('dashboard.company.settings.new'),"subtitle":_vm.subtitle}}),(_vm.form)?_c('div',{staticClass:"form__content"},[_c('OtForm',{attrs:{"form":_vm.form}},[_c('OtFormRow',[_c('OtFormInput',{attrs:{"label":_vm.$t('dashboard.company.settings.form.name.label'),"description":_vm.$t(
                            'dashboard.company.settings.form.name.description'
                        ),"type":"text","name":"name"}}),_c('OtFormInput',{attrs:{"label":_vm.$t('dashboard.company.settings.form.website.label'),"description":_vm.$t(
                            'dashboard.company.settings.form.website.description'
                        ),"type":"text","name":"website"}})],1),_c('OtFormRow',[_c('OtFormInput',{attrs:{"label":_vm.$t('dashboard.company.settings.form.locality.label'),"description":_vm.$t(
                            'dashboard.company.settings.form.locality.description'
                        ),"type":"select","sort":"alphabetically","options":_vm.locales,"name":"locale"}}),_c('OtFormInput',{attrs:{"label":_vm.$t('dashboard.company.settings.form.country.label'),"description":_vm.$t(
                            'dashboard.company.settings.form.country.description'
                        ),"type":"select","sort":"alphabetically","options":_vm.countries,"name":"country"}})],1),_c('OtFormRow',[_c('OtFormInput',{attrs:{"label":_vm.$t('dashboard.company.settings.form.currency.label'),"description":_vm.$t(
                            'dashboard.company.settings.form.currency.description'
                        ),"type":"select","options":_vm.currencies,"name":"currency"}}),_c('div')],1),_c('br'),_c('h3',{staticClass:"has-margin"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.company.settings.bank_information'))+" ")]),_c('OtFormRow',[_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.chamber_of_commerce_number.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.chamber_of_commerce_number.description'
                        ),"type":"text","name":"coc_number"}}),_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.chamber_of_commerce_name.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.chamber_of_commerce_name.description'
                        ),"type":"text","name":"coc_name"}})],1),_c('OtFormRow',[_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.vat_number.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.vat_number.description'
                        ),"type":"text","name":"vat_number"}}),_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.bank_account_name.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.bank_account_name.description'
                        ),"type":"text","name":"bank_account_name"}})],1),_c('OtFormRow',[_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.bank_account_number.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.bank_account_number.description'
                        ),"type":"text","name":"bank_account_number"}}),_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.bank_account_bic.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.bank_account_bic.description'
                        ),"type":"text","name":"bank_account_bic"}})],1),_c('br'),_c('h3',{staticClass:"has-margin"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.company.settings.visitor_information'))+" ")]),_c('OtFormRow',[_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.from_name.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.from_name.description'
                        ),"type":"text","name":"from_name"}}),_c('OtFormInput',{attrs:{"label":_vm.$t(
                            'dashboard.company.settings.form.from_email.label'
                        ),"description":_vm.$t(
                            'dashboard.company.settings.form.from_email.description'
                        ),"type":"text","name":"from_email"}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"form__footer"},[_c('OtCardFooter',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('button',{staticClass:"ot-button",attrs:{"title":_vm.$t('dashboard.common.action.save.title'),"disabled":!_vm.hasLocalChanges},on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"ot-button-icon--left oti oti-check"}),_vm._v(" "+_vm._s(_vm.$t('dashboard.common.action.save.text'))+" ")])]},proxy:true}])})],1),_c('OtInlineModal',{ref:"new_company_modal",attrs:{"width":"540"}},[_c('div',{staticClass:"ot-card new-company-modal"},[_c('i',{staticClass:"oti oti-home is-large"}),_c('h1',{staticClass:"new-company-modal__heading"},[_vm._v(" Your company is created ")]),_c('div',{staticClass:"ot-label new-company-modal__description"},[_vm._v(" Woohoo! Your company is successfully created ")]),_c('div',{staticClass:"new-company-modal__actions"},[_c('button',{staticClass:"ot-button",on:{"click":function($event){return _vm.selectNewCompany()}}},[_vm._v(" Select to your new company "),_c('i',{staticClass:"ot-button-icon--right oti oti-arrow-right"})])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }