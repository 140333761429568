
import { EventModel } from '@openticket/lib-management';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import EventFormHeader from '../components/EventFormHeader.vue';
import GenericEventForm from './generic/EventForm.vue';

@Component({
    components: {
        EventFormHeader,
        GenericEventForm,
    },
})
export default class EditEvent extends Vue {

    event: EventModel | null = null;

    @Ref('event_form')
        form!: GenericEventForm;

    async created(): Promise<void> {
        const { eventGuid } = this.$route.params;
        this.event = await this.$management.events.get(eventGuid);
    }

    async submit(close = false): Promise<void> {
        await this.form.submit();

        this.$notifications.success('Event saved');

        if (close) {
            await this.$router.push({
                name: 'events.home',
            });
        }
    }

}
