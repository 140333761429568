
import AuthClient, { IUserModelProps, UserModel } from '@openticket/lib-auth';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import { DialogController, ModalController } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import ProfileHeader from '../components/ProfileHeader.vue';

@Component({
    components: {
        ProfileHeader,
    },
})
export default class ProfileForm extends Vue {

    @Prop() guid!: string;

    @Inject('dialog')
        dialog!: DialogController;

    @Inject('modal')
        modal!: ModalController;

    private model: UserModel | null = null;

    private form: ModelForm<UserModel, IUserModelProps> | null = null;

    private hasLocalChanges = false;

    private get subtitle(): string {
        if (!this.model) {
            return '';
        }

        return this.$t('dashboard.company.profile.joined', {
            date: this.$l.date(new Date()),
        }) as string;
    }

    async created(): Promise<void> {
        const userId = this.guid || this.$route.params.userId;

        const { users }: AuthClient = this.$auth;

        if (userId && userId !== 'new') {
            this.model = await users.get(userId);
        } else {
            this.model = users.new();
        }

        this.form = new ModelForm(users, this.model);

        this.modal.registerBeforeClose(async () => !this.hasLocalChanges || this.dialog.confirm({
            title: 'Undo unsaved changes?',
            description: 'This action cannot be undone',
            type: 'is-danger',
        }));
    }

    private async save(): Promise<void> {
        try {
            await this.form!.submit();

            this.hasLocalChanges = false;

            void this.modal.close();

            this.$notifications.success(this.$t('dashboard.common.notification.save.success'));
        } catch (e) {
            this.$notifications.danger(this.$t('dashboard.common.notification.save.fail'));

            throw e;
        }
    }

    @Watch('form.props', { deep: true })
    private checkFormChanges(): void {
        this.hasLocalChanges = this.form!.hasLocalChanges();
    }

}
