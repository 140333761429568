// Register vue-router component hooks
import './hooks';
import { OpenTicketShop } from '@openticket/sdk-shop';

import Vue from 'vue';
import VueNotifications from '@openticket/vue-notifications';
import {
    VueLocalization,
    Formatters,
    Parsers,
    VueI18n,
} from '@openticket/vue-localization';
import '@openticket/vue-localization/lib/style.css';

import VueDashboardComponents from '@openticket/vue-dashboard-components';
import VueInput from '@openticket/vue-input';
import { Whitelabel } from '@openticket/lib-whitelabels';

import ManagementClient from '@openticket/lib-management';
import AuthClient, { CompanyModel, MeModel } from '@openticket/lib-auth';
import VueDocumentTitlePlugin from './documentTitle';

import { registerComponents } from './components';
import router from './router';
import App from './App.vue';

// Declarations on Vue root
declare module 'vue/types/vue' {
    interface Vue {
        $notifications: VueNotifications;
        $auth: AuthClient;
        $management: ManagementClient;
        $whitelabel: Whitelabel;
        $company: CompanyModel;
        $user: MeModel;

        $documentTitle: VueDocumentTitlePlugin;
    }
}

// Declarations on global window
declare global {
    interface Window {
        Vue: Vue;
        Auth: AuthClient;
        Shop?: OpenTicketShop;
        Management: ManagementClient;
        Whitelabel: Whitelabel;
    }
}

// Used for date(time) components in vue-input
declare module 'vue/types/vue' {
    interface Vue {
        $localization: VueLocalization;
        $l: Formatters;
        $p: Parsers;

        $t: typeof VueI18n.prototype.t;
        $tc: typeof VueI18n.prototype.tc;
        $te: typeof VueI18n.prototype.te;
        $d: typeof VueI18n.prototype.d;
        $n: typeof VueI18n.prototype.n;
    }
}

// Get whitelabel overwrite from URL
const whitelabelOverwrite = new URLSearchParams(window.location.search).get('whitelabel');

// Setup whitelabel instance and set styling
const whitelabel = Vue.prototype.$whitelabel = window.Whitelabel = new Whitelabel({
    whitelabel: whitelabelOverwrite || undefined,
});

// Setup whitelabel resources
void whitelabel.loadStylesheet();
void whitelabel.loadIconPack();
void whitelabel.setFavicon();

// Add Vue packages to Vue root
Vue.use(VueLocalization);

// @ts-expect-error - Type and bundle don't reflect each other properly...
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
Vue.use(VueDashboardComponents);
Vue.use(VueInput);
Vue.use(VueNotifications, {
    singleNotification: true,
    duration: 2000,
});
Vue.use(VueDocumentTitlePlugin, router);

// Register local components
registerComponents(Vue);

const i18n: VueI18n = Vue.prototype.$localization.getI18n();

// Disable Vue production tip
Vue.config.productionTip = false;

// Create Vue instance
new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
