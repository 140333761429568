
import Vue from 'vue';
import Component from 'vue-class-component';
import {
    Inject, InjectReactive, Ref, Watch,
} from 'vue-property-decorator';

// Libs
import { ModelForm } from '@openticket/lib-sdk-helpers';
import {
    CollapseModel,
    ShopModel,
    ICollapseModelProps,
    EventModel,
    TicketModel,
} from '@openticket/lib-management';
import {
    DialogController,
    IFormPicker,
    ModalController,
} from '@openticket/vue-dashboard-components';

@Component
export default class CollapseForm extends Vue {

    @InjectReactive('shop')
    private shop!: ShopModel;

    @Inject('modal')
    private modal!: ModalController;

    @Inject('dialog')
    private dialog!: DialogController;

    // Callback to invoke when a collapse has been saved
    @Inject('collapseSaved')
    private collapseSaved!: (collapse: CollapseModel) => void;

    // Used to throw an error when selecting tickets
    // using the nested picker fails
    @Inject('fail')
    private fail!: (error: Error, userMessage: string) => void;

    // Injecting the reactive events model map, provided by the `Tickets.vue` component
    @InjectReactive('events')
    private events!: { [guid: string]: EventModel };

    @Ref('ticket_picker')
    private ticketPicker!: IFormPicker;

    private collapse_id!: string;

    private event_id!: string;

    private collapse: CollapseModel | null = null;

    private event: EventModel | null = null;

    private tickets: TicketModel[] = [];

    private form: ModelForm<CollapseModel, ICollapseModelProps> | null = null;

    private hasLocalChanges = false;

    /**
     *
     *  Only real-time update the title if a user is creating a new collapse.
     *
     *  @todo: Translate default text
     *
     */
    private get collapseTitle(): string {
        if (this.collapse && this.collapse.guid) {
            return this.collapse.props.title;
        }

        if (
            !this.form
            || !this.form.props.title.value
            || !this.form.props.title.value.length
        ) {
            return this.$t('dashboard.shop.collapse_settings.new') as string;
        }

        return this.form.props.title.value;
    }

    public created(): void {
        this.collapse_id = this.$route.params.collapse_id;
        this.event_id = this.$route.params.event_id;

        /**
         *  An event (id) is mandatory to preselect the
         *  event as sums model in the ticket picker.
         */
        if (!this.event_id) {
            throw new Error('No event id provided');
        }

        this.modal.registerBeforeClose(() => this.shouldClose());
    }

    public async mounted(): Promise<void> {
        try {
            /**
             *  Create a new collapse or get an existing one.
             */
            this.collapse = this.collapse_id === 'new'
                ? this.$management.collapses.new()
                : await this.shop.collapses.get(this.collapse_id);

            /**
             *  Get the event out of the already preloaded and provided
             *  events, or get it from the API if it doesn't exist.
             */
            this.event = this.events
                ? this.events[this.event_id]
                : await this.$management.events.get(this.event_id);

            /**
             *  Construct the form for the collapse.
             */
            this.form = new ModelForm(
                this.$management.collapses,
                this.collapse,
            );
        } catch (e) {
            console.error(e);

            void this.$router.push({
                name: 'error',
            });
        }

        this.$documentTitle.setValue(this, 'collapse', !this.collapse || this.collapse_id === 'new' ? this.$t('dashboard.shop.collapse_settings.new') as string : this.collapse.props.title);
    }

    private async shouldClose(): Promise<boolean> {
        if (
            this.hasLocalChanges
            && !(await this.dialog.confirm({
                title: 'Undo unsaved changes?',
                description: 'This action cannot be undone',
                type: 'is-danger',
            }))
        ) {
            return false;
        }

        return true;
    }

    private async save(): Promise<void> {
        if (!this.form || !this.collapse) {
            return;
        }

        try {
            // Get isNew state before submit
            const { isNew } = this.collapse;

            await this.form.submit();

            if (isNew) {
                // If collapse was new, save tickets from CrudMultiSelect
                await this.collapse.tickets.linkMulti(this.tickets);
            } else {
                // Else save picker changes
                await this.ticketPicker.save();
            }

            this.hasLocalChanges = false;

            this.collapseSaved(this.collapse);

            void this.modal.close();

            this.$notifications.success('Collapse saved successfully');
        } catch (e) {
            this.$notifications.danger('Failed to save changes');

            throw e;
        }
    }

    @Watch('form.props', { deep: true })
    private checkFormChanges() {
        if (!this.form) {
            return;
        }

        this.hasLocalChanges = this.form.hasLocalChanges();
    }

}
