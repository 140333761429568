
import AuthClient, { CompanyModel, ICompanyModelProps } from '@openticket/lib-auth';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import { IInlineModal } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Ref, Watch } from 'vue-property-decorator';
import { DashboardInteractionController } from '@/layout/Dashboard.vue';
import ProfileHeader from '../components/ProfileHeader.vue';

@Component({
    components: { ProfileHeader },
})
export default class Form extends Vue {

    private company!: CompanyModel;

    private form: ModelForm<CompanyModel, ICompanyModelProps> | null = null;

    private hasLocalChanges = false;

    private get subtitle(): string {
        return `${this.$t('dashboard.common.created')} ${this.$l.date(
            this.company.isNew ? new Date() : this.company.props.created_at,
        )}`;
    }

    private created(): void {
        const { companies }: AuthClient = this.$auth;

        if (this.$route.name === 'company.new') {
            this.company = companies.new();
        } else {
            this.company = this.$company;
        }

        this.form = new ModelForm(companies, this.company);

        if (this.$route.name === 'company.new') {
            this.form.props.currency.value = this.$company.props.currency;
            this.form.props.locale.value = this.$company.props.locale;
            this.form.props.country.value = this.$company.props.country;
        }
    }

    @Ref('new_company_modal')
        newCompanyModal!: IInlineModal;

    @Inject('dashboard')
        dashboard!: DashboardInteractionController;

    selectNewCompany(): void {
        this.dashboard.selectCompany(this.company);
    }

    private async save(): Promise<void> {
        try {
            const { isNew } = this.company;

            await this.form!.submit();

            void this.$router.replace({
                name: 'company.new.edit',
                params: {
                    companyId: this.company.guid,
                },
            });

            if (isNew) {
                this.newCompanyModal.open();
            } else {
                this.$notifications.success(
                    this.$t('dashboard.common.notification.save.success'),
                );
            }
        } catch (e) {
            this.$notifications.danger(
                this.$t('dashboard.common.notification.save.fail'),
            );

            throw e;
        }
    }

    private get locales(): Record<string, string> {
        const map: Record<string, string> = {};
        const locales = this.$localization.supportedLocales;

        for (const key of Object.keys(locales)) {
            map[
                key
            ] = `${locales[key].flag} ${locales[key].territoryDisplayName} / ${locales[key].languageDisplayName}`;
        }

        return map;
    }

    private get countries(): Record<string, string> {
        const map: Record<string, string> = {};
        const countries = this.$localization.supportedCountries;

        for (const key of Object.keys(countries)) {
            map[
                key
            ] = `${countries[key].flag} ${countries[key].territoryDisplayName}`;
        }

        return map;
    }

    private get currencies(): Record<string, string> {
        const map: Record<string, string> = {};
        const currencies = this.$localization.supportedCurrencies;

        for (const key of Object.keys(currencies)) {
            map[
                key
            ] = `${currencies[key].currencySymbol} - ${currencies[key].currencyDisplayName}`;
        }

        return map;
    }

    @Watch('form.props', { deep: true })
    private checkFormChanges(): void {
        this.hasLocalChanges = this.form!.hasLocalChanges();
    }

}
