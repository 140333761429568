
import {
    IShopModelProps,
    ShopModel,
    TicketModel,
} from '@openticket/lib-management';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import { IInlineModal } from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import NewShopModal from '../../shop/views/NewShopModal.vue';

@Component({
    components: {
        NewShopModal,
    },
})
export default class TicketFormShopSelect extends Vue {

    @Prop()
        ticket!: TicketModel;

    @Ref('new_shop_modal')
        newShopModal!: IInlineModal;

    shopModel: ShopModel | null = null;

    shopForm: ModelForm<ShopModel, IShopModelProps> | null = null;

    newShop(): void {
        this.shopModel = this.$management.shops.new();
        this.shopForm = new ModelForm(this.$management.shops, this.shopModel);
        this.newShopModal.open();
    }

}
