
import { MetadataModel, IMetadataModelProps } from '@openticket/lib-management';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class MetadataForm extends Vue {

    @Prop()
    private form!: ModelForm<MetadataModel, IMetadataModelProps>;

    @Prop()
    private cancel!: () => void;

    @Prop()
    private save!: () => void;

}
