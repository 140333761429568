import { render, staticRenderFns } from "./NewShopModal.vue?vue&type=template&id=04bb7457&scoped=true&"
import script from "./NewShopModal.vue?vue&type=script&lang=ts&"
export * from "./NewShopModal.vue?vue&type=script&lang=ts&"
import style0 from "./NewShopModal.vue?vue&type=style&index=0&id=04bb7457&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04bb7457",
  null
  
)

export default component.exports