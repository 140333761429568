
import { EventModel, TicketModel } from '@openticket/lib-management';
import { Pagination } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class TicketList extends Vue {

    @Prop()
        event!: EventModel;

    tickets!: Pagination<TicketModel>;

    columns = [
        {
            prop: 'name',
            type: 'string',
            label: 'Name',
        },
        {
            prop: 'status_overrule',
            type: 'string',
            label: 'Status',
        },
        {
            prop: 'min_price',
            type: 'string',
            label: 'Price',
        },
        {
            prop: 'available_stock',
            type: 'string',
            label: 'Capacity',
        },
        {
            prop: 'sold_count',
            type: 'string',
            label: 'sold',
        },
    ];

    async created(): Promise<void> {
        this.tickets = new Pagination<TicketModel>(this.event.tickets);
        await this.tickets.init({
            defaults: {
                per_page: 30,
            },
        });
    }

}
