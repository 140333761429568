
import Vue from 'vue';
import Component from 'vue-class-component';
import { ProvideReactive, Watch } from 'vue-property-decorator';

import { ShopModel, IShopModelProps } from '@openticket/lib-management';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import ShopTabs, { TabItem } from '../components/ShopTabs.vue';

@Component({
    components: {
        ShopTabs,
    },
})
export default class Shop extends Vue {

    @ProvideReactive('shop')
    private shop: ShopModel | null = null;

    @ProvideReactive('form')
    private form: ModelForm<ShopModel, IShopModelProps> | null = null;

    // Items to add to the tabs
    private tabItems: TabItem[] = [
        {
            icon: 'shops',
            label: this.$t('dashboard.shop.tabs.details') as string,
            route: 'shops.edit',
        },
        {
            icon: 'ticket',
            label: this.$t('dashboard.shop.tabs.tickets') as string,
            route: 'shops.edit.tickets',
            disabled: this.$whitelabel.name === 'Eventix',
        },
    ];

    /**
     *
     *  Only real-time update the name if a user is creating a new shop.
     *
     *  @todo: Translate default text
     *
     */
    private get shopName(): string {
        if (this.shop && this.shop.guid) {
            return this.shop.props.name;
        }

        if (
            !this.form
            || !this.form.props.name.value
            || !this.form.props.name.value.length
        ) {
            return 'New Shop';
        }

        return this.form.props.name.value;
    }

    /**
     *  Initialize the shop, his form and provide them to the child co     */
    public async mounted(): Promise<void> {
        const guid = this.$route.params.shop_id;

        try {
            if (!guid || guid === 'new') {
                this.shop = this.$management.shops.new();
            } else {
                this.shop = await this.$management.shops.get(guid);

                this.$documentTitle.setValue(this, 'shop', this.shop.props.name);
            }

            this.form = new ModelForm(this.$management.shops, this.shop);
        } catch (e) {
            console.error(e);

            await this.$router.push({
                name: 'error',
            });
        }
    }

    @Watch('shop.isNew')
    onShopCreated(): void {
        this.form = new ModelForm(this.$management.shops, this.shop!);
    }

}
