
import { CompanyModel } from '@openticket/lib-auth';
import { Pagination } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

@Component
export default class CompanySwitchModal extends Vue {

    @Ref('input')
        input!: Vue;

    searchValue = '';

    companies: Pagination<CompanyModel> = new Pagination(this.$auth.companies);

    async created(): Promise<void> {
        await this.companies.init({
            defaults: {
                per_page: 10,
            },
        });
    }

    mounted(): void {
        const nativeInput = this.input.$el.getElementsByTagName(
            'input',
        )[0];
        nativeInput.focus();
    }

    selectCompany(company: CompanyModel): void {
        this.$emit('select', company);
    }

    @Watch('searchValue')
    private async onSearchValue() {
        if (this.searchValue) {
            await this.companies.addFilter('name', this.searchValue);
        } else {
            await this.companies.removeFilter('name');
        }
    }

}
