
import {
    EventModel,
    IEventModelProps,
    LocationModel,
} from '@openticket/lib-management';
import { ModelForm, StringError } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import FormHeader from '../../components/FormHeader.vue';
import FormFooter from '../../components/FormFooter.vue';
import LocationForm from './LocationForm.vue';
import EventdateList from './EventdateList.vue';
import genres from '../../categories';

@Component({
    components: {
        FormHeader,
        FormFooter,
        LocationForm,
        EventdateList,
    },
})
export default class EventForm extends Vue {

    @Prop()
        event!: EventModel;

    @Ref('eventdates')
        eventdates!: EventdateList;

    location: LocationModel | null = null;

    locationError = '';

    form: ModelForm<EventModel, IEventModelProps> | null = null;

    hasLocalChanges = false;

    async created(): Promise<void> {
        await this.initForm();
    }

    async initForm(): Promise<void> {
        if (!this.event.isNew) {
            this.location = await this.event.location.get();
        }

        const relation = {
            create: async (
                data: Partial<IEventModelProps>,
                model: EventModel,
            ) => {
                if (!this.location) {
                    this.locationError = 'No location provided';
                    throw new StringError('No location provided');
                }

                await this.$management.events.create(data, model);

                await model.location.link(this.location);
            },
            update: async (
                data: Partial<IEventModelProps>,
                model: EventModel,
            ) => {
                if (!this.location) {
                    this.locationError = 'No location provided';
                    throw new StringError('No location provided');
                }

                const currentLocation = await this.event.location.get();

                if (currentLocation.guid !== this.location.guid) {
                    await this.event.location.link(this.location);
                }

                await this.$management.events.update(data, model);
            },
            rules: this.$management.events.rules,
        };

        this.form = new ModelForm(relation, this.event);

        if (this.event.isNew) {
            this.form.props.locale.value = this.$company.props.locale;
            this.form.props.currency.value = this.$company.props.currency;
        }
    }

    public async submit(): Promise<EventModel> {
        const { isNew } = this.event;

        await this.form!.submit();

        if (isNew) {
            await this.eventdates.save();
        }

        this.hasLocalChanges = false;

        return this.event;
    }

    get categories(): { [key: string]: string } {
        const map: { [key: string]: string } = {};
        Object.keys(genres).forEach((genre: string) => {
            map[genre] = genre;
        });
        return map;
    }

    get subcategories(): { [key: string]: string } {
        if (
            !this.form
            || !this.form.props.category.value
            || !genres[this.form.props.category.value]
        ) {
            return {};
        }

        const map: { [key: string]: string } = {};
        genres[this.form.props.category.value].forEach((genre: string) => {
            map[genre] = genre;
        });
        return map;
    }

    get locales(): Record<string, string> {
        const map: Record<string, string> = {};
        const locales = this.$localization.supportedLocales;

        const rule = (this.form!.props.locale.rules || []).find(
            (r: string) => r.startsWith('in:'),
        );

        if (!rule) {
            return {};
        }

        const supported = this.getRuleInArray(rule);

        for (const key of Object.keys(locales)) {
            if (!supported.includes(key)) {
                continue;
            }

            map[
                key
            ] = `${locales[key].flag} ${locales[key].territoryDisplayName} / ${locales[key].languageDisplayName}`;
        }

        return map;
    }

    get currencies(): Record<string, string> {
        const map: Record<string, string> = {};
        const currencies = this.$localization.supportedCurrencies;

        const rule = (this.form!.props.currency.rules || []).find(
            (r: string) => r.startsWith('in:'),
        );

        if (!rule) {
            return {};
        }

        const supported = this.getRuleInArray(rule);

        for (const key of Object.keys(currencies)) {
            if (!supported.includes(key)) {
                continue;
            }

            map[
                key
            ] = `${currencies[key].currencySymbol} - ${currencies[key].currencyDisplayName}`;
        }

        return map;
    }

    getRuleInArray(rule: string): string[] {
        return rule.split('in:')[1].split(',');
    }

    @Watch('form.props.category.value')
    onCategoryChange(): void {
        this.form!.undo('subcategories');
    }

    @Watch('location')
    onLocationChange(): void {
        this.locationError = '';
    }

    @Watch('form.props', { deep: true })
    onPropChange(): void {
        this.hasLocalChanges = this.form!.hasLocalChanges();
    }

}
