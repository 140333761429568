
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AuthCallback extends Vue {

    async created(): Promise<void> {
        const { code } = this.$route.query;

        if (!code) {
            await this.$router.push({ name: 'error' });
            return;
        }

        try {
            await this.$auth.authorizeWithCode(code as string);

            const seed = await this.$auth.getMe();
            Vue.prototype.$user = seed.user;
            Vue.prototype.$company = seed.company;

            const path = (this.$route.query.state as string) || '/';

            await this.$router.push({
                path,
            });
        } catch (e) {
            console.error(e);
            await this.$router.push({ name: 'error' });
        }
    }

}
