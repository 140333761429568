
import {
    EventdateModel,
    EventModel,
    IEventdateModelProps,
} from '@openticket/lib-management';
import { ModelForm } from '@openticket/lib-sdk-helpers';
import {
    DialogController,
    ModalController,
} from '@openticket/vue-dashboard-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Watch } from 'vue-property-decorator';

@Component
export default class EventdateForm extends Vue {

    @Prop() event!: EventModel;

    @Prop() eventdate!: EventdateModel;

    @Inject('modal')
        modal!: ModalController;

    @Inject('dialog')
        dialog!: DialogController;

    model: EventdateModel | null = null;

    form: ModelForm<EventdateModel, IEventdateModelProps> | null = null;

    hasLocalChanges = false;

    created(): void {
        if (this.eventdate) {
            this.model = this.eventdate;
        } else {
            this.model = this.event.eventdates.new();
        }

        this.form = new ModelForm<EventdateModel, IEventdateModelProps>(
            {
                create: async (data, model) => {
                    if (this.event.isNew) {
                        if (this.eventdate) {
                            this.edit(data, model);
                        } else {
                            this.emitPendingCreate(data, model);
                        }

                        this.hasLocalChanges = false;
                        void this.modal.close();
                    } else {
                        const response = await this.event.eventdates.create(
                            data,
                            model,
                        );

                        this.$emit('created');

                        this.hasLocalChanges = false;
                        void this.modal.close();

                        return response;
                    }
                },
                update: async (data, model) => {
                    const response = await this.$management.eventdates.update(
                        data,
                        model,
                    );

                    this.hasLocalChanges = false;
                    void this.modal.close();

                    return response;
                },
                rules: this.$management.eventdates.rules,
            },
            this.model,
        );

        this.modal.registerBeforeClose(async () => {
            if (this.hasLocalChanges) {
                return this.dialog.confirm({
                    title: 'Are you sure?',
                    description: 'This cannot be undone',
                    type: 'is-danger',
                });
            }

            return true;
        });
    }

    edit(data: Partial<IEventdateModelProps>, model: EventdateModel): void {
        model.setData(data);
    }

    emitPendingCreate(
        data: Partial<IEventdateModelProps>,
        model: EventdateModel,
    ): void {
        model.setData(data);
        this.$emit('add-pending', model);
    }

    async save(): Promise<void> {
        await this.form!.submit();
    }

    @Watch('form.props', { deep: true })
    onFormPropsChange(): void {
        this.hasLocalChanges = this.form!.hasLocalChanges();
    }

}
