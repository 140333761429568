
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

/**
 *  Holding every option of a shop's item: a draghandle and an icon for removing the item.
 */
@Component
export default class TicketsItemOptions extends Vue {

    // Model for which the options are used
    @Prop({ required: true })
    private model!: string;

    // If the options should include remove
    @Prop({ default: false })
    private enableRemove!: string;

    // Typing a ref to the actual draghandle
    public $refs!: {
        dragHandle: HTMLElement;
    };

    /**
     *
     *  Used to get a ref to the drag handle.
     *
     *  (i) Used by the TicketsDragDrop
     *
     */
    public get dragHandle(): HTMLElement {
        return this.$refs.dragHandle;
    }

    /**
     *
     *  The following getters are used to get the translated
     *  title strings, based on the model for which the
     *  options are used.
     *
     *  @todo Translate
     *
     */
    private get dragTitle(): string {
        return `Drag to change the position of this ${this.model}`;
    }

    private get removeTitle(): string {
        return `Click to remove this ${this.model}`;
    }

}
