
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

/**
 *  Holding the details of a tab item.
 */
export interface TabItem {
    /** The name for the icon of the tab, always prefixed with 'oti-{icon}'. */
    icon: string;
    /** The label for the tab. */
    label: string;
    /** The route (name) for the tab. */
    route: string;
    /** If the tab should be disabled. */
    disabled?: boolean;
}

@Component
export default class Tabs extends Vue {

    @Prop({ type: Array, required: true })
    private items!: TabItem[];

    /**
     *  Used to check if the item's route is currently active.
     */
    private isActive(item: TabItem): boolean {
        return this.$route.name === item.route;
    }

    /**
     *  Used to navigate to the item's route, including a
     *  double check on the disabled and active state
     */
    private navigate(item: TabItem): void {
        if (item.disabled || this.isActive(item)) {
            return;
        }

        void this.$router.push({
            name: item.route,
        });
    }

}
