
import { EventModel } from '@openticket/lib-management';
import Vue from 'vue';
import Component from 'vue-class-component';
import {
    Inject,
    ProvideReactive,
} from 'vue-property-decorator';
import { DashboardInteractionController } from '@/layout/Dashboard.vue';
import Tabs, { TabItem } from '../components/Tabs.vue';
import EventFormHeader from '../components/EventFormHeader.vue';

@Component({
    components: {
        Tabs,
        EventFormHeader,
    },
})
export default class CreateEvent extends Vue {

    @Inject('dashboard')
        dashboard!: DashboardInteractionController;

    @ProvideReactive('event')
        event: EventModel | null = null;

    async created(): Promise<void> {
        const { eventGuid } = this.$route.params;

        if (eventGuid) {
            this.event = await this.$management.events.get(eventGuid);
        } else {
            this.event = this.$management.events.new();
        }

        this.dashboard.setCurrentEvent(this.event);
    }

    // Items to add to the tabs
    private get tabItems(): TabItem[] {
        return [
            {
                icon: 'alert',
                label: 'Event Details',
                route: this.$route.params.eventGuid
                    ? 'events.new.edit'
                    : 'events.new',
            },
            {
                icon: 'ticket',
                label: 'Tickets',
                route: 'events.new.tickets',
                disabled: !this.$route.params.eventGuid,
            },
        ];
    }

}
