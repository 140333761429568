
import AuthClient, { UserModel, Role } from '@openticket/lib-auth';
import { Pagination, Form } from '@openticket/lib-sdk-helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import ProfileHeader from '../components/ProfileHeader.vue';

interface InviteUserFormData {
    name: string;
    email: string;
    role: string;
}

@Component({
    components: {
        ProfileHeader,
    },
})
export default class Members extends Vue {

    private users: Pagination<UserModel> | null = null;

    private get title(): string {
        if (!this.users || !this.users.page) {
            return this.$t('dashboard.company.members.title') as string;
        }

        return `${this.users.page.data.length} ${this.$t('dashboard.company.members.title')}`;
    }

    private invite = new Form<InviteUserFormData>({
        data: {
            email: null,
            name: null,
            role: Role.IsInCompany,
        },
        submit: async (data) => {
            await this.$auth.inviteUser(data.name as string, data.email as string, [ data.role as Role ]);

            this.$notifications.success(`${data.name} is invited to ${this.$company.props.name}`);
        },
    });

    private get roles(): Record<string, string> {
        return {
            [Role.IsInCompany]: Role.IsInCompany,
            [Role.CompanyAdmin]: Role.CompanyAdmin,
        };
    }

    private async created() {
        const { users }: AuthClient = this.$auth;

        this.users = Vue.observable(new Pagination(users));
        await this.users.init({
            defaults: {
                per_page: 6,
            },
        });
    }

}
