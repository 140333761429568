
import Component from 'vue-class-component';
import { Prop, Mixins } from 'vue-property-decorator';

// Default SDK
import { ShopModel, IShopModelProps } from '@openticket/lib-management';

// Shop SDK
import { Event } from '@openticket/sdk-shop';

// SDK helpers
import { ModelForm } from '@openticket/lib-sdk-helpers';

// Tickets logic
import TicketsItemOptions from './ItemOptions.vue';
import TicketsTicket from './Ticket.vue';
import TicketsDragDrop from './DragDrop.vue';

// Tickets types
import { LocalEventTicket, LocalEventCollapse } from './types';

@Component({
    components: {
        TicketsTicket,
        TicketsItemOptions,
    },
})
export default class TicketsCollapse extends Mixins(TicketsDragDrop) {

    @Prop({ required: true })
    protected form!: ModelForm<ShopModel, IShopModelProps>;

    @Prop({ required: true })
    private shop!: ShopModel;

    @Prop({ required: true })
    private event!: Event;

    @Prop({ required: true })
    private collapse!: LocalEventCollapse;

    /**
     *  The colspan of the main part in the heading (title and
     *  edit button), used to make sure it gets the right
     *  colspan based on the screenwidth.
     */
    private headerColspan = 3;

    public mounted(): void {
        // Needed because it's used for dragging position computation
        this.collapse.ref = this;

        // Init the drag and drop logic, which is mixed in
        this.initDragDrop(
            this.collapse,
            this.$el as HTMLElement,
            this.$refs.options,
        );

        // Compute the correct colspan for the header on resize
        this.computeHeaderColspan();

        // And re-compute on resize
        window.addEventListener('resize', this.computeHeaderColspan);
    }

    public beforeDestroy(): void {
        window.removeEventListener('resize', this.computeHeaderColspan);
    }

    /**
     *  Returns the tickets, sorted based on their
     *  current index in this collapse.
     */
    private get sortedTickets(): LocalEventTicket[] {
        if (!this.collapse.tickets.length) {
            return [];
        }

        // Used to get the index of the ticket in this collapse
        const getIndexInThisCollapse = (
            localTicket: LocalEventTicket,
        ): number | null => {
            if (
                localTicket.collapse.current.item
                && localTicket.collapse.current.item.guid === this.collapse.guid
            ) {
                return localTicket.collapse.current.index;
            }

            if (
                localTicket.collapse.new.item
                && localTicket.collapse.new.item.guid === this.collapse.guid
            ) {
                return localTicket.collapse.new.index;
            }

            return null;
        };

        return this.collapse.tickets.sort(
            (
                localTicketA: LocalEventTicket,
                localTicketB: LocalEventTicket,
            ): number => {
                const localTicketAIndex: number | null = getIndexInThisCollapse(localTicketA);
                const localticketBIndex: number | null = getIndexInThisCollapse(localTicketB);

                if (localTicketAIndex !== null && localticketBIndex !== null) {
                    return localTicketAIndex - localticketBIndex;
                }

                return 0;
            },
        );
    }

    /**
     *
     *  Used to compute the right colspan for the header, because
     *  it should be only one on smaller screens (based on Bulma's
     *  desktop width) because only the the ticket's name and
     *  options are shown on smaller screens.
     *
     *  (i) No, unfortunately there is no elegant CSS way of doing this
     *
     */
    private computeHeaderColspan(): void {
        this.headerColspan = window.innerWidth <= 1024 ? 1 : 3;
    }

}
