
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EventFormHeader extends Vue {

    @Prop()
        isNew!: boolean;

    @Prop()
        title!: string;

    @Prop()
        subtitle!: string;

    @Prop()
        icon!: string;

}
