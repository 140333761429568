
import { EventModel } from '@openticket/lib-management';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, ProvideReactive, Watch } from 'vue-property-decorator';
import { DashboardInteractionController } from '@/layout/Dashboard.vue';
import EventSwitcher from '../components/EventSwitcher.vue';
import Tabs, { TabItem } from '../components/Tabs.vue';

@Component({
    components: {
        EventSwitcher,
        Tabs,
    },
})
export default class Event extends Vue {

    @ProvideReactive('event')
        event: EventModel | null = null;

    @Inject('dashboard')
        dashboard!: DashboardInteractionController;

    async created(): Promise<void> {
        await this.initEvent();
    }

    // Items to add to the tabs
    private get tabItems(): TabItem[] {
        return [
            {
                icon: 'event',
                label: 'Event Home', // TODO translate
                route: 'events.home',
            },
            {
                icon: 'ticket',
                label: 'Tickets', // TODO translate
                route: 'events.tickets',
            },
        ];
    }

    /**
     * Watch for eventGuid changes in the router to refresh data (set by the EventSwitcher)
     */
    @Watch('$route')
    async initEvent(): Promise<void> {
        const { eventGuid } = this.$route.params;

        if (this.dashboard.currentEvent && this.dashboard.currentEvent.guid === eventGuid) {
            this.event = this.dashboard.currentEvent;
        } else {
            this.event = await this.$management.events.get(eventGuid);

            // Update the sidebar event
            this.dashboard.setCurrentEvent(this.event);
        }
    }

}
