import Vue, { VueConstructor } from 'vue';
import { RouteConfig, Route, RawLocation } from 'vue-router';
import { RouterModalWidth } from '@openticket/vue-dashboard-components';
import Shop from './views/Shop.vue';
import ShopList from './views/ShopList.vue';
import ShopForm from './views/ShopForm.vue';
import ShopSettings from './views/ShopSettings.vue';
import ShopTickets from './views/Tickets.vue';
import ShopAdminForm from './views/AdminForm.vue';
import CollapseForm from './views/CollapseForm.vue';
import NewShopModal from './views/NewShopModal.vue';

const RouterView = {
    render: (h: (c: string) => Vue) => h('router-view'),
} as unknown as VueConstructor;

const routes: RouteConfig[] = [
    {
        path: 'shops',
        name: 'shops',
        component: ShopList,
        meta: {
            scope: 'shops',
            title: 'dashboard.document_title.shops.list',
        },
    },
    {
        path: 'shops',
        component: Shop,
        children: [
            {
                path: 'new',
                component: ShopForm,
                meta: {
                    title: 'dashboard.document_title.shops.new',
                },
                children: [
                    {
                        path: '',
                        name: 'shops.new',
                        component: NewShopModal,
                        meta: {
                            modal: true,
                            modalWidth: RouterModalWidth.Small,
                        },
                    },
                ],
            },
            {
                path: ':shop_id',
                component: RouterView,
                meta: {
                    title: 'dashboard.document_title.shops.edit.main',
                },
                children: [
                    {
                        path: '',
                        name: 'shops.edit',
                        component: ShopForm,
                        children: [
                            {
                                path: 'settings',
                                name: 'shops.edit.settings',
                                component: ShopSettings,
                                meta: {
                                    modal: true,
                                    modalWidth: RouterModalWidth.Large,
                                    fitHeight: true,
                                    fitWidth: true,
                                    title: 'dashboard.document_title.shops.edit.settings',
                                },
                            },
                            {
                                path: 'admin',
                                name: 'shops.edit.admin',
                                component: ShopAdminForm,
                                meta: {
                                    modal: true,
                                    modalWidth: RouterModalWidth.Large,
                                    title: 'dashboard.document_title.shops.edit.admin',
                                },
                            },
                        ],
                    },
                    {
                        path: 'tickets',
                        name: 'shops.edit.tickets',
                        component: ShopTickets,
                        meta: {
                            title: 'dashboard.document_title.shops.edit.tickets.main',
                        },
                        children: [
                            {
                                path: 'collapse/new/:event_id',
                                name: 'shops.edit.tickets.collapse.new',
                                redirect: (to: Route): RawLocation => ({
                                    name: 'shops.edit.tickets.collapse',
                                    params: {
                                        ...to.params,
                                        collapse_id: 'new',
                                    },
                                }),
                            },
                            {
                                path: 'collapse/:collapse_id/:event_id',
                                name: 'shops.edit.tickets.collapse',
                                component: CollapseForm,
                                meta: {
                                    modal: true,
                                    modalWidth: RouterModalWidth.Small,
                                    title: 'dashboard.document_title.shops.edit.tickets.collapse',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
        meta: {
            scope: 'shops',
            prevRoute: {
                title: 'Shops',
                name: 'shops',
            },
        },
    },
];

export default routes;
